import React from 'react';
const MediaCard = ({ type, src, title, subtitle, isActive }) => {
  return (
    <div
      className={`relative bg-slate-900 rounded-[8px] overflow-hidden w-full h-[300px] pt-4 px-4 pb-4 shadow-2xl shadow-white/50`}
    >
      <div className="w-full bg-slate-900 mx-auto"> {/* Changed height to auto */}
        {type === 'image' ? (
          <img
            src={src}
            alt={title}
            className="w-full h-auto object-fill rounded-[4px]" // Changed height to auto to adjust based on content
          />
        ) : (
          <video
            src={src}
            autoPlay
            loop
            muted
            playsInline
            webkit-playsinline="true"
            className="w-full h-auto object-fill rounded-[4px]"
          >
            Your browser does not support the video tag.
          </video>
        )}
      </div>
      {isActive && (
        <div className="absolute bottom-0 left-0 w-full bg-slate-900 text-left p-5">
          <div className="text-2xl text-primary font-semibold ">{title}</div>
          <div className="text-lg text-white ">{subtitle}</div>
        </div>
      )}
    </div>
  );
};

export default MediaCard;
