import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FaExclamationTriangle } from 'react-icons/fa'; // Importing the icon
import axios from 'axios'; // Importing axios
import ArticlePage from './ArticlePage';
import HeaderComponent from '../../components/HeaderComponent'; // Import HeaderComponent

const Article = () => {
  const { id } = useParams();
  const [article, setArticle] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchArticle = async () => {
      try {
        const response = await axios.get(`/api/blog_posts/${id}`); // Using axios to fetch article
        const data = response.data;
        if (data.is_published) {
          setArticle(data);
        }
      } catch (error) {
        console.error("Error fetching article:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchArticle();
  }, [id]);

  if (loading) {
    return <div className="flex justify-center items-center h-screen">Loading...</div>; // Loading state centered
  }

  if (!article) {
    return (
      <div className="flex flex-col bg-slate-950">
        <div className="flex flex-col items-center justify-center h-screen">
          <FaExclamationTriangle className="mb-2 text-6xl text-primary" /> {/* Centered icon with yellow color */}
          <h1 className="text-2xl mt-5">Oops! Blog not found.</h1>
        </div>
      </div>
    );
  }

  return (
    <ArticlePage
      id={id}
      article={article} // Use the fetched article data
      authors={article.authors} // Assuming authors are part of the article data
      relatedArticles={article.relatedArticles} // Assuming related articles are part of the article data
    />
  );
};

export default Article;
