import React from 'react';
import LatestUpdatesMobile from './LatestUpdatesMobile';
import LatestUpdatesTab from './LatestUpdatesTab';
import LatestUpdates from './LatestUpdates';
import { useMediaQuery } from 'react-responsive';

const LatestUpdatesResponsive = ({ updates }) => {
  const isDesktop = useMediaQuery({ minWidth: 1025 });
  const isTablet = useMediaQuery({ minWidth: 769, maxWidth: 1024 });
  const isMobile = useMediaQuery({ maxWidth: 768 });

  if (isDesktop) return <LatestUpdates updates={updates} />;
  if (isTablet) return <LatestUpdatesTab updates={updates} />;
  if (isMobile) return <LatestUpdatesMobile updates={updates} />;

  return null;
};

export default LatestUpdatesResponsive;
