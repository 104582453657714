import React, { useState } from 'react';
import axios from 'axios';
import { FaFacebookF, FaInstagram, FaLinkedinIn } from "react-icons/fa"; 
import { useNavigate } from 'react-router-dom'; // Import useNavigate
const ContactUsMobile = () => {
  // State for form data
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
    message: '',
  });
  const [isSubmitted, setIsSubmitted] = useState(false); // New state for submission status
  const navigate = useNavigate(); // Initialize navigate function

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setIsSubmitted(false); 
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post('/api/contact/', JSON.stringify(formData), {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      setIsSubmitted(true);  // Set submission status to true on success
      setFormData({ first_name: '', last_name: '', email: '', phone_number: '', message: '' }); // Reset form
    } catch (error) {
      console.error("Error sending message:", error);
      // Handle error (optional)
    }
  };
  const handleBackToHome = () => {
    setIsSubmitted(false); // Reset the submission status
    navigate('/'); // Navigate to the homepage
  };

  return (
    <div className="relative w-full h-[800px] bg-[#d8d8d8] shadow-[0px_0px_60px_30px_rgba(0,0,0,0.03)] p-1 flex flex-col items-center -mt-40">
      <img
        className="absolute w-[320px] h-[300px] object-cover mt-4" // Adjusted size
        alt="Careers Hero"
        src="/assets/careersheropage.png"
      />
      <div className="absolute inset-0 p-6 top-6 flex flex-col items-center">
        <div className="text-white text-[18px] font-semibold mb-1">Contact Information</div>
        <div className="text-[#c9c9c9] text-[10px] mb-2 text-center">Say something to start a live chat!</div>
        <div className="flex items-center mb-2">
          <img className="w-4 h-4 mr-2" alt="Phone Icon" src="/assets/call.png" />
          <span className="text-white text-[14px]">+91 9827368363</span>
        </div>
        <div className="flex items-center mb-1">
          <img className="w-4 h-4 mr-2" alt="Email Icon" src="/assets/mail.png" />
          <span className="text-white text-[14px]">contact@think41.com</span>
        </div>
        <div className="mb-4 p-1 text-center">
          <img className="w-4 h-4 mr-2 inline" alt="Address Icon" src="/assets/address.png" />
          <div className="text-white text-[14px]">
            <p className="inline">2nd Floor, Obeya Gusto, 367,</p>
            <p className="inline">5th Main Rd, Sector 6, HSR Layout,</p>
            <p className="inline">Bengaluru, Karnataka 560102</p>
          </div>
        </div>
        <div className="flex space-x-2 mb-4 p-1">
          <a href="https://www.facebook.com/Think41corp/" aria-label="Facebook" target="_blank" rel="noopener noreferrer">
            <div className="relative flex items-center justify-center w-7 h-7 rounded-full bg-white">
              <FaFacebookF className="text-orange-500 w-5 h-5" />
            </div>
          </a>
          <a href="https://www.instagram.com/think41corp/" aria-label="Instagram" target="_blank" rel="noopener noreferrer">
            <div className="relative flex items-center justify-center w-7 h-7 rounded-full bg-white">
              <FaInstagram className="text-orange-500 w-5 h-5" />
            </div>
          </a>
          <a href="https://www.linkedin.com/company/think41/" aria-label="LinkedIn" target="_blank" rel="noopener noreferrer">
            <div className="relative flex items-center justify-center w-7 h-7 rounded-full bg-white">
              <FaLinkedinIn className="text-orange-500 w-5 h-5" />
            </div>
          </a>
        </div>

        {/* Conditional rendering for thank you message */}
        {isSubmitted ? (
          <div className="flex-grow mt-4 w-[320px] text-center">
            <img 
                    src={`/assets/thankyou.png`}  // Replace with the actual path to your thank-you image
                    alt="Thank you" 
                    className="w-30 h-30 mb-4 mx-auto"  // Center image with mx-auto
                  />
            <h2 className="text-primary text-2xl font-semibold mb-4">Thank you for contacting us!</h2>
            <p className="text-[#444444] text-lg">
              We have received your message.<br />
              We’ll reach out to you immediately!
            </p>
            <button 
                    onClick={handleBackToHome} 
                    className="bg-primary text-white font-bold px-6 py-3 mt-20 rounded-lg hover:bg-opacity-90"
                  >
                    Back to Homepage
                  </button>
          </div>
        ) : (
          <div className="flex-grow mt-4 w-[320px]">
            <form onSubmit={handleSubmit}>
              <div className="mb-2">
                <label className="block text-gray-800 text-[14px]">First Name</label>
                <input
                  type="text"
                  name="first_name"
                  value={formData.first_name}
                  onChange={handleInputChange}
                  className="w-full border-b-2 bg-[#d8d8d8] border-black focus:outline-none focus:border-orange-500 text-black"
                  placeholder=""
                />
              </div>
              <div className="mb-2">
                <label className="block text-gray-800 text-[14px]">Last Name</label>
                <input
                  type="text"
                  name="last_name"
                  value={formData.last_name}
                  onChange={handleInputChange}
                  className="w-full border-b-2 bg-[#d8d8d8] border-black focus:outline-none focus:border-orange-500 text-black"
                  placeholder=""
                />
              </div>
              <div className="mb-2">
                <label className="block text-gray-800 text-[14px]">Email</label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  className="w-full border-b-2 bg-[#d8d8d8] border-black focus:outline-none focus:border-orange-500 text-black"
                  placeholder=""
                />
              </div>
              <div className="mb-2">
                <label className="block text-gray-800 text-[14px]">Phone Number</label>
                <input
                  type="text"
                  name="phone_number"
                  value={formData.phone_number}
                  onChange={handleInputChange}
                  className="w-full border-b-2 bg-[#d8d8d8] border-black focus:outline-none focus:border-orange-500 text-black"
                  placeholder=""
                />
              </div>
              <div className="mb-2">
                <label className="block text-black text-[14px]">Message</label>
                <input
                  name="message"
                  value={formData.message}
                  onChange={handleInputChange}
                  className="w-full border-b-2 bg-[#d8d8d8] border-black focus:outline-none focus:border-orange-500 text-black"
                  placeholder="Write your message..."
                />
              </div>
              <div className="text-right py-8">
                <button type="submit" className="bg-primary text-white px-6 py-3 font-bold rounded-lg hover:bg-opacity-90" disabled={isSubmitted}>
                  {isSubmitted ? "Submitted" : "Send Message"}
                </button>
              </div>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};

export default ContactUsMobile;
