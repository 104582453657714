// components/AboutUsHero.jsx
import React from 'react';
import { IMAGE_BASE_URL } from '../../config';

const AboutUsHero = () => {
  return (
    <section className="relative w-full h-[500px] md:h-[400px] lg:h-[600px]">
      {/* Background Image */}
      <img 
        className="w-full h-full shadow-lg object-fit" 
        src={`${IMAGE_BASE_URL}aboutUsHero.png`} 
        alt="Hero Background"
      />

      {/* Black Overlay */}
      <div className="absolute inset-0 bg-black opacity-50"></div>

      {/* Hero Text */}
      <div className="absolute top-[20%] md:top-[25%] lg:top-[30%] left-1/2 transform -translate-x-1/2 text-center self-stretch text-3xl font-bold text-[#F16E00] md:text-4xl sm:text-3xl xs:text-2xl break-words w-full">
        Fullstack GenAI Company
      </div>

      {/* Subtext */}
      <div className="absolute mt-[10px] top-[30%] md:top-[35%] lg:top-[40%] left-1/2 transform -translate-x-1/2 text-center  text-zinc-300 text-lg md:text-xl lg:text-xl font-normal leading-6 break-words w-full px-4">
        At Think41, we use Generative AI to hire, operate, build, innovate, and scale.
      </div>
    </section>
  );
};

export default AboutUsHero;