import React from 'react';

const SocialLinks = () => {
  const socialIcons = [
    { src: "https://cdn.builder.io/api/v1/image/assets/9d196419b2b0427f99f4a8814e99f56a/6089a042479e8b5acbceb77cb292da23b6021824e9dcb58d11bd36c480363fb7?apiKey=9d196419b2b0427f99f4a8814e99f56a&", alt: "Facebook Icon", link: "https://www.linkedin.com/company/think41/" },
    { src: "https://cdn.builder.io/api/v1/image/assets/9d196419b2b0427f99f4a8814e99f56a/a5bdb666ca6522e71837ecba8b7ca81a6a503960a803a8ed3e6674f0913e81ee?apiKey=9d196419b2b0427f99f4a8814e99f56a&", alt: "Instagram Icon", link: "https://www.instagram.com/think41corp/" },
    { src: "https://cdn.builder.io/api/v1/image/assets/9d196419b2b0427f99f4a8814e99f56a/612e64e3a75273ea401d6a105c1f45c718afb2c6f43fdd0cad65a640301145e5?apiKey=9d196419b2b0427f99f4a8814e99f56a&", alt: "LinkedIn Icon", link: "https://www.facebook.com/Think41corp/" },
  ];
  return (
    <div className="flex gap-8 items-center self-stretch my-auto h-10 max-md:justify-center">
      {socialIcons.map((icon, index) => (
        <a
          key={index}
          href={icon.link}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            loading="lazy"
            src={icon.src}
            className="object-contain shrink-0 self-stretch my-auto w-10 aspect-square"
            alt={icon.alt}
          />
        </a>
      ))}
    </div>
  );
};

export default SocialLinks;
