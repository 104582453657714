import React from 'react';

const DemoPopup = ({ onClose, directDriveLink }) => { // Added directDriveLink as a prop
  // Add a class for dark background and prevent scrolling
  React.useEffect(() => {
    document.body.style.overflow = 'hidden'; // Prevent scrolling
    return () => {
      document.body.style.overflow = 'unset'; // Restore scrolling on unmount
    };
  }, []);

  return (
    <div className="fixed inset-0 bg-gray-300 backdrop-blur-sm bg-opacity-10 flex justify-center items-center z-100"> {/* Enhanced dark background and higher z-index */}
      <div className="relative w-11/12 max-w-4xl bg-slate-950 rounded-lg p-8 z-90 shadow-lg"> {/* Increased width and padding for a larger component */}
        <button
          onClick={onClose}
          className="absolute top-4 right-4 bg-transparent border-none text-4xl cursor-pointer text-gray-500 hover:text-white" // Increased close button size
        >
          ×
        </button> {/* Enhanced close button styling */}
        <div className="p-4"> {/* Added padding around video */}
          <iframe
            className="w-full h-96 max-md:h-64" // Reduced height on smaller screens
            src={directDriveLink.replace('/view?usp=sharing', '/preview')} // Use the preview link for playback with autoplay and mute
            allow="autoplay; encrypted-media"
            title="Demo Video"
          >
            Your browser does not support the video tag.
          </iframe>
        </div>
      </div>
    </div>
  );
};

export default DemoPopup;
