import React, { useState } from "react";
import InfoModal from "./InfoModal"; // Adjust the import path as necessary

const IntelligentInterfacesCard = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const modalContent =
    "<p>We look to solve for the intuitiveness of voice inputs and natural language text as well as the utility and speed of traditional input mechanisms of forms and clicks. It allows users to provide high level commands and guidance while the agents handle the rest.</p>" +
    "</br>" +
    "<p>You don’t need to play out each step manually or ask your team members. You can now say “Rewrite this paragraph”, “Follow-up on this Action”, “Summarise the feedback” and “What’s the status” to the systems, requests which earlier were given to people and teams.</p>" +
    "</br>" +
    "<p>Primary use cases include CRM, ERP and other rule-based workflows for compliance and internal portals.</p>";

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <>
    <section className="flex flex-row items-start font-semibold justify-between rounded-lg grow gap-3 pr-2.5 pl-8 w-full bg-cyan-950 leading-[62px] max-md:pl-5 max-md:mt-5 relative min-h-[240px]">
      {" "}
      {/* Changed items-center to items-start to keep image at the top */}
      <div className="flex flex-col justify-center items-start h-full w-1/2">
        {" "}
        {/* Centered text shifted to the left */}
        <h2 className="text-5xl tracking-tight text-left pt-8 ml-0 bg-gradient-to-r from-[#E6AA6F] to-[#EF750D] bg-clip-text text-transparent leading-tight max-md:text-3xl max-md:mt-10">
          {" "}
          {/* Added gradient styles and adjusted line height */}
          Intelligent
        </h2>
        <h2 className="text-5xl tracking-tight text-left max-md:text-3xl pt-4 ml-0 bg-gradient-to-r from-[#E6AA6F] to-[#EF750D] bg-clip-text text-transparent">
          {" "}
          {/* Added gradient styles */}
          Interfaces
        </h2>
      </div>
      <div className="w-1/2 flex justify-end items-start">
        {" "}
        {/* Added items-start to keep the image at the top */}
        <img
          loading="lazy"
          src="https://res.cloudinary.com/dnxhznphs/image/upload/v1730113258/Group_1_vr3ial.png"
          alt=""
          className="object-contain w-[150px] max-md:w-[100px] self-start"
        />
      </div>
      <div className="absolute bottom-3 right-3">
        <button
          className="border border-gray-500 rounded-full w-10 h-10 sm:w-8 sm:h-8 flex items-center justify-center shadow-lg bg-transparent hover:border-gray-400"
          onClick={openModal} // This opens the modal
        >
          <span className="text-gray-500 text-2xl sm:text-xl hover:text-gray-400">
            +
          </span>
        </button>
      </div>
      
    </section>
    <InfoModal
    isOpen={isModalOpen}
    onClose={closeModal}
    content={modalContent}
    modalHeader="Intelligent Interfaces - Building interfaces for people and not machines"
  />
  </>
  );
};

export default IntelligentInterfacesCard;
