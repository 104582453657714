import React, { useEffect } from "react";

const InfoModal = ({ isOpen, onClose, content, modalHeader }) => {
  useEffect(() => {
    if (isOpen) {
      // Disable scrolling when the modal is open
      document.body.style.overflow = 'hidden';
    } else {
      // Re-enable scrolling when the modal is closed
      document.body.style.overflow = 'unset';
    }

    // Cleanup function to reset the overflow when the component unmounts
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isOpen]);

  if (!isOpen) return null;

  // Add handler for backdrop clicks
  const handleBackdropClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <div 
      onClick={handleBackdropClick}
      className="fixed inset-0 bg-slate-950 bg-opacity-30 backdrop-blur-sm flex items-center justify-center z-50"
    >
      <div className="bg-slate-950 rounded-[16px] py-[40px] px-6 w-full max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg xl:max-w-2xl relative">
        <h2 className="text-white text-xl mb-3 
                       xl:text-2xl
                       lg:text-xl
                       md:text-lg
                       sm:text-base">{modalHeader}</h2>
        <div className="text-zinc-300 leading-relaxed text-sm 
                        xl:text-base
                        lg:text-sm
                        md:text-xs
                        sm:text-xs" 
             dangerouslySetInnerHTML={{ __html: content }} />
        <button 
          className="absolute top-2 right-2 text-white text-xl 
                     xl:text-2xl
                     lg:text-xl
                     md:text-lg
                     sm:text-lg border-2 border-zinc-300 rounded-full w-10 h-10 sm:w-8 sm:h-8 flex items-center justify-center shadow-lg bg-transparent hover:border-gray-400"
          onClick={onClose}
        >
          <span className="text-zinc-300 text-2xl sm:text-xl hover:text-gray-400 flex items-center justify-center">
            &times;
          </span>
        </button>
      </div>
    </div>
  );
};

export default InfoModal;
