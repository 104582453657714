import React, { useState, useEffect } from 'react';
import { IMAGE_BASE_URL } from '../config';

const MainPageHero = () => {
  const [displayText, setDisplayText] = useState('');
  const words = ['intelligent software', 'innovative solutions', 'transformative apps'];
  const [wordIndex, setWordIndex] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    const currentWord = words[wordIndex];
    const typeSpeed = isDeleting ? 10 : 45;

    const timer = setTimeout(() => {
      if (!isDeleting && displayText === currentWord) {
        setTimeout(() => setIsDeleting(true), 2500);
      } else if (isDeleting && displayText === '') {
        setIsDeleting(false);
        setWordIndex((prev) => (prev + 1) % words.length);
      } else {
        setDisplayText(currentWord.substring(0, isDeleting ? displayText.length - 1 : displayText.length + 1));
      }
    }, typeSpeed);

    return () => clearTimeout(timer);
  }, [displayText, isDeleting, wordIndex]);

  return (
    <div className="relative h-screen bg-cover bg-center" style={{ backgroundImage: `url(${IMAGE_BASE_URL}backgrounds/BG_Hero.png)` }}>
      <div className="absolute inset-0 bg-opacity-50"></div>
      <div className="relative z-10 flex flex-col justify-center h-full px-24 max-md:px-5">
        <h1 className="text-5xl font-bold text-primary mb-4 text-left">
          Reimagining enterprise software <br /> with Generative AI
        </h1>
        <p className="text-2xl text-white mb-8 text-left">
          Crafting <span className="text-white">{displayText}</span>
          <span className="text-primary animate-blink font-bold ml-[2px]">|</span>
        </p>
        <div className="text-left">
          <button 
            className="bg-primary text-white font-bold py-3 px-6 rounded-lg hover:bg-opacity-90 transition duration-300"
            onClick={() => window.location.href = '/demos'}
          >
            Explore Demos
          </button>
        </div>
      </div>
    </div>
  );
};

export default MainPageHero;
