import React, { useState } from "react";
import InfoModal from "../InfoModal"; // Ensure you import the InfoModal

function ProprietoryData() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const modalContent = `<p>Closed-source, high performance, paid / licensed models where there aren’t any compliance and IP restrictions to send data to an LLM. Most providers also provide an enterprise agreement that guarantees proprietary data sent to these LLMs won’t be used for training or other purposes.</p>`;

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <div className="flex items-center justify-center w-full sm:px-4 sm:pt-6 -mb-16 relative">
      <img 
        src="/assets/Cylinder.png"
        alt="Vector Stores"
        className="w-full h-full object-cover md:w-[90%] lg:w-[70%]"
      />

      <button 
        className="absolute text-center w-11/12 sm:w-3/4 lg:w-1/2 pt-4" 
        style={{ 
          top: '50%', 
          left: '50%', 
          transform: 'translate(-50%, -50%)',
          maxWidth: 'calc(100% - 2rem)' 
        }}
        onClick={openModal} // This opens the modal
      >
        <h2 className="text-[16px] sm:text-[36px] tracking-tight text-[#8BC5E1] bg-clip-text xs:-mb-2 sm:-mb-4 xs:pt-4 sm:pt-8">
          Proprietary
        </h2>
        <h2 className="text-[16px] sm:text-[30px] tracking-tight text-[#8BC5E1] bg-clip-text mt-[-4px] md:mt-[10px]">
        Data
        </h2>
      </button>

      <InfoModal isOpen={isModalOpen} content={modalContent} onClose={closeModal} modalHeader="Proprietary Data" />
    </div>
  );
}

export default ProprietoryData;
