import React, { useEffect, useState } from 'react';
import MainPageHero from '../components/MainPageHero';
import GenerativeAITransformationView from '../components/GenerativeAITransformView';
import AutoPod from '../components/AutoPod';
import TechRadar from '../components/TechRadar';
import TechRadarResponsive from '../components/TechRadarResponsive';
import DemoSection from '../components/Home/DemoSection';
import LatestInsights from '../components/Home/LatestInsights';

function Home() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const isLargeScreen = windowWidth > 1280;

  return (
    <div className="home">
      <main>
        <MainPageHero />
        <GenerativeAITransformationView />
        <AutoPod/>
        {isLargeScreen ? <TechRadar/> : <TechRadarResponsive/>}
        <div className="bg-[#01111A]">
          <DemoSection /> 
        </div>
        <div className="bg-[#01111A] py-20">
          <LatestInsights />
        </div>
      </main>
    </div>
  );
}

export default Home;
