import React from 'react';
import Header from '../components/Header';
import ContactUs from '../components/ContactUs/ContactUs';

const ContactUsPage = () => {
  return (
    <div className="contactus" style={{ backgroundColor: '#01111A' }}>
      {/* Main Content */}
      <main>
        <ContactUs />
      </main>
      
      {/* Optional: If you want to ensure there's consistent styling around the footer */}
      <div className="h-20 bg-[#01111A]" /> {/* This adds a 40px height gap if needed */}
    </div>
  );
};

export default ContactUsPage;
