import React from 'react';
import { IMAGE_BASE_URL } from '../../config';

// BenefitCard Component
function BenefitCard({ imageSrc, title }) {
    return (
        <div className="flex flex-col items-center w-full sm:w-[120px] md:w-[180px]">
            <img
                loading="lazy"
                src={imageSrc}
                alt={title}
                className="object-contain self-center rounded-lg w-[80px] h-[80px]" // Adjusted size for mobile
            />
            <h3 className="flex-1 gap-4 p-2 mt-3.5 w-full text-[16px] text-center text-[#d8d8d8] md:text-[18px]">
                {title}
            </h3>
        </div>
    );
}

// Benefits Section Data
const benefits = [
    { imageSrc: `${IMAGE_BASE_URL}Work.png`, title: "Flexible Work Hours" },
    { imageSrc: `${IMAGE_BASE_URL}Food.png`, title: "Free Meals" },
    { imageSrc: `${IMAGE_BASE_URL}Knowledge.png`, title: "Incubator for New Ideas" },
    { imageSrc: `${IMAGE_BASE_URL}Fun.png`, title: "Fun With Purpose" },
    { imageSrc: `${IMAGE_BASE_URL}Wellness.png`, title: "Health and Wellness Programs" }
];

// Benefits Section Component
function BenefitsSection() {
    return (
        <section className="grid grid-cols-2 md:grid-cols-5 justify-center md:gap-3 lg:gap-20 px-5 md:px-10 lg:px-[100px] sm:px-[50px] xs:px-[25px]">
            {benefits.slice(0, 4).map((benefit, index) => (
                <BenefitCard 
                    key={index} 
                    imageSrc={benefit.imageSrc} 
                    title={benefit.title} 
                />
            ))}
            {/* Center the fifth item */}
            <div className="flex justify-center col-span-2 md:col-span-1">
                <BenefitCard 
                    imageSrc={benefits[4].imageSrc} 
                    title={benefits[4].title} 
                />
            </div>
        </section>
    );
}

// Facilities Component
function Facilities() {
    return (
        <>
            <BenefitsSection />
            <style jsx>{`
                builder-component {
                    max-width: none !important;
                }
            `}</style>
        </>
    );
}

export default Facilities;
