import React, { useEffect, useState } from 'react';
import UpdateCardResponsive from './UpdateCardResponsive';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css'; // Update Swiper styles import


function ShowcaseTab() {
  const [updates, setUpdates] = useState([]);

  useEffect(() => {
    async function fetchLatestUpdates() {
      try {
        const response = await fetch('/blog_posts/published/');
        if (!response.ok) throw new Error('Failed to fetch blog posts');
        
        const data = await response.json();
        setUpdates(data);
      } catch (error) {
        console.error('Error fetching latest blog posts:', error);
      }
    }

    fetchLatestUpdates();
  }, []);
  return (
    <section className="w-full overflow-hidden">
      <header className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-bold text-orange-500">Showcase</h2>
      </header>

      <Swiper slidesPerView={2} spaceBetween={20} style={{ paddingRight: '50px' }}>
        {updates.map((update, index) => (
          <SwiperSlide key={index}>
            <UpdateCardResponsive
              key={update.id}
              title={update.heading}
              imageSrc={update.image1}
              introduction={update.preview_text}
              blogPostId={update.id}
            />
          </SwiperSlide>
        ))}
        {/* Add an extra empty slide to show half of the third card */}
        <SwiperSlide style={{ opacity: 0 }}>
          <div className="h-full w-full"></div>
        </SwiperSlide>
      </Swiper>
    </section>
  );
}

export default ShowcaseTab;
