import React from 'react';
import { IMAGE_BASE_URL } from '../../config';
const LifeAtThink41 = () => {
  return (
    <div className="flex flex-col-reverse lg:flex-row text-white">
      {/* Text Section */}
      <div className="w-full lg:w-1/2 lg:pl-[100px] px-6 py-12">
        <h2 className="text-[#f16e00] text-[18px] md:text-[24px] lg:text-[30px]  font-bold mb-4">Life At Think 41</h2>
        <p className="mb-6 text-[14px] md:text-[16px] lg:text-[18px]">
          At Think41, work is a blend of creativity, collaboration, and growth. Dive into a
          vibrant environment where your ideas drive our mission, and every day is an
          opportunity to learn and have fun.
        </p>
        
        <h2 className="text-[#f16e00] text-[18px] md:text-[24px] lg:text-[30px]  font-bold mb-4">Our Culture and Values</h2>
        <p className="mb-4 text-[16px] lg:text-[18px]">
          Our core values guide everything we do. We aim to always deliver client
          delight by exceeding expectations at every opportunity. We work to earn
          trust through transparency, reliability, and accountability.
        </p>
        <p className="mb-4 text-[14px] md:text-[16px] lg:text-[18px]">
          At Think41, we play as a team, believing that collaboration and mutual
          support drive the best results. We continuously strive to be an expert in our
          field, delivering high-quality output, staying ahead of industry trends, and
          advancing our skills. Finally, we take extreme ownership of our actions,
          ensuring responsibility and commitment to delivering the best outcomes.
        </p>
      </div>

      {/* Image Section */}
      <div className="w-full lg:w-1/2">
        <img 
          src={`${IMAGE_BASE_URL}lifeatthink41.png`} 
          alt="Woman working on AI interface" 
          className="w-full h-auto max-h-[523px] object-cover rounded-[8px_8px_8px_8px] lg:rounded-[8px_0px_0px_8px]"
        />
      </div>
    </div>
  );
};

export default LifeAtThink41;