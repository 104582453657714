import React, { useState } from 'react';
import { FaTwitter, FaLinkedin, FaInstagram, FaTimes, FaEdit } from 'react-icons/fa'; // Removed unused FaCamera imports
import axios from 'axios';
import Loader from '../Loader'; // Import the loading component

const AddAuthorModal = ({ isOpen, onClose, onAddAuthor }) => {
  console.log("Modal isOpen:", isOpen); // Debugging line

  const [authorData, setAuthorData] = useState({
    name: '',
    image: '',
    designation: '',
    about: '',
    social_links: {},
  });

  const [loading, setLoading] = useState(false); // New loading state

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAuthorData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSocialLinkChange = (platform, value) => {
    setAuthorData((prevData) => ({
      ...prevData,
      social_links: { ...prevData.social_links, [platform]: value },
    }));
  };

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) {
      console.error("No file selected for upload.");
      return; // Exit if no file is selected
    }
    const formData = new FormData();
    formData.append('file', file); // Changed 'image' to 'file' to match the server's expected parameter name

    setLoading(true); // Set loading to true when starting upload
    try {
      const response = await axios.post('/api/upload/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setAuthorData((prevData) => ({ ...prevData, image: response.data.url })); // Ensure correct property for image URL
    } catch (error) {
      console.error("Error uploading image:", error);
    } finally {
      setLoading(false); // Set loading to false after upload
    }
  };

  const handleSubmit = async () => {
    // Validate required fields before submission
    if (!authorData.name || !authorData.designation || !authorData.about) {
      console.error("All fields are required.");
      return; // Exit if required fields are missing
    }
    setLoading(true); // Set loading to true when starting submission
    try {
      await onAddAuthor(authorData); // Assuming onAddAuthor is async
    } catch (error) {
      console.error("Error adding author:", error);
    } finally {
      setLoading(false); // Set loading to false after submission
      onClose();
    }
  };

  const triggerFileInput = () => {
    document.getElementById('imageUploadInput').click(); // Trigger the file input click
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-20 flex justify-center items-center z-50 backdrop-blur-sm"> {/* Added backdrop-blur class */}
      <div className="bg-gray-950 p-8 rounded-lg shadow-lg w-11/12 max-w-lg mt-4">
        <div className="flex justify-between items-center border-b border-gray-700 pb-4">
          <h2 className="text-2xl font-bold text-orange-500">Add New Author</h2>
          <button onClick={onClose} className="text-white hover:text-orange-500 transition">
            <FaTimes size={24} />
          </button>
        </div>
        <div className="mb-4 flex flex-col items-center">
          <div className="mt-4 w-24 h-24 rounded-full overflow-hidden border-2 border-orange-500 flex items-center justify-center bg-gray-800">
            {loading ? (
              <Loader /> // Display loading dots in the image circle
            ) : authorData.image ? (
              <img src={authorData.image} alt="Author" className="w-full h-full object-cover" />
            ) : (
              <div className="flex flex-col items-center">
                <button onClick={triggerFileInput} className="w-full h-full text-white font-semibold flex items-center justify-center">
                  Add Photo
                </button>
              </div>
            )}
          </div>
          {authorData.image && (
            <div className="flex items-center mt-2">
              <button onClick={triggerFileInput} className="text-orange-500">Change Photo</button>
              <FaEdit className="text-orange-500 ml-2" /> {/* Added edit icon beside Change Photo */}
            </div>
          )}
          <input
            type="file"
            id="imageUploadInput"
            onChange={handleImageUpload}
            className="hidden" // Hide the file input
          />
        </div>
        <input
          type="text"
          name="name"
          placeholder="Name"
          value={authorData.name}
          onChange={handleChange}
          className="w-full p-3 border border-orange-500 rounded mb-2 bg-gray-700 text-white"
        />
        <input
          type="text"
          name="designation"
          placeholder="Designation"
          value={authorData.designation}
          onChange={handleChange}
          className="w-full p-3 border border-orange-500 rounded mb-2 bg-gray-700 text-white"
        />
        <textarea
          name="about"
          placeholder="About"
          value={authorData.about}
          onChange={handleChange}
          className="w-full p-3 border border-orange-500 rounded mb-2 bg-gray-700 text-white"
        />
        <div className="mb-2 relative">
          <FaTwitter className="absolute left-3 top-1/2 transform -translate-y-1/2 text-orange-500 text-lg" />
          <input
            type="text"
            name="social_links.twitter"
            placeholder="Twitter"
            onChange={(e) => handleSocialLinkChange('twitter', e.target.value)}
            className="w-full p-3 border border-orange-500 rounded bg-gray-700 text-white pl-10" // Added padding-left for icon
          />
        </div>
        <div className="mb-2 relative">
          <FaLinkedin className="absolute left-3 top-1/2 transform -translate-y-1/2 text-orange-500 text-lg" />
          <input
            type="text"
            name="social_links.linkedin"
            placeholder="LinkedIn"
            onChange={(e) => handleSocialLinkChange('linkedin', e.target.value)}
            className="w-full p-3 border border-orange-500 rounded bg-gray-700 text-white pl-10" // Added padding-left for icon
          />
        </div>
        <div className="mb-4 relative">
          <FaInstagram className="absolute left-3 top-1/2 transform -translate-y-1/2 text-orange-500 text-lg" />
          <input
            type="text"
            name="social_links.instagram"
            placeholder="Instagram"
            onChange={(e) => handleSocialLinkChange('instagram', e.target.value)}
            className="w-full p-3 border border-orange-500 rounded bg-gray-700 text-white pl-10" // Added padding-left for icon
          />
        </div>
        <button onClick={handleSubmit} className="bg-orange-500 text-white p-3 rounded hover:bg-orange-400 w-full">Add Author</button>
      </div>
    </div>
  );
};

export default AddAuthorModal;
