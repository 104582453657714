import * as React from "react";

export function FounderCard({ name, image, imageType, info, link }) {
  return (
    <article className="flex flex-col min-w-[240px] w-[295px]">
      <div 
        className="relative flex flex-col justify-center items-center px-7 py-7 rounded-lg bg-white transition-all duration-300 ease-in-out group 
                    shadow-[0_4px_8px_rgba(0,0,0,0.08),0_1px_3px_rgba(0,0,0,0.25)] hover:shadow-[0_6px_12px_rgba(0,0,0,0.15),0_4px_6px_rgba(0,0,0,0.1)] transform hover:-translate-y-2"
        style={{ width: '295px', height: '324px' }}
      >
        {/* Card content on non-hover */}
        <div className="flex flex-col items-center group-hover:hidden">
          <img 
            loading="lazy" 
            src={image} 
            alt={`${name} - Founder`}
            className={`object-contain self-end max-w-full aspect-square shadow-[4px_4px_12px_1px_rgba(0,0,0,0.15)] w-[235px] ${imageType === "round" ? "rounded-full" : ""}`}
          />
          <h3 className="mt-3 text-2xl text-orange-500">{name}</h3>
        </div>

        {/* Card content on hover */}
        <div className="absolute top-0 left-0 right-0 bottom-0 flex flex-col items-center justify-center p-5 rounded-lg bg-[#01111A] opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-in-out">
          <p className="text-white font-normal text-[14px] text-center mb-3">{info}</p>
          <a 
            href={link}
            className="mt-4 px-4 py-2 rounded-md bg-white text-[#F57C00] font-medium text-sm transition duration-300 ease-in-out hover:bg-[#F57C00] hover:text-white"
          >
            LinkedIn
          </a>
        </div>
      </div>
    </article>
  );
}