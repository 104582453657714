import React, { useState } from 'react';
import DemoPopup from './DemoPopup';
const FeatureCard = ({ title, subtitle, features, buttonText, demoYTLink, demo_link, is_try, is_watch }) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const handleOpenPopup = () => {
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  return (
    <div className="flex flex-col grow justify-center items-start mt-10 rounded-2xl border-2 border-solid border-[#312D34] h-[400px] w-[130%] z-5"> {/* Increased width */}
      <div className="flex flex-col w-full overflow-visible">
        <div className="flex flex-col items-start w-full">
          <h3 className="text-3xl font-bold text-orange-500 text-left pl-8">{title}</h3>
          <p className="text-lg leading-none text-zinc-300 text-left break-words max-w-[70%] pl-8">{subtitle}</p>
        </div>
        <ul className="flex flex-col mt-8 w-full text-sm leading-6 text-zinc-300 items-start overflow-visible">
          {features.map((feature, index) => (
            <li key={index} className="flex gap-2.5 items-center mt-1.5 w-full justify-start overflow-visible pl-8">
              <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/728e7f2d1a96680b06158aab96322a9ebd14d0d3dc9f59ffa5d8f7a32c036b9c?placeholderIfAbsent=true&apiKey=2a0ad5d14c014777b1f4b2f82bd16488" alt="" className="object-contain shrink-0 w-4 aspect-square" />
              <span className="self-stretch">{feature}</span>
            </li>
          ))}
        </ul>
        <div className="flex gap-8 items-center mt-8 w-full text-base font-medium text-white justify-start pl-8">
          {is_watch ? (
            <button href="#" onClick={handleOpenPopup} className="gap-2.5 self-stretch px-8 py-2.5 bg-primary hover:bg-opacity-90 rounded">
              Watch Demo
            </button>
          ) : is_try ? (
            <a href={demo_link} target="_blank" rel="noopener noreferrer" className="gap-2.5 self-stretch px-8 py-2.5 bg-primary hover:bg-opacity-90 rounded">
              Try It Out
            </a>
          ) : (
            <a href="/contact" className="gap-2.5 self-stretch px-8 py-2.5 bg-primary hover:bg-opacity-90 rounded">
              Get in touch
            </a>
          )}
        </div>
      </div>
      {isPopupOpen && <DemoPopup directDriveLink={demoYTLink} onClose={handleClosePopup} />}
    </div>
  );
};

export default FeatureCard;
