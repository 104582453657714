import * as React from "react";
import { FounderCard } from "./FoundersCard";
import { foundersData } from "./FoundersData";

export function FoundersSection() {
  return (
    <section className="flex flex-col py-24 font-semibold text-center bg-white">
      <header className="flex flex-col self-center max-w-full text-4xl text-black w-[400px]">
        <h2 className="self-center">
          <span className="text-slate-950">Meet our</span>{" "}
          <span className="text-orange-500">Founders</span>
        </h2>
        <div className="self-stretch mt-4 w-full h-[5px] bg-gradient-to-r from-white via-[#F57C00] to-white -mx-6" />
        <div className="mt-4 w-full min-h-[5px]" />
      </header>
      <div className="px-24 mt-8 max-md:px-5">
        <div className="flex flex-wrap gap-5 justify-between items-start max-md:justify-center">
          {foundersData.map((founder) => (
            <FounderCard
              key={founder.id}
              name={founder.name}
              image={founder.image}
              imageType={founder.imageType}
              info={founder.info}
              link={founder.link}
            />
          ))}
        </div>
      </div>
    </section>
  );
}