import React, { useState } from "react";
import InfoModal from "./InfoModal"; // Ensure you import the InfoModal

function AgenticWorkflows() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const modalContent = `<p>Data is the differentiator in a world where intelligence is getting commoditized. Mix proprietary data with foundational models to process and derive analytics, insights, and understanding, while ensuring governance, compliance, and control with careful system design using techniques like Retrieval Augmented Generation, Knowledge Graphs, and Role-Based Access Control.</p>`;

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <>
      <section
        className="flex flex-col items-center justify-center rounded-lg py-3 bg-cyan-950 min-h-[200px] bg-gradient-to-b from-[#698da2] to-transparent backdrop-blur-lg sm:min-h-[220px] md:min-h-[200px] px-6 max-lg:px-4 -mr-20 max-sm:-mr-0 max-xl:-mr-36 relative  max-w-screen-lg"
        aria-label="Agentic Workflows Card"
      >
        <h2 className="text-2xl sm:text-3xl max-md:text-xl lg:text-4xl font-semibold tracking-tight leading-none text-blue-200 text-center">
          Reference Data
        </h2>
        <p className="max-md:text-sm text-base sm:text-lg lg:text-xl text-blue-300 text-center pt-2">
          RAG with Role <br /> Based Access Control
        </p>
        <div className="absolute bottom-3 right-3 sm:bottom-2 sm:right-2">
        <button 
          className="border border-gray-500 rounded-full w-10 h-10 sm:w-8 sm:h-8 flex items-center justify-center shadow-lg bg-transparent hover:border-gray-400"
          onClick={openModal} // This opens the modal
        >
          <span className="text-gray-500 text-2xl sm:text-xl hover:text-gray-400">+</span>
        </button>
      </div>
      </section>
      <InfoModal
        isOpen={isModalOpen}
        onClose={closeModal}
        content={modalContent} // This passes the content to the modal
        modalHeader="Reference Data - Essential Information"
      />
    </>
  );
}

export default AgenticWorkflows;
