import React, { useState, useEffect } from "react";
import {
  FaPlus,
  FaTrashAlt,
  FaInstagram,
  FaLinkedin,
  FaTwitter,
  FaTimes,
  FaEdit,
  FaCamera,
  FaEye // Import eye icon
} from "react-icons/fa"; // Added camera icon import
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import axios from "axios";
import AddAuthorModal from "../../components/Blogs/AddAuthorModal";
import AuthorInfoModal from "../../components/Blogs/AuthorInfoModal"; // New modal for author info
import { useNavigate } from "react-router-dom"; // Import useNavigate
import Loader from "../../components/Loader"; // Import Loader component

function BlogAddPage() {
  const [sections, setSections] = useState([
    { heading: "", content: "", image: null },
  ]);
  const [formData, setFormData] = useState({
    heading: "",
    author: { name: "", image: null, designation: "", about: ""}, // Added 'about' field
    intro: "",
    image1: null,
  });
  const [authors, setAuthors] = useState([]); // State to hold authors
  const [authorInput, setAuthorInput] = useState(""); // State for manual author input
  const [socialLinks, setSocialLinks] = useState({}); // State for social links
  const [selectedAuthors, setSelectedAuthors] = useState([]); // State to hold selected authors
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAuthorInfoModalOpen, setIsAuthorInfoModalOpen] = useState(false); // State for author info modal
  const [selectedAuthorName, setSelectedAuthorName] = useState(""); // State to hold selected author name
  const [selectedAuthorDetails, setSelectedAuthorDetails] = useState(null); // State to hold selected author details
  const navigate = useNavigate(); // Initialize useNavigate
  const [loading, setLoading] = useState(false); // State for loading state
  const [blogPostPreviewText, setBlogPostPreviewText] = useState(""); // New state for preview text

  // Fetch authors on component mount
  useEffect(() => {
    const fetchAuthors = async () => {
      try {
        const response = await axios.get("/api/authors/");
        setAuthors(response.data);
      } catch (error) {
        console.error("Error fetching authors:", error);
      }
    };
    fetchAuthors();
  }, []);

  const handleAddSection = () => {
    setSections([...sections, { heading: "", content: "", image: null }]);
  };

  const handleRemoveSection = (index) => {
    const newSections = sections.filter((_, i) => i !== index);
    setSections(newSections);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name.startsWith("author.")) {
      const authorField = name.split(".")[1];
      setFormData((prevData) => ({
        ...prevData,
        author: { ...prevData.author, [authorField]: value },
      }));
    } else {
      setFormData((prevData) => ({ ...prevData, [name]: value })); // Ensure all fields are updated
    }
  };

  const handleSectionChange = (index, field, value) => {
    const newSections = [...sections];
    newSections[index][field] = value;
    setSections(newSections);
  };

  const handleImageUpload = async (file, field) => {
    const formData = new FormData();
    formData.append("file", file);

    try {
      setLoading(true); // Set loading state to true
      const response = await axios.post(
        "/api/upload/",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      const imageUrl = response.data.url;
      if (field.startsWith("author.")) {
        setFormData((prevData) => ({
          ...prevData,
          author: { ...prevData.author, [field.split(".")[1]]: imageUrl },
        }));
      } else if (field.startsWith("section.")) {
        const sectionIndex = parseInt(field.split(".")[1]);
        const newSections = [...sections];
        newSections[sectionIndex].image = imageUrl;
        setSections(newSections);
      } else {
        setFormData((prevData) => ({ ...prevData, [field]: imageUrl }));
      }
    } catch (error) {
      console.error("Error uploading image:", error);
    } finally {
      setLoading(false); // Set loading state to false
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const authorIds = selectedAuthors.map((author) => author.id);

    const blogPostData = {
        heading: formData.heading,
        intro: formData.intro,
        image1: formData.image1,
        author_ids: authorIds,
        sections: sections.map((section) => ({
            heading: section.heading,
            content: section.content,
            image: section.image,
        })),
        is_published: false,
        preview_text: blogPostPreviewText,
    };

    try {
        // Create blog post with sections
        const blogPostResponse = await fetch(
            "/api/blog_posts/",
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(blogPostData),
            }
        );
        const blogPostResult = await blogPostResponse.json();

        // Extract the secret key from the current URL
        const urlParams = new URLSearchParams(window.location.search);
        const secretKey = urlParams.get('secret'); // Assuming the key is named 'secretKey'

        // Redirect to the new blog post page with the secret key
        navigate(`/blog-preview/${blogPostResult.id}?secret=${secretKey}`);
        console.log("Blog post created successfully");
    } catch (error) {
        console.error("Error:", error);
    }
};

  const handleMouseDown = (e) => {
    e.preventDefault();
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  };

  const handleMouseMove = (e) => {
    const leftPanel = document.getElementById("leftPanel");
    const newWidth = e.clientX; // Get the new width based on mouse position
    leftPanel.style.width = `${newWidth}px`; // Set the new width
    const rightPanel = document.getElementById("rightPanel");
    rightPanel.style.width = `calc(100% - ${newWidth}px)`; // Adjust right panel width accordingly
  };

  const handleMouseUp = () => {
    document.removeEventListener("mousemove", handleMouseMove);
    document.removeEventListener("mouseup", handleMouseUp);
  };

  const handleAuthorSelect = (e) => {
    const selectedAuthor = authors.find((author) => author.name === e.target.value);
    if (selectedAuthor) {
      setSelectedAuthors((prevSelected) => [...prevSelected, selectedAuthor]); // Add selected author
      setSelectedAuthorName(""); // Reset the dropdown to "Select an author"
    } else {
      setSelectedAuthorName(e.target.value); // Update selected author name
    }
  };

  const handleAuthorRemove = (authorId) => {
    setSelectedAuthors(selectedAuthors.filter((author) => author.id !== authorId));
  };

  const handleAddAuthor = async (newAuthor) => {
    try {
      const response = await axios.post("/api/authors/", {
        authors: [newAuthor],
      });
      setAuthors((prevAuthors) => [...prevAuthors, ...response.data]);
    } catch (error) {
      console.error("Error adding author:", error);
    }
  };

  const handleAuthorClick = (author) => {
    setSelectedAuthorDetails(author); // Set the selected author details
    setIsAuthorInfoModalOpen(true); // Open the author info modal
  };

  const getImageStyle = (image) => {
    return {
      maxWidth: '100%',
      maxHeight: '300px', // Set a max height for the image
      objectFit: 'contain', // Maintain aspect ratio
    };
  };

  const handlePreviewTextChange = (e) => {
    const { value } = e.target;
    const wordCount = value.split(/\s+/).filter(Boolean).length; // Count words
    if (wordCount <= 50) {
      setBlogPostPreviewText(value); // Update state if within limit
    }
  };

  return (
    <div className="flex bg-black text-white w-full pt-10 px-28 pb-20">
      <div id="leftPanel" className="w-2/3 p-6 overflow-y-auto h-screen scrollbar-thin scrollbar-thumb-gray-700 scrollbar-track-gray-900"> {/* Changed width to 2/3 and added scrollbar styles */}
        <h1 className="text-3xl text-orange-500 mb-6 text-center font-bold">
          Add a Blog
        </h1>
        {/* Author Section */}
        <h2 className="text-2xl text-orange-500 mb-4 text-center font-bold pt-4">
          Authors
        </h2>
        <hr className="border-t border-orange-500 mb-4" />
        <select
          id="authorName"
          value={selectedAuthorName} // Set the value of the select to the state
          onChange={handleAuthorSelect}
          className="w-full p-4 border border-orange-500 rounded bg-gray-800 text-lg mt-2"
        >
          <option value="">Select an author</option>
          {authors.map((author) => (
            <option key={author.id} value={author.name}>
              {author.name}
            </option>
          ))}
        </select>
        <button
          onClick={() => setIsModalOpen(true)}
          className="mt-2 p-2 border border-orange-500 bg-black text-white rounded hover:text-primary"
        >
          + New Author
        </button>
        <AddAuthorModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          onAddAuthor={handleAddAuthor}
          author={null} // Pass null to indicate adding a new author
        />
        {selectedAuthors.length > 0 && (
          <h3 className="text-lg text-orange-500 mb-2 mt-4">Selected Authors:</h3>
        )}
        <div className="flex flex-wrap mb-2">
          {selectedAuthors.map((author) => (
            <div key={author.id} className="flex items-center p-2 border border-orange-500 rounded-full mr-2 cursor-pointer bg-gray-800" style={{ marginTop: '10px', width: 'fit-content' }} onClick={() => handleAuthorClick(author)}>
              <button
                onClick={(e) => { e.stopPropagation(); handleAuthorRemove(author.id); }} // Prevent triggering author click
                className="text-primary hover:text-primary mr-2" // Moved to the left side
                aria-label="Remove author"
              >
                <FaTimes className="text-xl" /> {/* Increased size of the icon */}
              </button>
              <h3 className="text-lg font-bold mr-2">{author.name}</h3>
              <FaEye className="text-primary text-lg" onClick={() => handleAuthorClick(author)} /> {/* Eye icon for viewing author info */}
            </div>
          ))}
        </div>

        {/* Blog Post Preview Text Section */}
        <label htmlFor="blogPostPreviewText" className="mt-4 mb-2 block text-orange-500 text-xl">
          Blog Post Preview Text (max 50 words)
        </label>
        <textarea
          id="blogPostPreviewText"
          name="blogPostPreviewText"
          placeholder="Enter your blog post preview text"
          onChange={handlePreviewTextChange}
          className="w-full p-4 border border-orange-500 rounded bg-gray-800 text-lg"
          value={blogPostPreviewText} // Bind the textarea to the state
          rows="3" // Set the number of visible rows
          style={{ resize: 'vertical' }} // Allow vertical resizing
        />
        <p className="text-orange-500">
          {blogPostPreviewText.split(/\s+/).filter(Boolean).length} / 50 words
        </p>

        {/* Blog Post Heading and Image Section */}
        <h2 className="text-2xl text-orange-500 mb-4 text-center font-bold pt-4">
          Blog Post
        </h2>
        <hr className="border-t border-orange-500 mb-4" />
        <label htmlFor="heading" className="mt-4 mb-2 block text-orange-500 text-xl">
          Blog Post Heading
        </label>
        <input
          type="text"
          id="heading"
          name="heading"
          placeholder="Enter your blog post heading"
          onChange={handleChange}
          className="w-full p-4 border border-orange-500 rounded bg-gray-800 text-lg"
        />
        {/* Blog Main Image Section */}
        <label htmlFor="blogImage" className="mt-4 mb-2 block text-orange-500 text-xl">
          Blog Main Image
        </label>
        <div className="flex items-center justify-center rounded border-2 border-orange-500">
          {loading ? ( // Show loader while uploading
            <Loader />
          ) : (
            <>
              {formData.image1 ? ( // Check if an image is uploaded
                <img
                  src={formData.image1}
                  alt="Blog Main"
                  style={getImageStyle(formData.image1)} // Adjust image style based on dimensions
                />
              ) : (
                <button
                  onClick={() => document.getElementById("blogImage").click()} // Trigger file input click
                  className=" bg-black text-white font-semibold flex items-center text-lg p-6 hover:text-primary" // Increased font size
                >
                  <FaCamera className="mr-2" />
                  Upload your image here
                </button>
              )}
              <input
                type="file"
                id="blogImage"
                onChange={(e) => handleImageUpload(e.target.files[0], "image1")}
                className="hidden"
              />
            </>
          )}
        </div>
        {formData.image1 && ( // Show Change Image link if an image is uploaded
          <div className="flex items-center mt-2">
            <button
              type="button"
              onClick={() => document.getElementById("blogImage").click()} // Trigger file input click for reupload
              className="ml-4  bg-black text-white flex items-center hover:text-primary"
            >
              <FaEdit className="mr-2 text-primary" /> <span>Change Image</span>
            </button>
          </div>
        )}

        <label htmlFor="blogIntro" className="mt-4 mb-2 block text-orange-500 text-xl">
          Blog Introduction Paragraph
        </label>
        <div className="border-4 border-orange-500 rounded">
          <SunEditor
            onChange={(content) =>
              setFormData((prevData) => ({ ...prevData, intro: content }))
            }
            setOptions={{
              minHeight: 400,
              width: "100%",
              buttonList: [
                ["fontSize", "bold", "italic", "underline", "strike"],
                ["list", "indent", "outdent"],
                ["align", "image", "video", "link"],
                ["undo", "redo", "fullScreen", "preview"],
              ],
            }}
          />
        </div>

        {/* Sections Section */}
        <h2 className="text-2xl text-orange-500 mb-4 text-center font-bold pt-4">
          Sections
        </h2>
        <hr className="border-t border-orange-500 mb-4" />
        {sections.map((section, index) => (
          <div key={index} className="space-y-2">
            <label
              htmlFor={`sectionHeading${index}`}
              className="mt-4 mb-2 block text-orange-500 text-xl"
            >
              Section {index + 1} Heading
            </label>
            <input
              type="text"
              id={`sectionHeading${index}`}
              placeholder={`Enter section ${index + 1} heading`}
              onChange={(e) =>
                handleSectionChange(index, "heading", e.target.value)
              }
              className="w-full p-4 border border-orange-500 rounded bg-gray-800 text-lg"
            />
            <label
              htmlFor={`sectionContent${index}`}
              className="mt-4 mb-2 block text-orange-500 text-xl"
            >
              Section {index + 1} Content
            </label>
            <div className="border-4 border-orange-500 rounded">
              <SunEditor
                onChange={(content) =>
                  handleSectionChange(index, "content", content) // Update this line to use handleSectionChange
                }
                setOptions={{
                  minHeight: 400,
                  width: "100%",
                  buttonList: [
                    ["fontSize", "bold", "italic", "underline", "strike"],
                    ["list", "indent", "outdent"],
                    ["align", "image", "video", "link"],
                    ["undo", "redo", "fullScreen", "preview"],
                  ],
                }}
              />
            </div>
            <label
              htmlFor={`sectionImage${index}`}
              className="mt-4 mb-2 block text-orange-500 text-xl"
            >
              Section {index + 1} Image
            </label>
            <div className="w-full h-auto flex items-center justify-center rounded border-2 border-orange-500">
              {loading ? ( // Show loader while uploading
                <Loader />
              ) : (
                <>
                  {section.image ? ( // Check if an image is uploaded
                    <img
                      src={section.image}
                      alt={`Section ${index + 1}`}
                      style={getImageStyle(section.image)} // Adjust image style based on dimensions
                    />
                  ) : (
                    <button
                      onClick={() => document.getElementById(`sectionImage${index}`).click()} // Trigger file input click
                      className=" bg-black text-white font-semibold flex items-center text-lg p-6 hover:text-primary"
                    >
                      <FaCamera className="mr-2" />
                      Upload your image here
                    </button>
                  )}
                  <input
                    type="file"
                    id={`sectionImage${index}`}
                    onChange={(e) => handleImageUpload(e.target.files[0], `section.${index}`)}
                    className="hidden"
                  />
                </>
              )}
            </div>
            {section.image && ( // Show Change Image link if an image is uploaded
              <div className="flex items-center mt-2">
                <span className="ml-4 text-primary underline">
                  <FaEdit />
                </span>
                <button
                  type="button"
                  onClick={() => document.getElementById(`sectionImage${index}`).click()} // Trigger file input click for reupload
                  className="ml-2  bg-black text-white hover:text-primary"
                >
                  Change Image
                </button>
              </div>
            )}
            <br />
            <div className="mt-4"> {/* Added margin-top for spacing */}
              <button
                type="button"
                onClick={() => handleRemoveSection(index)}
                className="p-2 border border-orange-500 bg-black text-white flex items-center hover:text-primary"
              >
                <FaTrashAlt  />
                <span className="ml-2"> Section</span>
              </button>
            </div>
          </div>
        ))}
        <div className="pt-10">
          <button
            type="button"
            onClick={handleAddSection}
            className="flex items-center space-x-2 p-2 mb-4 border border-orange-500 bg-black text-white hover:text-primary"
          >
            <FaPlus />
            <span>Add a new Section</span>
          </button>
        </div>
        <button
          type="submit"
          onClick={handleSubmit}
          className="w-full p-2 border border-orange-500 bg-black text-white text-xl hover:text-primary"
        >
          Preview Changes
        </button>
      </div>
      <div
        className="resizer cursor-col-resize"
        onMouseDown={handleMouseDown}
        style={{ width: "5px", backgroundColor: "gray" }}
      />
      <div id="rightPanel" className="w-1/3 h-screen overflow-y-auto pt-8 sticky top-0 scrollbar-thin scrollbar-thumb-gray-700 scrollbar-track-gray-900"> {/* Changed width to 1/3 and added scrollbar styles */}
        <h2 className="text-2xl font-bold text-orange-500 mb-4 text-center">
          Sample Blog Page
        </h2>
        <img
          src="/assets/BlogPageOverview.png"
          alt="Blog Page Overview"
          className="w-full h-auto"
        />
      </div>

      {/* Author Info Modal */}
      <AuthorInfoModal
        isOpen={isAuthorInfoModalOpen}
        onClose={() => setIsAuthorInfoModalOpen(false)}
        author={selectedAuthorDetails} // Pass the selected author details
      />
    </div>
  );
}

export default BlogAddPage;
