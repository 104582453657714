import React, { useState } from "react";
import InfoModal from "./InfoModal"; // Ensure you import the InfoModal

function ProprietoryData() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const modalContent = `<p>Use learned embeddings to convert and store text as vectors, which can later be queried for a similarity search. This technique allows for enhanced data retrieval and analysis, making it easier to find relevant information based on contextual similarities.</p>
  </br>
  <p>By leveraging these embeddings, systems can improve user engagement and facilitate a more dynamic interaction with the data.</p>`;

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <div className="flex items-center justify-center relative w-full max-w-screen-xl mx-auto p-4 ml-12" style={{ height: '220px' }}>
       <img 
        src="/assets/Cylinder.png"
        alt="Vector Stores"
        className="w-full h-full" // Updated to match mobile version scaling
      />
      <div className="absolute text-center w-11/12 sm:w-3/4 lg:w-1/2 mr-1 mb-4" >
        <h2 className="text-2xl text-white text-center mt-5 font-bold bg-gradient-to-r from-[#BFDFF1] to-[#3D6B86] bg-clip-text ">
          Vector <br /> Stores
        </h2>
      </div>
      <div className="absolute pt-4 mt-16 pr-12 max-lg:bottom-4">
      <button
          className="border border-gray-500 rounded-full ml-11 mt-4 w-8 h-8 flex items-center justify-center shadow-lg bg-transparent hover:border-gray-400"
          onClick={openModal} // This opens the modal
        >
          <span className="text-gray-500 text-2xl sm:text-xl hover:text-gray-400">
            +
          </span>
        </button>
      </div>
      <InfoModal isOpen={isModalOpen} content={modalContent} onClose={closeModal} modalHeader="Vector Stores" /> {/* Ensure modal is controlled by isOpen state */}
    </div>
  );
}

export default ProprietoryData;
