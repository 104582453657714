import React, { useState } from "react";
import InfoModal from "./InfoModal"; // Ensure you import the InfoModal

function ProprietoryModels() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const modalContent = `<p>Agentic Workflows enable multiple agents to examine a problem from their unique perspectives, providing feedback and next steps. By combining insights, they can suggest decisions, summaries, and present these to a human in the loop or take actions based on the criticality of the decision.</p>
  </br>
  <p>Additionally, leveraging function calling allows for the dynamic invocation of specific operations, enabling AI models to execute tasks and retrieve real-time data seamlessly. This enhances interactivity, precision, and improves maintainability.</p>`;

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <>
    <section 
      className="flex flex-col items-center justify-center rounded-lg py-3 bg-cyan-950 min-h-[200px] relative bg-gradient-to-b from-[#698da2] to-transparent backdrop-blur-md w-full"
      aria-label="Agentic Workflows Card"
    >
      <h2 className="text-4xl  max-lg:text-3xl font-semibold tracking-tight leading-none text-slate-50 text-center max-md:-mt-6">
        Agentic Workflows
      </h2>
      <div className="absolute bottom-3 right-3 sm:bottom-2 sm:right-2">
        <button 
          className="border border-gray-500 rounded-full w-10 h-10 sm:w-8 sm:h-8 flex items-center justify-center shadow-lg bg-transparent hover:border-gray-400"
          onClick={openModal} // This opens the modal
        >
          <span className="text-gray-500 text-2xl sm:text-xl hover:text-gray-400">+</span>
        </button>
      </div>

    
    </section>
      <InfoModal 
      isOpen={isModalOpen} 
      onClose={closeModal} 
      content={modalContent} // This passes the content to the modal
      modalHeader="Agentic Workflows - Empowering Users"
    />
    </>
  );
}

export default ProprietoryModels;
