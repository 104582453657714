import React, { useState, useEffect } from 'react';
import Header from './Header';
import MobileHeader from './Mobile/MobileHeader'; // New mobile header component

function HeaderComponent() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1253); // Adjust breakpoint as needed
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Check on initial load

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
      {isMobile ? <MobileHeader /> : <Header />}
    </>
  );
}

export default HeaderComponent;
