import React, { useState, useEffect, useCallback } from "react";
import MediaCard from "./MediaCard";
import { motion, AnimatePresence } from "framer-motion";
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { IMAGE_BASE_URL } from '../../config';
const mediaItems = [
  { type: 'image', src: `${IMAGE_BASE_URL}home1.png`, title: 'Recruit 41', subtitle: 'Smart Recruiting: Harnessing AI for Talent Selection' },
  { type: 'video', src: `${IMAGE_BASE_URL}insights.mp4`, title: 'Text Analytics', subtitle: 'Transforming natural language into structured data using AI' },
  { type: 'video', src: `${IMAGE_BASE_URL}dev41.mp4`, title: 'Dev41', subtitle: 'Revolutionizing Development with AI' }
];

const HomeCarousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [touchStart, setTouchStart] = useState(0);
  const [touchEnd, setTouchEnd] = useState(0);
  const [isPaused, setIsPaused] = useState(false);
  const navigate = useNavigate(); // Initialize useNavigate

  useEffect(() => {
    if (isPaused) return;
    
    const interval = setInterval(() => {
      nextSlide();
    }, 5000);
    
    return () => clearInterval(interval);
  }, [currentIndex, isPaused]);

  const nextSlide = useCallback(() => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % mediaItems.length);
  }, []);

  const prevSlide = useCallback(() => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + mediaItems.length) % mediaItems.length);
  }, []);

  const handleTouchStart = (e) => {
    setTouchStart(e.touches[0].clientX);
    setIsPaused(true);
  };

  const handleTouchMove = (e) => {
    setTouchEnd(e.touches[0].clientX);
  };

  const handleTouchEnd = () => {
    setIsPaused(false);
    if (touchStart - touchEnd > 75) {
      nextSlide();
    }
    if (touchStart - touchEnd < -75) {
      prevSlide();
    }
  };

  return (
    <div className="w-full mx-auto">
      <div 
        className="relative w-full h-[500px] overflow-hidden shadow-none"
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        <AnimatePresence mode="wait">
          <motion.div
            key={currentIndex}
            initial={{ opacity: 0, x: 300 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -300 }}
            transition={{ duration: 0.5 }}
            className="absolute inset-0"
          >
            <MediaCard
              type={mediaItems[currentIndex].type}
              src={mediaItems[currentIndex].src}
              title={mediaItems[currentIndex].title}
              subtitle={mediaItems[currentIndex].subtitle}
              isActive={true}
            />
          </motion.div>
        </AnimatePresence>

        <div className="absolute bottom-36 left-0 right-0">
          <div className="flex justify-center gap-2">
            {mediaItems.map((_, index) => (
              <motion.button
                key={index}
                whileHover={{ scale: 1.2 }}
                whileTap={{ scale: 0.9 }}
                onClick={() => setCurrentIndex(index)}
                className={`transition-all duration-300 ease-in-out rounded-full h-2.5
                  ${index === currentIndex 
                    ? 'w-10 bg-[#F16E00]' 
                    : 'w-2.5 bg-white/70 hover:bg-white'}`}
              />
            ))}
          </div>
        </div>
        
        <div className="absolute bottom-8 max-lg:bottom-12 left-0 right-0 flex justify-center">
          <button 
            onClick={() => {navigate('/demos'); window.scrollTo(0, 0);}}
            className="px-4 py-2 bg-primary text-white rounded hover:bg-opacity-90 transition"
            
          >
            Explore Demos
          </button>
        </div>
      </div>
    </div>
  );
};

export default HomeCarousel;
