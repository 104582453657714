import React, { useState } from "react";
import InfoModal from "./InfoModal"; // Ensure you import the InfoModal

function OpenWeight() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const modalContent = `<p>Open-source, flexible, modifiable, customizable, free models that can be self-hosted and used where proprietary data can’t be sent to public LLMs due to compliance or IP restrictions.</p>`;

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <section 
      className="flex flex-col items-center justify-center rounded-lg py-3 bg-cyan-950  relative w-full max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8 max-lg:min-h-[180px] h-[377px]"
      aria-label="Open Weight Card"
    >
      <svg 
        xmlns="http://www.w3.org/2000/svg" 
        width="181" 
        height="59" 
        viewBox="0 0 181 59" 
        fill="none" 
        className="absolute top-4 left-0 w-24 h-auto sm:w-32 max-md:top-1 max-md:w-16"
      >
        <g filter="url(#filter0_di_2243_251)">
          <path fillRule="evenodd" clipRule="evenodd" d="M171.089 13.0728C175.458 13.0728 179 10.1464 179 6.53641C179 2.92645 175.458 0 171.089 0C169.762 0 168.512 0.269894 167.413 0.746863C164.886 1.8446 162.385 3.53661 159.629 3.53661L114.266 3.5366C107.669 3.5366 101.544 6.95849 98.086 12.5763L78.286 44.7404C75.9198 48.5842 71.7292 50.9255 67.2155 50.9255L3.96582 50.9255C2.30896 50.9255 0.96582 52.2686 0.96582 53.9255C0.96582 55.5823 2.30896 56.9255 3.96582 56.9255L67.2155 56.9255C73.8124 56.9255 79.9372 53.5036 83.3955 47.8858L103.195 15.7217C105.562 11.8779 109.752 9.5366 114.266 9.5366L159.63 9.5366C162.385 9.53661 164.886 11.2283 167.413 12.326C168.512 12.8029 169.762 13.0728 171.089 13.0728Z" fill="#110A2B" fillOpacity="0.25" shapeRendering="crispEdges"/>
        </g>
        <defs>
          <filter id="filter0_di_2243_251" x="0.96582" y="0" width="180.034" height="58.9255" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feOffset dx="1" dy="1"/>
            <feGaussianBlur stdDeviation="0.5"/>
            <feComposite in2="hardAlpha" operator="out"/>
            <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.07 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2243_251"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2243_251" result="shape"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feOffset dx="1" dy="1"/>
            <feGaussianBlur stdDeviation="2"/>
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0.00547059 0 0 0 0 0.0187459 0 0 0 0 0.116098 0 0 0 0.55 0"/>
            <feBlend mode="normal" in2="shape" result="effect2_innerShadow_2243_251"/>
          </filter>
        </defs>
        <circle cx="172" cy="6" r="4" fill="orange" />
        <line x1="30" y1="54" x2="60" y2="54" stroke="orange" strokeWidth="3" />
      </svg>
      <h2 className="text-4xl max-lg:text-2xl font-semibold tracking-tight leading-none text-transparent bg-clip-text bg-gradient-to-r from-[#F5F1FF] to-[#2190D0] text-center max-md:text-xl max-md:-mt-2">
        Open Weight <br /> Models
      </h2>
      <p className="pt-4 text-2xl max-lg:text-lg text-transparent bg-clip-text bg-gradient-to-r from-[#F5F1FF] to-[#2190D0] text-center max-md:text-xs max-md:pb-4 max-md:-mt-4">
        Public/Private Cloud <br /> GPU Computer
      </p>
      <div className="absolute bottom-3 right-3 sm:bottom-4 sm:right-4">
      <button 
          className="border border-gray-500 rounded-full w-10 h-10 sm:w-8 sm:h-8 flex items-center justify-center shadow-lg bg-transparent hover:border-gray-400"
          onClick={openModal} // This opens the modal
        >
          <span className="text-gray-500 text-2xl sm:text-xl hover:text-gray-400">+</span>
        </button>
      </div>

      <InfoModal 
        isOpen={isModalOpen} 
        onClose={closeModal} 
        content={modalContent} // This passes the content to the modal
        modalHeader="Open Weight Models"
      />
    </section>
  );
}

export default OpenWeight;
