import React from 'react';

const HeroSection = () => {
  return (
    <section className="flex relative z-10 flex-col justify-center px-20 py-40 -mt-1 w-full min-h-[608px] max-md:px-[1.5rem] max-md:py-24 max-md:max-w-full">
      <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/477a58c4af5e933ce22da0bc3b6543ad9f61437e7a8d3895242a6a221a69d420?placeholderIfAbsent=true&apiKey=2a0ad5d14c014777b1f4b2f82bd16488" alt="" className="object-cover absolute inset-0 size-full" />
      <div className="flex relative flex-col items-center -mb-8 max-md:mb-2.5 max-md:max-w-full">
        <h1 className="text-5xl font-bold text-center text-orange-500 w-[700px] max-md:max-w-full max-md:text-2xl">
          Unlock New Possibilities with Our GenAI Tools
        </h1>
        <p className="mt-12 text-2xl leading-6 text-center text-zinc-300 w-[700px] max-md:mt-10 max-md:max-w-full max-md:text-lg">
          Experience the future of AI-driven innovation with our cutting-edge tools designed for tomorrow's challenges.
        </p>
        <button onClick={() => window.location.href = '/contact'} className="gap-2.5 px-8 py-2.5 mt-12 text-base font-medium text-white bg-primary hover:bg-opacity-90 rounded max-md:px-5 max-md:mt-10 max-md:text-sm">
          Get in Touch
        </button>
      </div>
    </section>
  );
};

export default HeroSection;