import React, { useState } from "react";
import InfoModal from "./InfoModal"; // Ensure you import the InfoModal

function AgenticWorkflows() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const modalContent = `<p>Closed-source, high performance, paid / licensed models where there aren’t any compliance and IP restrictions to send data to an LLM. Most providers also provide an enterprise agreement that guarantees proprietary data sent to these LLMs won’t be used for training or other purposes.</p>`;

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <>
    <section
      className="flex flex-col items-center justify-center rounded-lg py-3 bg-cyan-950 min-h-[180px] relative max-xl:-mr-36 -mr-20 max-sm:-mr-0"
      aria-label="Agentic Workflows Card"
    >
      <h2 className="text-4xl max-lg:text-2xl font-semibold tracking-tight leading-none text-transparent bg-clip-text bg-gradient-to-r from-[#F5F1FF] to-[#2190D0] text-center max-md:text-xl max-md:-mt-2">
        Proprietary Models
      </h2>

      <p className="text-transparent bg-clip-text bg-gradient-to-r from-[#F5F1FF] to-[#2190D0] text-2xl max-lg:text-lg pt-4 text-center max-md:text-sm max-md:pb-4 max-md:-mt-4">
        Public/Private Cloud GPU Computer
      </p>
      <div className="absolute bottom-3 right-3 z-30">
        <button
          className="border border-gray-500 rounded-full w-10 h-10 sm:w-8 sm:h-8 flex items-center justify-center shadow-lg bg-transparent hover:border-gray-400"
          onClick={openModal} // This opens the modal
        >
          <span className="text-gray-500 text-2xl sm:text-xl hover:text-gray-400">
            +
          </span>
        </button>
      </div>

     

    </section>
     <InfoModal
     isOpen={isModalOpen}
     onClose={closeModal}
     content={modalContent} // This passes the content to the modal
     modalHeader="Proprietary Models"
   />
   </>
  );
}

export default AgenticWorkflows;
