import React from "react";

function Boot41() {
  return (
    <main className="flex flex-col p-24 max-md:px-5">
      <header className="flex flex-col items-center self-center max-w-full text-center text-zinc-300 w-[660px]">
        <h1 className="text-[18px] md:text-[24px] lg:text-[30px] font-bold">Boot41</h1>

        {/* Line with gradient */}
        <div className="self-stretch mt-4 w-full h-[5px] bg-gradient-to-r from-[#01111A] via-[#F57C00] to-[#01111A] -mx-6" />
      </header>

      <p className="mt-4 text-[14px] md:text-[16px] lg:text-[18px] leading-[24px]  text-[#d8d8d8] text-center max-w-[700px] mx-auto">
        Bootcamps at Think41 are high-energy, hands-on programs designed to transform new hires into top-notch Think41 professionals. Through a dynamic combination of intense learning, interactive activities, and deep dives into full-stack development and AI, you'll gain practical skills and a forward-thinking mindset.
      </p>
      <p className="text-[14px] md:text-[16px] lg:text-[18px] leading-[24px]  text-[#d8d8d8] text-center max-w-[700px] mx-auto mt-[30px]">
        Connect with industry leaders, collaborate with peers, and immerse yourself in AI's exciting possibilities. Our bootcamp equips you with the expertise and enthusiasm to excel in our innovative tech-driven culture.
      </p>
    </main>
  );
}

export default Boot41;