import React, { useState } from 'react';
import NavItem from './NavItem';

const navItems = [
  { label: 'About us', href: '/about' },
  { label: 'Careers', href: '/careers' },
  { label: 'Contact Us', href: '/contact' }
];

function Header() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  // Function to toggle mobile menu visibility
  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <div className="fixed top-0 left-0 right-0 z-50 flex flex-wrap gap-2.5 justify-between items-center px-6 py-2 md:px-10 lg:px-28 bg-black bg-opacity-50 backdrop-filter backdrop-blur-md transition-opacity duration-300 ease-in-out">
      <h1 onClick={() => window.location.href = '/'} className="text-lg md:text-xl lg:text-2xl font-bold text-orange-500 whitespace-nowrap cursor-pointer">
        Think41
      </h1>
      
      {/* Hamburger menu icon for mobile view */}
      <div className="md:hidden">
        <button onClick={toggleMobileMenu} className="text-white">
          {/* Hamburger icon */}
          {isMobileMenuOpen ? '✖' : '☰'}
        </button>
      </div>
      
      {/* Desktop nav (hidden on mobile) */}
      <nav className="hidden md:flex gap-4 items-center text-white">
        {navItems.map((item, index) => (
          <NavItem key={index} label={item.label} href={item.href} />
        ))}
      </nav>

      {/* Mobile menu */}
      {isMobileMenuOpen && (
        <div className="absolute top-full left-0 right-0 bg-black bg-opacity-50 backdrop-filter backdrop-blur-md py-4 transition-opacity duration-300 ease-in-out md:hidden">
          <ul className="flex flex-col items-center gap-4 text-white text-lg">
            {navItems.map((item, index) => (
              <li key={index}>
                <NavItem label={item.label} href={item.href} />
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

export default Header;
