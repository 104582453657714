import React from 'react';
import { IMAGE_BASE_URL } from '../../config';
function OpenPosition() {
  return (
    <section className="relative flex flex-col items-center justify-center h-[400px] md:h-[500px] lg:h-[600px] w-full mb-0">
      {/* Blurred Background Image */}
      <div
        className="absolute inset-0 bg-cover bg-center filter blur-sm"
        style={{
          backgroundImage: `url('${IMAGE_BASE_URL}NewsLetter.png')`,
        }}
      ></div>

      {/* Centered Frame Content */}
      <div className="relative z-10 flex flex-col items-center justify-center gap-4 md:gap-6 lg:gap-8 mt-8 md:mt-12 lg:mt-16 text-center px-4">
        <div className="text-[20px] md:text-[28px] lg:text-[32px] font-semibold text-[#f16e00]">
          Want to Explore Open Positions?
        </div>
        <div className="max-w-[300px] md:max-w-[450px] lg:max-w-[600px] text-[14px] md:text-[16px] lg:text-[18px] text-[#d8d8d8]">
          Explore new career opportunities and find the perfect job for you. Discover positions that match your skills and interests.
        </div>
        <a 
          href="https://think.recruit41.com/" 
          target="_blank" 
          rel="noopener noreferrer" 
          className="px-4 py-3 md:px-6 md:py-3 lg:px-6 lg:py-3 rounded-lg bg-primary hover:bg-opacity-90 text-white font-bold text-sm md:text-base lg:text-lg transition duration-300 ease-in-out"
        >
          Open Positions
        </a>
      </div>
    </section>
  );
}

export default OpenPosition;