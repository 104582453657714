import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom'; // Import useParams to get the id from the URL

import ArticleHeader from '../../components/Blogs/ArticleHeader';
import ArticleContent from '../../components/Blogs/ArticleContent';
import AuthorCard from '../../components/Blogs/AuthorCard';

const BlogPreviewPage = () => {
  const { id } = useParams(); // Get the id from the URL parameters
  const [authorData, setAuthorData] = useState([]);
  const [articleData, setArticleData] = useState(null);
  const [isPublished, setIsPublished] = useState(false); // Track publication status
  const navigate = useNavigate(); // Use navigate for navigation

  useEffect(() => {
    // Fetch author and article data similar to ArticlePage
    const fetchData = async () => {
      try {
        const authorResponse = await fetch(`/api/blog_posts/${id}/authors`);
        const authorData = await authorResponse.json();
        setAuthorData(authorData);

        const articleResponse = await fetch(`/api/blog_posts/${id}`);
        const articleData = await articleResponse.json();
        setArticleData(articleData);
        // Add a new state to track the publication status
        setIsPublished(articleData.is_published); // Track publication status
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [id]);

  const handlePublish = async () => {
    const newPublishState = !isPublished;
    try {
      await fetch(`/api/blog_posts/${id}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ is_published: newPublishState }),
      });
      setIsPublished(newPublishState); // Update local state to reflect the change
      if (newPublishState) {
        navigate(`/blog/${id}`); // Redirect only if published
      }
    } catch (error) {
      console.error("Error toggling article publish state:", error);
    }
  };

  const handleEdit = () => {
    // Extract the secret key from the current URL
    const urlParams = new URLSearchParams(window.location.search);
    const secretKey = urlParams.get('secret'); // Assuming the key is named 'secretKey'
    
    // Redirect to the edit page with the secret key
    navigate(`/edit-blog/${id}?secret=${secretKey}`);
  };

  if (!authorData.length || !articleData) {
    return <div>Loading...</div>;
  }

  return (
    <div className="flex overflow-hidden flex-col bg-slate-950"> {/* Updated background color */}
      <div className="flex justify-between items-center p-4 border-b border-primary text-white mt-16 mx-24"> {/* Added mt-16 for margin-top */}
        <h1 className="text-4xl font-bold text-primary">Blog Post Preview</h1>
        <div>
          <button onClick={handlePublish} className="bg-black border border-primary hover:bg-gray-800 text-white font-bold py-2 px-4 rounded mx-2">
            {isPublished ? 'Unpublish' : 'Publish'}
          </button>
          <button onClick={handleEdit} className="bg-black border border-primary hover:bg-gray-800 text-white font-bold py-2 px-4 rounded mx-2">
            Edit
          </button>
        </div>
      </div>
      <main className=''>
        <ArticleHeader
          title={articleData.heading}
          authors={authorData}
          date={articleData.published_date}
          readTime={articleData.read_time}
        />
        <ArticleContent id={id} />
      </main>
    </div>
  );
};

export default BlogPreviewPage;
