import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Header from "../../components/Header";
import { FaEdit, FaChevronLeft, FaChevronRight } from "react-icons/fa";

function BlogDashboard() {
  const [blogs, setBlogs] = useState([]);
  const [authors, setAuthors] = useState([]); // New state for authors
  const [selectedAuthor, setSelectedAuthor] = useState(null); // New state for selected author
  const [sortOrder, setSortOrder] = useState("asc"); // New state for sort order
  const [searchQuery, setSearchQuery] = useState(""); // New state for search query
  const [currentPage, setCurrentPage] = useState(1);
  const [blogsPerPage] = useState(6);
  const navigate = useNavigate();
  // Add a new state for filtering by publication status
  const [publicationStatus, setPublicationStatus] = useState("all"); // New state for publication status

  useEffect(() => {
    // Fetch authors from the backend
    const fetchAuthors = async () => {
      try {
        const response = await axios.get("/api/authors/");
        setAuthors(response.data); // Assuming authors are returned as an array
      } catch (error) {
        console.error("Error fetching authors:", error);
      }
    };
    fetchAuthors();
  }, []);

  useEffect(() => {
    // Fetch blog posts from the backend
    const fetchBlogs = async () => {
      try {
        const response = await axios.get("/api/blog_posts/");
        const blogsWithAuthors = await Promise.all(response.data.map(async (blog) => {
          const authorResponse = await axios.get(`/api/blog_posts/${blog.id}/authors`);
          return { ...blog, authors: authorResponse.data }; // Assuming the authors are returned as an array
        }));
        setBlogs(blogsWithAuthors);
      } catch (error) {
        console.error("Error fetching blog posts:", error);
      }
    };
    fetchBlogs();
  }, []);

  // Update the filteredBlogs logic to correctly handle both published and unpublished statuses
  const filteredBlogs = blogs.filter(blog => 
    (selectedAuthor ? blog.authors.some(author => author.id === parseInt(selectedAuthor)) : true) &&
    (blog.heading.toLowerCase().includes(searchQuery.toLowerCase()) || 
     blog.authors.some(author => author.name.toLowerCase().includes(searchQuery.toLowerCase()))) &&
    (publicationStatus === "all" || 
     (publicationStatus === "published" && blog.is_published === true) || // Correctly filter published blogs
     (publicationStatus === "unpublished" && (blog.is_published === false || blog.is_published === null))) // Correctly filter unpublished blogs
  );

  // Sort blogs by published date
  const sortedBlogs = filteredBlogs.sort((a, b) => {
    const dateA = new Date(a.published_date);
    const dateB = new Date(b.published_date);
    return sortOrder === "asc" ? dateA - dateB : dateB - dateA; // Ascending or descending order
  });

  // Calculate the number of pages
  const indexOfLastBlog = currentPage * blogsPerPage;
  const indexOfFirstBlog = indexOfLastBlog - blogsPerPage;
  const currentBlogs = sortedBlogs.slice(indexOfFirstBlog, indexOfLastBlog);
  const totalPages = Math.ceil(blogs.length / blogsPerPage);

  // Function to change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleEdit = (id) => {
    // Extract the secret key from the current URL
    const urlParams = new URLSearchParams(window.location.search);
    const secretKey = urlParams.get('secret'); // Assuming the key is named 'secretKey'
    
    // Navigate to the edit page for the selected blog with the secret key
    navigate(`/edit-blog/${id}?secret=${secretKey}`);
  };

  const handleViewMore = (id) => {
    // Extract the secret key from the current URL
    const urlParams = new URLSearchParams(window.location.search);
    const secretKey = urlParams.get('secret'); // Assuming the key is named 'secretKey'
    
    // Open the blog view page for the selected blog in a new window with the secret key
    window.open(`/blog-preview/${id}?secret=${secretKey}`, '_blank');
  };

  const formatPublishedDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: 'numeric', month: 'long', year: 'numeric' };
    return `Published on: ${date.toLocaleDateString('en-GB', options)}`; // Format date
  };

  return (
    <>
      <div className="bg-black min-h-screen">
        <div className="fixed top-0 left-0 right-0 z-10">
        </div>
        <div className="text-white p-6 pt-20 pb-20 px-28 max-md:px-5 flex flex-col">
          <h1 className="text-3xl font-bold mb-4" onClick={() => window.location.reload()} style={{ cursor: 'pointer' }}>Blogs Dashboard</h1>
          <hr className="border-t border-orange-500 mb-10" />
          <div className="flex mb-4 justify-between"> {/* Adjusted for additional search bar */}
            <div className="flex-grow mr-4" style={{ flexBasis: 'calc(66.666% - 1rem)' }}> {/* Search bar container with calculated width */}
              <label className="text-sm mb-1 block">Search:</label>
              <input
                type="text"
                className="p-2 w-full rounded bg-black border-2 border-primary" // Set height to full
                style={{ height: '38px' }} // Uniform height for all input elements
                placeholder="Search by blog or author name"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
            <div className="flex ml-2" style={{ flexBasis: 'calc(33.333% - 1rem)' }}> {/* Container for filters with calculated width */}
              <div className="flex flex-row justify-between w-full"> {/* Horizontal layout for filters */}
                <div className="mr-2"> {/* Individual filter container */}
                  <label className="text-sm mb-1 block">Filter by:</label>
                  <select 
                    className="p-2 rounded bg-black border-2 border-primary" // Set height to full
                    style={{ height: '38px' }} // Uniform height for all input elements
                    onChange={(e) => setSelectedAuthor(e.target.value)} 
                    value={selectedAuthor || ""}
                  >
                    <option value="">All Authors</option>
                    {authors.map(author => (
                      <option key={author.id} value={author.id}>{author.name}</option>
                    ))}
                  </select>
                </div>
                <div className="mr-2"> {/* Individual sort container */}
                  <label className="text-sm mb-1 block">Sort by:</label>
                  <select 
                    className="p-2 rounded bg-black border-2 border-primary" // Set height to full
                    style={{ height: '38px' }} // Uniform height for all input elements
                    onChange={(e) => setSortOrder(e.target.value)} 
                    value={sortOrder}
                  >
                    <option value="asc">Date: Ascending</option>
                    <option value="desc">Date: Descending</option>
                  </select>
                </div>
                <div className="mr-2"> {/* Individual publication status container */}
                  <label className="text-sm mb-1 block">Publication Status:</label>
                  <select 
                    className="p-2 rounded bg-black border-2 border-primary" // Set height to full
                    style={{ height: '38px' }} // Uniform height for all input elements
                    onChange={(e) => setPublicationStatus(e.target.value)} 
                    value={publicationStatus}
                  >
                    <option value="all">All</option>
                    <option value="published">Published</option>
                    <option value="unpublished">Unpublished</option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 flex-grow">
            {currentBlogs.map((blog) => (
              <div
                key={blog.id}
                className="bg-gray-800 rounded-lg shadow-lg transition-transform transform hover:scale-105 flex flex-col justify-between border-2 border-orange-500" // Removed padding from the card
              >
                <div className="relative w-full h-44 rounded-lg overflow-hidden p-2"> {/* Increased height for the image container */}
                  <img
                    src={blog.image1}
                    alt={blog.heading}
                    className="absolute inset-0 w-full h-full object-cover rounded-lg" // Ensure the image covers the entire div with rounded corners
                  />
                </div>
                <div className="p-4"> {/* Added padding for the content area */}
                  <h2 className="text-xl font-bold text-orange-500 mb-2">{blog.heading}</h2> {/* Increased heading size */}
                  <p className="text-gray-300 mb-2">
                    by <span className="font-semibold text-orange-100 p-1">{blog.authors.map(author => author.name).join(', ')}</span>
                  </p> {/* Display authors' names under the heading with italics styling */}
                  <p className="text-gray-300 text-sm mb-2">{formatPublishedDate(blog.published_date)}</p> {/* Display formatted published date */}
                  <div className="text-gray-300 mb-4 h-24 overflow-hidden"> {/* Fixed height for uniformity */}
                    <div
                      dangerouslySetInnerHTML={{
                        __html:
                          blog.intro.split(" ").slice(0, 30).join(" ") +
                          (blog.intro.split(" ").length > 30 ? "..." : ""), // Always append ellipsis if intro exceeds 30 words
                      }}
                    />
                  </div>
                </div>
                <div className="flex justify-between mt-4 p-4"> {/* Added padding for the button area */}
                  <button
                    className="text-primary py-1 px-2 rounded hover:text-orange-700"
                    onClick={() => handleViewMore(blog.id)}
                  >
                    View More
                  </button>
                  <button
                    className="text-primary py-1 px-2 rounded hover:text-orange-700"
                    onClick={() => handleEdit(blog.id)}
                  >
                    <FaEdit />
                  </button>
                </div>
              </div>
            ))}
          </div>
          <div className="flex justify-center items-center space-x-4 mt-12">
            <button 
              onClick={() => setCurrentPage(prev => prev > 1 ? prev - 1 : prev)}
              disabled={currentPage === 1}
              className="p-2 rounded bg-black text-white"
            >
              <FaChevronLeft className="text-orange-500" /> {/* Orange arrow for previous */}
            </button>
            <span className="text-orange-500">Page {currentPage} of {totalPages}</span>
            <button 
              onClick={() => setCurrentPage(prev => prev < totalPages ? prev + 1 : prev)}
              disabled={currentPage === totalPages}
              className="p-2 rounded bg-black text-white"
            >
              <FaChevronRight className="text-orange-500" /> {/* Orange arrow for next */}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default BlogDashboard;
