import React from "react";
import { FaTwitter, FaLinkedin, FaInstagram, FaUser } from "react-icons/fa"; // Import icons from react-icons/fa
import { FaXTwitter } from "react-icons/fa6";
const AuthorCard = ({ name, title, bio, image, socialLinks }) => {
  return (
    <div className="flex flex-col flex-1 shrink my-5 p-5 bg-orange-50 rounded-lg min-w-[240px] max-w-[50%] shadow-[0px_4px_7px_rgba(0,0,0,0.15)] max-md:max-w-full">
      <div className="flex flex-wrap gap-5 items-center w-full max-md:max-w-full">
        {image ? (
          <img
            loading="lazy"
            src={image}
            alt={`${name}'s profile`}
            className="object-contain shrink-0 self-stretch my-auto rounded-full aspect-square shadow-[0px_4px_7px_rgba(0,0,0,0.25)] w-[100px]"
          />
        ) : (
          <FaUser className="w-[100px] h-[100px] text-gray-500 rounded-full" />
        )}
        <div className="flex flex-col items-start self-stretch my-auto min-w-[240px] w-[310px]">
          <div className="flex flex-col max-w-full text-black w-[492px]">
            <div className="text-2xl font-semibold max-md:max-w-full">
              {name}
            </div>
            <div className="mt-1.5 text-base max-md:max-w-full">{title}</div>
          </div>
          <div className="flex gap-1 items-center pr-2 mt-1 h-10">
            {socialLinks && socialLinks.length > 0 ? (
              socialLinks.map((link, index) =>
                link.url ? (
                  <a
                    key={index}
                    href={
                      link.url.startsWith("http")
                        ? link.url
                        : `https://${link.url}`
                    }
                    aria-label={link.name}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {link.name === "Twitter" && (
                      <FaXTwitter className="w-8 h-8 text-orange-500" />
                    )}
                    {link.name === "LinkedIn" && (
                      <FaLinkedin className="w-8 h-8 text-orange-500" />
                    )}
                    {link.name === "Instagram" && (
                      <FaInstagram className="w-8 h-8 text-orange-500" />
                    )}
                  </a>
                ) : null
              )
            ) : (
              <span className="text-gray-500">No social links available</span>
            )}
          </div>
        </div>
      </div>
      <div className="flex flex-col mt-5 w-full text-base leading-5 text-black max-md:max-w-full">
        <p className="w-full max-md:max-w-full">{bio}</p>
      </div>
    </div>
  );
};

export default AuthorCard;
