import React from 'react';
import { IMAGE_BASE_URL } from '../../config';
const CareersIntro = () => {
  return (
    <div className="flex flex-col lg:flex-row lg:gap-6 text-white">
      {/* Image Section */}
      <div className="w-full lg:w-1/2">
        <img 
          src={`${IMAGE_BASE_URL}careersabout.png`} 
          alt="Person working at computer" 
          className="w-full h-auto max-h-[400px] object-cover rounded-[8px_8px_8px_8px] lg:rounded-[0px_8px_8px_0px] max-w-full" 
        />
      </div>

      {/* Text Section */}
      <div className="w-full lg:w-1/2 px-6 lg:pl-6 lg:pr-[100px] py-8 flex flex-col justify-center">
        <h2 className="text-[#F16E00] text-[18px] md:text-[24px] lg:text-[30px] font-bold mb-4">
          AI is in Our DNA
        </h2>
        
        <p className="text-[#D8D8D8] text-[14px] md:text-[16px] lg:text-[18px] mb-8">
          At our core, AI is an integral part of everything we do. We transform innovative ideas into real-world AI solutions, focusing on operational excellence and client service. AI guides our approach to innovation and shapes our daily operations.
        </p>
        
        <h3 className="text-[#F16E00] text-[18px] md:text-[24px] lg:text-[30px] font-bold mb-4">
          Why You'll Love Working Here
        </h3>
        
        <p className="text-[#D8D8D8] text-[14px] md:text-[16px] lg:text-[18px]">
          We create an environment where AI handles routine tasks, allowing you to focus on extraordinary challenges. Our AI-driven workspace enhances productivity and innovation, giving you the freedom to develop impactful solutions and advance your career in technology.
        </p>
      </div>
    </div>
  );
};

export default CareersIntro;