import React from 'react';
import { FaUser } from 'react-icons/fa'; // Importing placeholder icon

const ArticleHeader = ({ title, authors, date, readTime }) => {
  const formatDate = (dateString) => {
    const dateObj = new Date(dateString);
    const day = dateObj.getDate();
    const month = dateObj.toLocaleString('default', { month: 'long' });
    const year = dateObj.getFullYear();
    const suffix = ['th', 'st', 'nd', 'rd'][day % 10 > 3 ? 0 : (day % 100 - 20) % 10 || 0];
    return `${month} ${day}, ${year}`;
  };

  return (
    <div className="flex flex-col mt-6 px-24 py-16 w-full max-md:px-5 max-md:max-w-full">
      <div className="flex flex-col w-full max-md:max-w-full">
        <h1 className="mt-2.5 text-5xl font-bold text-orange-500 max-md:max-w-full max-md:text-4xl">
          {title}
        </h1>
      </div>
      <div className="flex justify-between mt-24 w-full text-white max-md:mt-10 max-md:max-w-full">
        <div className="flex flex-1 shrink justify-between items-center basis-0 min-w-[240px] size-full max-md:max-w-full">
          <div className="flex flex-col justify-center self-stretch my-auto min-w-[240px] w-[747px]">
            <div className="flex flex-wrap gap-5 items-start w-full max-w-[747px] max-md:max-w-full">
              {authors.map((author) => (
                <div key={author.id} className="flex gap-3 items-center">
                  {author.image ? (
                    <img loading="lazy" src={author.image} alt={`${author.name}'s profile`} className="object-contain shrink-0 self-stretch my-auto aspect-square rounded-[40px] w-[45px]" />
                  ) : (
                    <FaUser className="shrink-0 self-stretch my-auto aspect-square rounded-[40px] w-[45px] text-gray-500" /> // Placeholder icon
                  )}
                  <div className="flex flex-col self-stretch my-auto w-[158px]">
                    <div className="text-xl font-bold leading-none whitespace-nowrap">
                      {author.name}
                    </div>
                    <div className="mt-1.5 text-base leading-none whitespace-nowrap">
                      {author.designation}
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="mt-8 text-sm font-light leading-none">
              {formatDate(date)} • {readTime} mins read
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArticleHeader;
