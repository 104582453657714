import * as React from "react";

function AutoPods() {
  return (
    <main className="flex relative flex-col justify-center items-end px-24 py-52 min-h-[650px] max-md:px-5 max-md:py-24">
      <img 
        loading="lazy" 
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/1cda2930afc1260a2190de436a6e63c4436dc3e900ec087ac67c40dcdca0f114?placeholderIfAbsent=true&apiKey=2a0ad5d14c014777b1f4b2f82bd16488" 
        alt=""
        className="object-cover absolute inset-0 size-full blur-sm lg:blur-0" 
      />
      <section className="flex relative flex-col items-end max-w-full w-[527px] px-6">
        <h1 className="self-stretch text-4xl font-semibold text-stone-700 max-md:max-w-full">
          <span className="font-bold text-white">Auto</span>
          <span className="font-bold text-primary">PODS</span>
        </h1>
        <h2 className="mt-6 text-xl font-medium text-primary max-md:max-w-full">
          Pods empowered with AI tools delivering exceptional performance
        </h2>
        <p className="mt-6 text-lg text-zinc-300 max-md:max-w-full">
          Autonomous pods are cross-functional teams consisting of full stack engineers, engineering & product managers, working alongside GenAI agents. This approach ensures that we deliver robust, end-to-end products and services tailored to your specific needs.
        </p>
      </section>
    </main>
  );
}

export default AutoPods;
