import React from 'react';
import LatestUpdatesResponsive from './LatestUpdatesResponsive';
import ShowcaseResponsive from './ShowcaseResponsive';

function MainComponent() {
  return (
    <main className="flex flex-col px-5 md:px-[50px] lg:px-[100px]">
      <div>
        <LatestUpdatesResponsive />
      </div>
      <div>
        <ShowcaseResponsive />
      </div>
      <style jsx>{`
        builder-component {
          max-width: none !important;
        }
      `}</style>
    </main>
  );
}

export default MainComponent;
