import React from 'react';

const QuickLinks = () => {
  const links = [
    { name: 'Home', href: '/' },
    { name: 'About Us', href: '/about' },
    { name: 'Careers', href: '/careers' },
    { name: 'Contact Us', href: '/contact' },
  ];

  return (
    <div className="flex flex-col w-[94px]">
      <h3 className="flex flex-col text-lg font-medium text-orange-500 w-[94px]">
        Quick Links
        
        <div className="self-stretch mt-2 w-full h-[2px] bg-gradient-to-r from-[#F57C00] to-[#01111A] " />
      </h3>
      <nav className="flex flex-col mt-5 text-sm text-zinc-300 w-[75px]">
        {links.map((link, index) => (
          <a key={link.name} href={link.href} className={index > 0 ? 'mt-4' : ''}>
            {link.name}
          </a>
        ))}
      </nav>
    </div>
  );
};

export default QuickLinks;