import React, { useState } from "react";
import InfoModal from "../InfoModal";

const IntelligentInterfacesCard = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const modalContent = `<p>Closed-source, high performance, paid / licensed models where there aren’t any compliance and IP restrictions to send data to an LLM. Most providers also provide an enterprise agreement that guarantees proprietary data sent to these LLMs won’t be used for training or other purposes.</p>`;


  return (
    <>
      <section className="relative w-full bg-cyan-950 rounded-lg p-4 h-[89px] sm:min-h-[200px] md:min-h-[220px]" onClick={() => setIsModalOpen(true)}>
        <div className="flex flex-col h-full w-full">
          {/* Header Container */}
          <div className="flex justify-between items-start w-full">
            {/* Text Content */}
            <div className="flex flex-col md:ml-4">
              <h2 className="text-[20px] sm:text-4xl md:text-5xl tracking-tight text-[#8BC5E1] bg-clip-text -mt-2 md:mt-4 ">
                Proprietary
              </h2>
              <h2 className="text-[20px] sm:text-4xl md:text-5xl tracking-tight text-[#8BC5E1]  bg-clip-text mt-[-4px] md:mt-[10px]">
                Models
              </h2>
            </div>
          </div>
        </div>

        {/* Info Button */}
        <button
          onClick={() => setIsModalOpen(true)}
          className="absolute bottom-1 right-1 w-7 h-7 sm:w-9 sm:h-9 border border-gray-500 rounded-full flex items-center justify-center bg-transparent hover:border-gray-400 transition-colors md:bottom-3 md:right-3"
        >
          <span className="text-gray-500 text-lg hover:text-gray-400 md:text-xl">
            +
          </span>
        </button>
      </section>

      <InfoModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        content={modalContent}
        modalHeader="Proprietary Models"
        />
    </>
  );
};

export default IntelligentInterfacesCard;