const features = [
    {
      title: "Voice AI Agents (Dexter)",
      subtitle: "Customer Interaction Assistant",
      features: [
        "Handles direct queries",
        "Takes notes",
        "Creates suggestions"
      ],
      buttonText: "Watch Demo",
      imageSrc: "https://res.cloudinary.com/dnxhznphs/image/upload/v1729598337/Untitled_video_-_Made_with_Clipchamp_1_ypqiec.gif",
      section: "Chat AI",
      demoYTLink: "https://drive.google.com/file/d/1XNTAsI9TowAaox6VtCTtr_vTCsMIGoaz/view?usp=sharing", // Added YouTube demo link
      is_try: false, // Added is_try field
      // demo_link: "" // Uncomment and set the link if is_try is true
      is_watch : true
    },
    {
      title: "Pitch Perfect",
      subtitle: "Simulated practice before pitch",
      features: [
        "For a wealth manager",
        "Persona based on real user profile and portfolio",
        "Natural voice conversation",
        "Evaluation summary emailed after the discussion"
      ],
      buttonText: "Watch Demo",
      imageSrc: "https://cdn.dribbble.com/users/32512/screenshots/3477540/media/656d5f985c4786c22a8b39a510fafc65.gif",
      section: "Chat AI",
      demoYTLink: "https://youtu.be/M_Dz9b-mDUA", // Added YouTube demo link
      is_try: false // Added is_try field
      // demo_link: "" // Uncomment and set the link if is_try is true
    },
    {
      title: "SimulTrain",
      subtitle: "Voice AI Agents training simulations",
      features: [
        "Create a custom persona and scene",
        "Trainee interacts as if on a customer call",
        "Objective evaluation of the interaction"
      ],
      buttonText: "Watch Demo",
      imageSrc: "https://res.cloudinary.com/dnxhznphs/image/upload/v1729598340/Untitled_video_-_Made_with_Clipchamp_2_phgmko.gif",
      section: "Chat AI",
      demoYTLink: "https://drive.google.com/file/d/1dhLUGjM2Urw8hIWoP37Ctj7CNUmJLxAQ/view?usp=sharing", // Added YouTube demo link
      is_try: false, // Added is_try field
      // demo_link: "" // Uncomment and set the link if is_try is true
      is_watch : true
    },
    {
      title: "Recruit41",
      subtitle: "Smart Recruiting: Harnessing AI for Talent Selection",
      features: [
        "Realistic Interview Practice",
        "Skill Evaluation Across Roles",
        "Company-Specific Role Practice",
        "Career Advancement Evaluation"
      ],
      buttonText: "Try Demo",
      imageSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/7b211a938a27fca5720a1657465154f6d216f9fd4e472c29583c064ac0e21d4d?placeholderIfAbsent=true&apiKey=2a0ad5d14c014777b1f4b2f82bd16488",
      section: "Chat AI",
      is_try: true, // Added is_try field
      demo_link: "https://recruit41.com/" // Uncomment and set the link if is_try is true
    },
    {
      title: "Autoplay",
      subtitle: "Playbook & Workflow Automation",
      features: [
        "Handles direct Q&A Queries",
        "Creates mini apps for action and checklists",
        "Future-Create and execute multi user workflows"
      ],
      buttonText: "Watch Demo",
      imageSrc: "https://res.cloudinary.com/dnxhznphs/image/upload/v1729599680/video-capture-23.92seg-535_ht0mgp.png", // Mock image URL
      section: "Data Dialogue",
      demoYTLink: "https://youtu.be/M_Dz9b-mDUA", // Added YouTube demo link
      is_try: false // Added is_try field
      // demo_link: "" // Uncomment and set the link if is_try is true
    },
    {
        title: "Text Analytics",
        subtitle: "Natural language analytics",
        features: [
          "Converts natural language user queries into structured data.",
          "Monitoring systems for access control and guardrails.",
          "Agentic workflows specialised for answering queries.",
          "Data Visualisation as charts and graphs"
        ],
        buttonText: "Explore Demo",
        imageSrc: "https://res.cloudinary.com/dnxhznphs/image/upload/v1729598337/Untitled_video_-_Made_with_Clipchamp_3_cztyu2.gif", // Mock image URL
        section: "Data Dialogue",
        demoYTLink: "https://drive.google.com/file/d/1nRQ54x06r-4GX7w5WzltwS1o_ebKEzMe/view?usp=sharing", // Added YouTube demo link
        is_try: false, // Added is_try field
        // demo_link: "" // Uncomment and set the link if is_try is true
        is_watch : true
      },
    {
      title: "Dev41",
      subtitle: "Development Platform",
      features: [
        "Comprehensive documentation",
        "Automated Document Generation",
        "User Story Transformation",
        "Streamlined Management"
      ],
      buttonText: "Try Demo",
      imageSrc: "https://res.cloudinary.com/dnxhznphs/image/upload/v1729598336/Untitled_video_-_Made_with_Clipchamp_z7icyn.gif", // Mock image URL
      section: "Dev41",
      demoYTLink: "https://youtu.be/M_Dz9b-mDUA", // Added YouTube demo link
      is_try: true, // Added is_try field
      demo_link: "https://dev41.think41.com/" // Added demo_link since is_try is true
    }
  ];

export default features;
