// client/src/components/LoadingDots.jsx
import React from 'react';

const LoadingDots = () => {
  return (
    <div className="flex justify-center items-center space-x-2">
      <div className="w-4 h-4 bg-orange-500 rounded-full animate-bounce"></div>
      <div className="w-4 h-4 bg-orange-500 rounded-full animate-bounce"></div>
      <div className="w-4 h-4 bg-orange-500 rounded-full animate-bounce"></div>
    </div>
  );
};

export default LoadingDots;

