import React from 'react';

const ContactInfo = () => {
  return (
    <div className="flex flex-col w-[204px]">
      <h3 className="flex flex-col w-full text-lg font-medium text-orange-500">
        <span>Contact Us</span>
        {/* Gradient line adjusted to span the width of the text */}
        <div className="w-full mt-2 h-[2px] bg-gradient-to-r  from-[#F57C00] to-[#01111A]" />
      </h3>
      <div className="flex flex-col items-start mt-5 text-sm leading-6 text-zinc-300">
        <div className="flex gap-4 items-start self-stretch leading-5">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/9d196419b2b0427f99f4a8814e99f56a/282dca6ff660379d5d3e3f1e690c0642939e30c0fc25cf1c95889004a3f449ba?apiKey=9d196419b2b0427f99f4a8814e99f56a&"
            className="object-contain shrink-0 w-5 aspect-square"
            alt=""
          />
          <address className="not-italic">
            2nd Floor, Obeya Gusto, <br />
            367, 5th Main Rd,Sector 6, <br />
            HSR Layout, Bengaluru, <br />
            Karnataka 560102
          </address>
        </div>
        <div className="flex gap-4 items-start mt-4 whitespace-nowrap">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/9d196419b2b0427f99f4a8814e99f56a/147ee9bff512a551a1fa3136f139adfea84e606024da0221693211bbc4215090?apiKey=9d196419b2b0427f99f4a8814e99f56a&"
            className="object-contain shrink-0 w-5 aspect-square"
            alt=""
          />
          <a href="mailto:contact@think41.com">contact@think41.com</a>
        </div>
        <div className="flex gap-4 items-start mt-4">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/9d196419b2b0427f99f4a8814e99f56a/b84aed3a87353e5d3a3592bfdbc38e9a196f2c7cdb400cf7b8af1beab91dc563?apiKey=9d196419b2b0427f99f4a8814e99f56a&"
            className="object-contain shrink-0 w-5 aspect-square"
            alt=""
          />
          <a href="tel:+919827368363">+91 9827368363</a>
        </div>
      </div>
    </div>
  );
};

export default ContactInfo;
