import React from 'react';
import GenAIChallenge from '../components/BlogListing/BlogListingHero';
import Header from '../components/Header';
import MainComponent from '../components/BlogListing/BlogSection';
import PressReleases from '../components/BlogListing/PressReleases';
import NewsletterSubscription from '../components/BlogListing/NewsLetter';


const BlogList = () => {
  return (
    <div className="bloglist bg-[#01111A]">
      <main>
        {/* GenAIChallenge (Hero Section) */}
        <section>
          <GenAIChallenge />
        </section>
        {/* Showcase Section */}
        <div className="bg-[#01111A]">
          <section className="md:py-6 lg:py-8">
            <MainComponent /> 
          </section>
          <section className="md:py-12 lg:py-16">
            <NewsletterSubscription />
          </section>
        </div>
      </main>
    </div>
  );
};

export default BlogList;
