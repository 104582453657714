import React from 'react';

const MapComponent = () => {
  return (
    <div className="flex flex-col justify-center self-stretch min-w-[240px] w-[279px]">
      <div className="flex gap-2 items-center px-2 w-full text-lg font-medium text-orange-500">
        <div className="flex flex-col self-stretch my-auto w-[120px]">
        <h3 className="text-lg font-medium text-orange-500 pl-2">
                    Find us Here
                  </h3>
          <div className="mt-1.5 w-full min-h-0" />
          <div className="self-stretch mt-2 w-full h-[2px] bg-gradient-to-r from-[#F57C00] to-[#01111A] " />
        </div>
      </div>
      <div className="flex flex-col flex-1 px-2 mt-5 w-full">
        <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.875319353839!2d77.6299513746589!3d12.91573411610153!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae15e4ab29f6a9%3A0x78164c186207e91e!2sThink41!5e0!3m2!1sen!2sin!4v1729751215090!5m2!1sen!2sin"
                      width="180"
                      height="160"
                      style={{ border: 0 }}
                      allowFullScreen=""
                      loading="lazy"
                      title="Google Map showing Think41 location"
                    ></iframe>
      </div>
    </div>
  );
};

export default MapComponent;