import React from 'react';
import MobileFeatureCard from './MobileFeatureCard';

const MobileFeatureSection = ({ title, subtitle, features, buttonText, imageSrc, demoYTLink, demo_link, is_try, is_watch }) => {
  return (
    <div className="mt-20 w-[300px] max-xl:mt-10 flex justify-center mx-auto">
      <div className="flex gap-5 max-xl:flex-col justify-center items-start mx-28 max-xl:mx-5 ">
        {/* Feature Card */}
        <div className="flex flex-col w-full mt-10 relative mx-28 ml-32 max-xl:ml-0 max-xl:order-2">
          <MobileFeatureCard
            title={title}
            subtitle={subtitle}
            features={features}
            buttonText={buttonText}
            demoYTLink={demoYTLink}
            demo_link={demo_link}
            is_try={is_try}
            is_watch={is_watch}
          />
        </div>
        
        {/* Image */}
        <div className="flex flex-col max-xl:w-full relative ml-5 max-xl:ml-0 max-xl:order-1 z-50 md:w-[700px] lg:w-[700px] ">
          <div className="w-[300px] h-[430px] md:w-[700px] md:h-[800px] md:-mb-48 overflow-visible">
            <img
              loading="lazy"
              src={imageSrc}
              alt={title}
              className="object-fit w-full h-[50%] rounded-xl"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileFeatureSection;