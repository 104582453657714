import React from 'react';
import SubscriptionForm from './SubscriptionForm';

function NewsletterSubscription() {
  return (
    <section className="relative flex flex-col items-center justify-center h-[600px] w-full mb-0">
      {/* Blurred Background Image */}
      <div
        className="absolute inset-0 bg-cover bg-center filter blur-sm"
        style={{
          backgroundImage: "url('/assets/NewsLetter.png')",
        }}
      ></div>

      {/* Content */}
      <div className="relative z-10 text-center text-white px-4">
        {/* Section Title */}
        <h2 className="text-3xl font-semibold text-orange-500 max-md:text-2xl">
          Stay Updated on Our Latest Releases
        </h2>

        {/* Section Subtitle */}
        <p className="mt-4 text-base leading-6 text-zinc-300 max-md:text-sm">
          Be in to know what's happening here
        </p>

        {/* Subscription Form */}
        <SubscriptionForm />
      </div>
    </section>
  );
}

export default NewsletterSubscription;
