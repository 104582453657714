import React from "react";

function GenAIChallenge() {
  return (
    <article className="relative min-h-[600px] rounded-none">
      {/* Background image */}
      <img
        loading="lazy"
        src="https://res.cloudinary.com/dnxhznphs/image/upload/v1729755883/blog_images/vgyhuo2jsfwmccnddqpq.png"
        alt=""
        className="absolute inset-0 w-full h-full object-cover"
      />

      {/* Gradient overlay */}
      <div className="absolute inset-0 bg-gradient-to-b from-[rgba(10,33,56,0)] to-[#01111A]" />

      {/* Content section positioned at the bottom */}
      <section className="absolute bottom-0 left-0 w-full px-6 md:px-24 pb-8">
        <div className="relative flex flex-col max-w-full p-4 bg-transparent backdrop-blur-sm">
          <h1 className="text-xl md:text-3xl font-bold text-orange-500">
            GenAI: The Challenge of Scaling with Data Security & Privacy
          </h1>
          <p className="mt-2.5 text-sm md:text-base leading-none text-white">
            AI Strategy · Cost Optimization · Quantization Techniques
          </p>
        </div>
      </section>
    </article>
  );
}

export default GenAIChallenge;
