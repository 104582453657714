import React, { useState, useEffect, useRef } from "react";
import AuthorCard from "./AuthorCard"; // Import AuthorCard
import RelatedArticleCard from "./RelatedArticleCard"; // Import RelatedArticleCard
import { FaTwitter, FaLinkedin, FaFacebook } from "react-icons/fa"; // Import icons from react-icons/fa
import { FaXTwitter } from "react-icons/fa6";


const ArticleContent = ({ id }) => {
  const [blogPost, setBlogPost] = useState(null); // State to hold the blog post data
  const [loading, setLoading] = useState(true); // State to manage loading state
  const [activeSection, setActiveSection] = useState(null); // State to manage active section
  const [authors, setAuthors] = useState([]); // State to hold authors data
  const [relatedArticles, setRelatedArticles] = useState([]);
  const [activeId, setActiveId] = useState('');
  const observerRef = useRef({});
  const [isTableVisible, setIsTableVisible] = useState(false);
  const articleRef = useRef(null);

  useEffect(() => {
    const fetchBlogPost = async () => {
      try {
        const response = await fetch(`/api/blog_posts/${id}`); // Use BASE_URL
        const data = await response.json();
        // Update the blogPost state to include author_name and create TOC from sections
        setBlogPost({
          ...data,
          tableOfContents: data.sections.map((section, index) => ({
            id: `heading-${index}`, // Changed ID format
            title: section.heading,
          })),
          activeTOCItem: 1, // Initialize active TOC item
        });
      } catch (error) {
        console.error("Error fetching blog post:", error);
      } finally {
        setLoading(false); // Set loading to false after fetching
      }
    };

    fetchBlogPost();
  }, [id]);

  useEffect(() => {
    if (activeSection) {
      const element = document.getElementById(activeSection);
      if (element) {
        const headerOffset = 120;
        const elementPosition = element.getBoundingClientRect().top;
        const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth",
        });
      }
    }
  }, [activeSection]);

  useEffect(() => {
    if (!blogPost) return;
    
    const headings = document.querySelectorAll('h2[id^="heading-"]');
    
    const callback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setActiveId(entry.target.id);
        }
      });
    };

    const options = {
      rootMargin: '-20% 0px -35% 0px',
      threshold: 0
    };

    observerRef.current = new IntersectionObserver(callback, options);

    headings.forEach((element) => {
      observerRef.current.observe(element);
    });

    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, [blogPost]);

  const handleSectionClick = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      const headerOffset = 120;
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    const fetchAuthors = async () => {
      try {
        const response = await fetch(`/api/blog_posts/${id}/authors`); // Use BASE_URL
        const data = await response.json();

        // Handle case when there is only one author
        const formattedAuthors = Array.isArray(data)
          ? data.map((author) => ({
              id: author.id, // Assuming the API provides an id
              name: author.name,
              image: author.image,
              designation: author.designation,
              socialLinks: [
                {
                  name: "Instagram",
                  url: author.social_links.instagram,
                  icon: "instagram-icon.png",
                },
                {
                  name: "LinkedIn",
                  url: author.social_links.linkedin,
                  icon: "linkedin-icon.png",
                },
                {
                  name: "Twitter",
                  url: author.social_links.twitter,
                  icon: "twitter-icon.png",
                },
              ],
              bio: author.about, // Add bio from the API
            }))
          : [
              {
                id: data.id, // Assuming the API provides an id
                name: data.name,
                image: data.image,
                designation: data.designation,
                socialLinks: [
                  {
                    name: "Instagram",
                    url: data.social_links.instagram,
                    icon: "instagram-icon.png",
                  },
                  {
                    name: "LinkedIn",
                    url: data.social_links.linkedin,
                    icon: "linkedin-icon.png",
                  },
                  {
                    name: "Twitter",
                    url: data.social_links.twitter,
                    icon: "twitter-icon.png",
                  },
                ],
                bio: data.about, // Add bio from the API
              },
            ]; // If it's a single object, format it accordingly

        setAuthors(formattedAuthors); // Update authors state with fetched data
      } catch (error) {
        console.error("Error fetching authors:", error);
      }
    };

    fetchAuthors(); // Call the function to fetch authors
  }, []); // Fetch authors on component mount

  useEffect(() => {
    // Mocked related articles data
    const mockRelatedArticles = [
      {
        image: "https://via.placeholder.com/150",
        title: "How to Optimize Your Blog for SEO",
        tags: ["SEO", "Blogging"],
      },
      {
        image: "https://via.placeholder.com/150",
        title: "Understanding Content Marketing",
        tags: ["Marketing", "Content"],
      },
      {
        image: "https://via.placeholder.com/150",
        title: "The Future of Web Development",
        tags: ["Web Development", "Tech"],
      },
    ];

    setRelatedArticles(mockRelatedArticles); // Set the mocked related articles data
  }, []);

  useEffect(() => {
    if (!blogPost) return;

    const handleScroll = () => {
      if (!articleRef.current) return;

      const articleStart = articleRef.current.offsetTop + 100;
      const articleEnd = articleStart + articleRef.current.offsetHeight - 350; // Added 500px offset
      const scrollPosition = window.scrollY + 200; // Offset for header

      setIsTableVisible(scrollPosition >= articleStart && scrollPosition <= articleEnd);
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Initial check

    return () => window.removeEventListener('scroll', handleScroll);
  }, [blogPost]);

  if (loading) return <div>Loading...</div>; // Show loading state

  if (!blogPost) return <div>No blog post found.</div>; // Handle case where no post is found

  return (
    <div className="flex flex-col items-center justify-center pb-10 mt-36 w-full bg-orange-50 rounded-lg max-w-[1280px] mx-auto px-24">
      <div className="w-full max-w-[1078px] -mt-36 z-10 self-center rounded-lg overflow-hidden shadow-[4px_4px_24px_rgba(0,0,0,0.25)] max-md:max-w-full relative h-0 pb-[56.25%]">
        <img
          loading="lazy"
          src={blogPost.image1}
          alt="Article header"
          className="absolute top-0 left-0 w-full h-full object-cover"
        />
      </div>
      <div
        dangerouslySetInnerHTML={{ __html: blogPost.intro }}
        className="text-black mt-6"
      />{" "}
      {/* Display blog intro */}
      <div className="flex flex-wrap gap-10 justify-between items-start mt-5 w-full max-md:max-w-full">
        <aside className={`
          transition-all 
          ${isTableVisible ? 'opacity-100 translate-y-0' : 'opacity-0 -translate-y-4'}
          fixed left-[max(2rem,calc((100vw-1280px)/2+2rem))] top-36 w-[236px]
          ${!isTableVisible && 'pointer-events-none'}
        `}>
          <div className="flex flex-col max-w-full w-[250px]  backdrop-blur-sm rounded-lg shadow-lg p-4">
            <h2 className="text-2xl font-semibold text-black">
              In this article
            </h2>
            <nav className="flex flex-col mt-5 w-full text-base text-black max-h-[60vh] overflow-y-auto">
              {blogPost?.tableOfContents.map((item, index) => (
                <a
                  key={index}
                  href={`#${item.id}`}
                  className={`flex-1 shrink gap-2.5 self-stretch py-2.5 pl-5 
                    ${index === 0 ? "" : "mt-2.5"} 
                    w-full leading-6 transition-all duration-200 
                    ${item.id === activeId
                      ? "border-orange-500 border-l-[3px] text-black font-bold bg-orange-50"
                      : "text-gray-400 hover:text-gray-600 hover:bg-orange-50/50"
                    }`}
                  onClick={(e) => {
                    e.preventDefault();
                    handleSectionClick(item.id);
                  }}
                >
                  {item.title}
                </a>
              ))}
            </nav>
          </div>
        </aside>

        <article
          ref={articleRef}
          className="flex flex-col justify-center items-center pt-20 pb-5 min-w-[240px] w-[786px] max-md:max-w-full p-5 text-black ml-[236px]"
        >
          {blogPost.sections.map((section, index) => (
            <div key={index}>
              <h2 
                id={`heading-${index}`} // Add ID to the heading directly
                className="pt-4 text-2xl font-semibold text-black"
              >
                {section.heading}
              </h2>
              <div
                dangerouslySetInnerHTML={{ __html: section.content }}
                className="text-black"
              />
              <br />
              {section.image && (
                <div className="w-full h-0 pb-[56.25%] relative overflow-hidden rounded-lg shadow-[0px_8px_12px_rgba(0,0,0,0.25)]">
                  <img
                    src={section.image}
                    alt={`Section ${index + 1}`}
                    className="absolute top-0 left-0 w-full h-full object-cover"
                  />
                </div>
              )}{" "}
              {/* Display section image if available, cropped to fill container */}
            </div>
          ))}
          <div className="flex flex-wrap justify-between items-center mt-10 w-full">
            <p className="text-lg font-medium text-black text-left">
              See what you like? Share with a friend
            </p>
            <div className="flex gap-4">
              <a
                href={`https://twitter.com/share?url=${encodeURIComponent(
                  window.location.href
                )}&text=Check out this article I read on Think41 blogs`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaXTwitter className="object-contain w-10 h-10 text-primary" />
              </a>
              <a
                href={`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
                  window.location.href
                )}&title=Check out this article I read on Think41 blogs`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaLinkedin className="object-contain w-10 h-10 text-primary" />
              </a>
              <a
                href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                  window.location.href
                )}&quote=Check out this article I read on Think41 blogs`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaFacebook className="object-contain w-10 h-10 text-primary" />
              </a>
            </div>
          </div>
        </article>
      </div>
      {/* Add AuthorCards */}
      <div className="flex flex-col gap-5 justify-center mt-5 w-full max-w-[1078px] px-5">
        <h2 className="text-2xl font-semibold text-black">Authors</h2>
        <div className="flex flex-wrap gap-5 mt-2">
          {authors.length === 1 ? ( // Check if there is only one author
            <AuthorCard
              key={0} // Use a fixed key since there's only one author
              name={authors[0].name}
              title={authors[0].designation}
              bio={authors[0].bio}
              image={authors[0].image}
              socialLinks={authors[0].socialLinks}
            />
          ) : (
            authors.map(
              (
                author,
                index // Render AuthorCard for each author
              ) => (
                <AuthorCard
                  key={index}
                  name={author.name}
                  title={author.designation}
                  bio={author.bio}
                  image={author.image}
                  socialLinks={author.socialLinks}
                />
              )
            )
          )}
        </div>
        {/* <hr className="my-5 border-t-1 border-black w-full" />
        <h2 className="text-2xl font-semibold text-black">Related Articles</h2>
        <div className="flex flex-wrap gap-5 justify-center mt-2">
          {relatedArticles.map((article, index) => (
            <RelatedArticleCard
              key={index}
              image={article.image}
              tags={article.tags}
              title={article.title}
            />
          ))}
        </div> */}
      </div>
    </div>
  );
};

export default ArticleContent;
