export const foundersData = [
    {
      id: 1,
      name: "Anshuman Singh",
      image: "https://cdn.builder.io/api/v1/image/assets/9d196419b2b0427f99f4a8814e99f56a/931999eeb23d5da9e0df784abed6cd9043fab687e05e5414c4d0140e47f8c2ec?apiKey=9d196419b2b0427f99f4a8814e99f56a&",
      imageType: "round",
      info:"Anshu loves tech and scalability, having built hundreds of products and driven significant growth  in the past 13 years. He approaches business problems like chess challenges, drawing from his expertise as a chess master. Outside of work, Anshu enjoys playing carrom and tennis, balancing his strategic mind with fun and competition.",
      link:"https://www.linkedin.com/in/anshum4n/"
    },
    {
      id: 2,
      name: "Harshit Singhal",
      image: "https://cdn.builder.io/api/v1/image/assets/9d196419b2b0427f99f4a8814e99f56a/1c34748567a44f886cfcd49e2a27b11ef458bbf2dfc943951584ae90bc9782a5?apiKey=9d196419b2b0427f99f4a8814e99f56a&",
      imageType: "round",
      info:"Harshit loves business and has supercharged the growth trajectory for HashedIn, significantly expanding the mid-market segment for Deloitte. Previously, he co-founded Auctus Advisors, a strategy consulting firm acquired by YCP, and worked as a management consultant with SDG. Passionate about history, Harshit also enjoys diving deep into the past to draw lessons for the future.",
      link:"https://www.linkedin.com/in/harshitsinghal01/"
    },
    {
      id: 3,
      name: "Himanshu Varshney",
      image: "https://cdn.builder.io/api/v1/image/assets/9d196419b2b0427f99f4a8814e99f56a/7adb96b903c01582f9b4dd90f055e9e784e81ada0c32901b9405a9354d803514?apiKey=9d196419b2b0427f99f4a8814e99f56a&",
      imageType: "round",
      info:"Himanshu is a people person. Along with leading HashedIn, he has championed the creation of an open culture at Deloitte Engineering and was recognized as one of India's Top 25 Trusted Leaders by GPTW. Formerly with Trilogy, Himanshu is also an All-Star sportsman from IIT Guwahati and HashedIn. He remains active in various sports, continually fostering teamwork and spirit.",
      link:"https://www.linkedin.com/in/himanshuhv/"
    },
    {
      id: 4,
      name: "Sripathi Krishnan",
      image: "https://cdn.builder.io/api/v1/image/assets/9d196419b2b0427f99f4a8814e99f56a/3329340cc44451afa86147fb3d70a72bb073ef34619147749ebad2b844b0e181?apiKey=9d196419b2b0427f99f4a8814e99f56a&",
      imageType: "round",
      info:"Sri is a tech guy who served as the CTO and MD of Modern Software Engineering at Deloitte US, where he was responsible for technology strategy and GenAI innovation. He previously founded RDBTools, acquired by RedisLabs. Known as a champ for GenAI CoE, Sri is also a foodie who loves hosting and attending parties, blending his passion for technology with a zest for life.",
      link:"https://www.linkedin.com/in/sripathikrishnan/"
    }
  ];