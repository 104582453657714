import React from 'react';
import { FaTwitter, FaLinkedin, FaInstagram, FaTimes, FaUser } from 'react-icons/fa';

const AuthorInfoModal = ({ isOpen, onClose, author }) => {
  if (!isOpen || !author) return null;

  const formatSocialLink = (link) => {
    if (!link.startsWith('http')) {
      return `http://${link}`;
    }
    return link;
  };

  return (
    <div className="fixed inset-0 bg-black backdrop-blur-sm bg-opacity-20 flex justify-center items-center z-50">
      <div className="bg-gray-950 p-8 rounded-lg shadow-lg w-11/12 max-w-md">
        <div className="flex justify-between items-center border-b border-gray-700 pb-4">
          <h2 className="text-2xl font-bold text-orange-500">Author Details</h2>
          <button onClick={onClose} className="text-white hover:text-orange-500 transition">
            <FaTimes size={24} />
          </button>
        </div>
        <div className="flex flex-col items-center mt-4">
          {author.image ? (
            <img src={author.image} alt="Author" className="w-28 h-28 rounded-full border-4 border-orange-500" />
          ) : (
            <FaUser className="w-28 h-28 text-gray-500 rounded-full" />
          )}
          <h3 className="text-xl font-semibold mt-2 text-white">{author.name}</h3>
          <p className="text-orange-300 text-sm">{author.designation}</p>
          <p className="text-gray-300 text-center mt-2">{author.about}</p>
          {/* Display social links */}
          <div className="mt-4 flex space-x-4">
            {author.social_links.twitter && (
              <a href={formatSocialLink(author.social_links.twitter)} target="_blank" rel="noopener noreferrer" className="text-orange-500 hover:text-orange-300 transition">
                <FaTwitter size={20} />
              </a>
            )}
            {author.social_links.linkedin && (
              <a href={formatSocialLink(author.social_links.linkedin)} target="_blank" rel="noopener noreferrer" className="text-orange-500 hover:text-orange-300 transition">
                <FaLinkedin size={20} />
              </a>
            )}
            {author.social_links.instagram && (
              <a href={formatSocialLink(author.social_links.instagram)} target="_blank" rel="noopener noreferrer" className="text-orange-500 hover:text-orange-300 transition">
                <FaInstagram size={20} />
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthorInfoModal;