import React, { useState } from "react";
import InfoModal from "../InfoModal";

const IntelligentInterfacesCard = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const modalContent = `<p>Agentic Workflows enable multiple agents to examine a problem from their unique perspectives, providing feedback and next steps. By combining insights, they can suggest decisions, summaries, and present these to a human in the loop or take actions based on the criticality of the decision.</p>
  </br>
  <p>Additionally, leveraging function calling allows for the dynamic invocation of specific operations, enabling AI models to execute tasks and retrieve real-time data seamlessly. This enhances interactivity, precision, and improves maintainability.</p>`;

  return (
    <>
      <section className="relative w-full bg-[#344f5f] rounded-lg p-4 h-[89px] sm:min-h-[200px] md:min-h-[220px]" onClick={() => setIsModalOpen(true)}>
        <div className="flex flex-col h-full w-full">
          {/* Header Container */}
          <div className="flex justify-between items-start w-full">
            {/* Text Content */}
            <div className="flex flex-col md:ml-4">
              <h2 className="text-[20px] sm:text-4xl md:text-5xl tracking-tight text-white bg-clip-text text-transparent -mt-2 md:mt-4 ">
                Agentic
              </h2>
              <h2 className="text-[20px] sm:text-4xl md:text-5xl tracking-tight text-white  bg-clip-text text-transparent mt-[-4px] md:mt-[10px]">
                Workflows
              </h2>
            </div>
          </div>
        </div>

        {/* Info Button */}
        <button
          onClick={() => setIsModalOpen(true)}
          className="absolute bottom-1 right-1 w-7 h-7 sm:w-9 sm:h-9 border border-gray-500 rounded-full flex items-center justify-center bg-transparent hover:border-gray-400 transition-colors md:bottom-3 md:right-3"
        >
          <span className="text-gray-500 text-lg hover:text-gray-400 md:text-xl">
            +
          </span>
        </button>
      </section>

      <InfoModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        content={modalContent}
        modalHeader="Agentic Workflows - Empowering Users"
      />
    </>
  );
};

export default IntelligentInterfacesCard;