import React, { useEffect, useState } from 'react';
import UpdateCard from './UpdateCard';

function LatestUpdates() {
  const [updates, setUpdates] = useState([]);

  useEffect(() => {
    async function fetchLatestUpdates() {
      try {
        const response = await fetch('/api/latest_blog_posts/');
        if (!response.ok) throw new Error('Failed to fetch blog posts');
        
        const data = await response.json();
        setUpdates(data);
      } catch (error) {
        console.error('Error fetching latest blog posts:', error);
      }
    }

    fetchLatestUpdates();
  }, []);

  return (
    <section className="flex flex-col w-full">
      <header className="flex flex-wrap gap-1 items-center w-full text-2xl font-bold text-orange-500">
        <h2>Latest Updates</h2>
        <img loading="lazy" src="https://res.cloudinary.com/dnxhznphs/image/upload/v1729756007/blog_images/ptzzkha2twby866kyij2.png" alt="" className="w-6" />
      </header>
      <div className="mt-4 w-full">
        <div className="flex flex-wrap gap-5"> {/* Keep left alignment */}
          {updates.map((update) => (
            <UpdateCard
              key={update.id}
              title={update.heading}
              imageSrc={update.image1}
              introduction={update.preview_text}
              blogPostId={update.id}
            />
          ))}
        </div>
      </div>
    </section>
  );
}

export default LatestUpdates;
