import React from 'react';

function NavItem({ label, href, onClick }) {
  return (
    <a 
      href={href} 
      className="gap-2.5 p-2.5 my-auto whitespace-nowrap transition duration-300 ease-in-out border-b-2 border-transparent hover:border-primary" // Added line animation on hover
      style={{ display: 'inline-block' }} 
      onClick={onClick} // Call onClick when the item is clicked
    >
      {label}
    </a>
  );
}

export default NavItem;
