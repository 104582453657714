import React from 'react';
import Header from '../components/Header';
import CareersHero from '../components/Careers/CareersHero';
import CareersIntro from '../components/Careers/CareersIntro';
import LifeAtThink41 from '../components/Careers/LifeAtThink41';
import Facilities from '../components/Careers/Facilities';
import Boot41 from '../components/Careers/Boot41';
import Carousel from '../components/Careers/CareersCarousel';
import Testimonials from '../components/Careers/Testimonials';
import OpenPosition from '../components/Careers/OpenPositions';

const Careers = () => {
    return (
        <div className="bg-[#01111A]">
            {/* Main Content */}
            <main> {/* Horizontal padding for responsiveness */}
                <CareersHero />
                <div className="mt-[100px] sm:mt-[80px]"> {/* Responsive margin-top */}
                    <CareersIntro />
                </div>
                <div className="mt-[80px] sm:mt-[60px]"> {/* Responsive margin-top */}
                    <LifeAtThink41 />
                </div>
                <div className="mt-[80px] sm:mt-[100px]"> {/* Responsive margin-top */}
                    <Facilities />
                </div>
                <div className="mt-[10px] sm:mt-[5px]"> {/* Responsive margin-top */}
                    <Boot41 />
                </div>
                <div>
                    <Carousel />
                </div>
                <div className="mt-[80px] sm:mt-[110px] mb-40"> {/* Responsive margin-top */}
                    <Testimonials />
                </div>
                <div className="mt-[100px] sm:mt-[80px]"> {/* Responsive margin-top */}
                    <OpenPosition />
                </div>
            </main>
            
        </div>
    );
};

export default Careers;
