import React from 'react';
import ShowcaseMobile from './ShowcaseMobile';
import ShowcaseTab from './ShowcaseTab';
import Showcase from './Showcase'; // If you have a desktop version
import { useMediaQuery } from 'react-responsive';

const ShowcaseResponsive = () => {
  const isDesktop = useMediaQuery({ minWidth: 1025 });
  const isTablet = useMediaQuery({ minWidth: 769, maxWidth: 1024 });
  const isMobile = useMediaQuery({ maxWidth: 768 });

  if (isDesktop) return <Showcase />; // Assuming you have a desktop version
  if (isTablet) return <ShowcaseTab />;
  if (isMobile) return <ShowcaseMobile />;

  return null;
};

export default ShowcaseResponsive;
