import React, { useState } from "react";
import InfoModal from "../InfoModal";

const IntelligentInterfacesCard = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const modalContent =
    "<p>We look to solve for the intuitiveness of voice inputs and natural language text as well as the utility and speed of traditional input mechanisms of forms and clicks. It allows users to provide high level commands and guidance while the agents handle the rest.</p>" +
    "</br>" +
    "<p>You don’t need to play out each step manually or ask your team members. You can now say “Rewrite this paragraph”, “Follow-up on this Action”, “Summarise the feedback” and “What’s the status” to the systems, requests which earlier were given to people and teams.</p>" +
    "</br>" +
    "<p>Primary use cases include CRM, ERP and other rule-based workflows for compliance and internal portals.</p>";

  return (
    <>
      <section className="relative w-full bg-cyan-950 rounded-lg p-4 h-[89px] sm:min-h-[200px] md:min-h-[220px]" onClick={() => setIsModalOpen(true)}>
        <div className="flex flex-col h-full w-full">
          {/* Header Container */}
          <div className="flex justify-between items-start w-full">
            {/* Text Content */}
            <div className="flex flex-col md:ml-4">
              <h2 className="text-[22px] sm:text-4xl md:text-5xl  tracking-tight bg-gradient-to-r from-[#E6AA6F] to-[#EF750D] bg-clip-text text-transparent -mt-2 md:mt-4 ">
                Intelligent
              </h2>
              <h2 className="text-[22px] sm:text-4xl md:text-5xl tracking-tight bg-gradient-to-r from-[#E6AA6F] to-[#EF750D] bg-clip-text text-transparent mt-[-8px] md:mt-[10px]">
                Interfaces
              </h2>
            </div>
          </div>
        </div>

        {/* Info Button */}
        <button
          onClick={() => setIsModalOpen(true)}
          className="absolute bottom-1 right-1 w-6 h-6 sm:w-9 sm:h-9 border border-gray-500 rounded-full flex items-center justify-center bg-transparent hover:border-gray-400 transition-colors md:bottom-3 md:right-3"
        >
          <span className="text-gray-500 text-lg hover:text-gray-400 md:text-xl">
            +
          </span>
        </button>
      </section>

      <InfoModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        content={modalContent}
        modalHeader="Intelligent Interfaces - Building interfaces for people and not machines"
      />
    </>
  );
};

export default IntelligentInterfacesCard;