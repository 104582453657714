import React from 'react';

const CallToAction = () => {
  return (
    <section className="flex relative z-10 flex-col justify-center px-16 py-48 mt-24 w-full min-h-[502px] max-md:px-5 max-md:py-24 max-md:mb-2.5 max-md:max-w-full">
      <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/3b9955e2c8c97f457343024172a25c7d00d9e1f6d604dcd19c355d443c0de8e5?placeholderIfAbsent=true&apiKey=2a0ad5d14c014777b1f4b2f82bd16488" alt="" className="object-cover absolute inset-0 size-full" />
      <div className="flex relative flex-col items-center w-full max-w-[900px] mx-auto max-md:max-w-full">
        <h2 className="self-stretch text-3xl font-semibold text-center text-orange-500 max-md:max-w-full max-md:text-2xl">
          Optimise your Potential with AI
        </h2>
        <p className="mt-8 text-base text-center text-zinc-300 max-md:max-w-full max-md:text-sm">
          Explore how Think41 can revolutionize your operations.
        </p>
        <button onClick={() => window.location.href = '/contact'} className="gap-2.5 px-8 py-2.5 mt-8 text-sm font-medium text-white bg-primary hover:bg-opacity-90 rounded border-2 border-orange-500 border-solid max-md:px-5 max-md:text-xs">
          Get in Touch
        </button>
      </div>
    </section>
  );
};


export default CallToAction;