import React, { useState } from "react";
import InfoModal from "../InfoModal";

const IntelligentInterfacesCard = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const modalContent =
    "<p>The data density of voice is much higher than typing or clicking. You can arrange for a voice discussion with the system to collect much more information in a lot less time from a lot more people.</p>" +
    "</br>" +
    "<p>Assistants can be passive participants, providing a voice in the ear, or active collaborators in important meetings, helping with note-taking, delivering data, information, and insights on the fly.</p>" +
    "</br>" +
    "<p>Primary use cases include sales support, customer support, training, interviews, and active assistance.</p>";


  return (
    <>
      <section 
        className="relative w-full bg-cyan-950 rounded-lg p-4 h-[89px] sm:min-h-[200px] md:min-h-[220px]"
        onClick={() => setIsModalOpen(true)} // Make the whole card clickable
      >
        <div className="flex flex-col h-full w-full">
          {/* Header Container */}
          <div className="flex justify-between items-start w-full">
            {/* Text Content */}
            <div className="flex flex-col md:ml-4">
              <h2 className="text-[22px] sm:text-4xl md:text-5xl  tracking-tight bg-gradient-to-r from-[#E6AA6F] to-[#EF750D] bg-clip-text text-transparent -mt-2 md:mt-4 ">
                Assistants &
              </h2>
              <h2 className="text-[22px] sm:text-4xl md:text-5xl tracking-tight bg-gradient-to-r from-[#E6AA6F] to-[#EF750D] bg-clip-text text-transparent mt-[-8px] md:mt-[10px]">
                Copilots
              </h2>
            </div>
          </div>
        </div>

        {/* Info Button */}
        <button
          onClick={() => setIsModalOpen(true)}
          className="absolute bottom-1 right-1 w-7 h-7 sm:w-9 sm:h-9 border border-gray-500 rounded-full flex items-center justify-center bg-transparent hover:border-gray-400 transition-colors md:bottom-3 md:right-3"
        >
          <span className="text-gray-500 text-lg hover:text-gray-400 md:text-xl">
            +
          </span>
        </button>
      </section>

      <InfoModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        content={modalContent}
        modalHeader="Assistants & Copilots - Enhancing User Experience"
      />
    </>
  );
};

export default IntelligentInterfacesCard;