import React from 'react';
import TransformationCard from './TransformationCard';

const transformationData = [
  {
    title: "Productivity",
    description: "Harnessing GenAI's power, we revolutionise your organisation's productivity at individual, team, and enterprise levels. Our solutions streamline existing processes and software, accelerating development cycles and optimising business operations through AI-driven automation.",
    imageSrc: '/assets/stopwatch.png'
  },
  {
    title: "Possibility",
    description: "Leverage GenAI to achieve what was once unthinkable in technology and business. Through strategic investment in emerging possibilities, we empower organisations to unlock unprecedented growth and redefine industry standards.",
    imageSrc: '/assets/lightbulb.png'
  },
  {
    title: "Pioneering",
    description: "Turn today's pioneering concepts into your competitive advantages, ensuring your organisation always stays ahead of the curve. At the forefront of GenAI innovation, we'll help your organisation explore and develop use-cases that challenge conventional limits and reshape your industry.",
    imageSrc: "/assets/handchip.png"
  }
];

function GenerativeAITransformationView() {
  return (
    <section className="flex flex-col items-center px-5 py-24 max-md:px-5 bg-cover bg-center" style={{ backgroundImage: "url('/assets/backgrounds/bg_genai_section.png')" }}>
      <div className="flex flex-col items-center w-full max-w-screen-xl">
        <header className="flex flex-col justify-center items-center w-full text-4xl font-bold text-orange-500 min-h-[54px]">
          <div className="flex flex-col items-center">
            <h1 className="text-center max-md:max-w-full">
              Generative AI Transformation
            </h1>
            <div className="w-full h-[5px] mt-2" style={{
              background: "linear-gradient(90deg, rgba(255, 255, 255, 0.00) 0%, #F57C00 50%, rgba(255, 255, 255, 0.00) 100%)"
            }} />
          </div>
          <div className="flex-1 mt-1.5 w-full min-h-[5px]" />
        </header>
        <div className="flex flex-wrap max-lg:flex-col gap-5 items-center mt-16 max-w-full w-[1242px] max-xl:w-full">
          {transformationData.map((item, index) => (
            <TransformationCard
              key={index}
              title={item.title}
              description={item.description}
              imageSrc={item.imageSrc}
              className="transition-all duration-300 hover:shadow-[0_0_20px_rgba(255,255,255,0.2)] hover:-translate-y-1 hover:scale-[1.02]"
            />
          ))}
        </div>
      </div>
    </section>
  );
}

export default GenerativeAITransformationView;
