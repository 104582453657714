import React, { useState, useEffect } from 'react';
import { FaComments, FaChartLine, FaCode } from 'react-icons/fa';
import Header from '../../components/HeaderComponent';
import Hero from '../../components/Demos/HeroSection';
import FeatureSection from '../../components/Demos/FeatureSection';
import MobileFeatureSection from '../../components/Demos/Mobile/MobileFeatureSection';
import CallToAction from '../../components/Demos/CallToAction';
import features from '../../data/featuresData';

const useWindowWidth = () => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return width;
};

const DemoPage = () => {
  const [selectedSection, setSelectedSection] = useState('Chat AI');
  const width = useWindowWidth();
  const isMobile = width <= 1270;

  useEffect(() => {
    // Get section from URL parameters when component mounts
    const urlParams = new URLSearchParams(window.location.search);
    const sectionParam = urlParams.get('section');
    
    if (sectionParam) {
      setSelectedSection(sectionParam);
      // Scroll to feature section after a short delay to ensure content is loaded
      setTimeout(() => {
        document.getElementById('feature-section')?.scrollIntoView({ behavior: 'smooth' });
      }, 100);
    }
  }, []);

  // Filter features based on the selected section
  const filteredFeatures = features.filter(feature => feature.section === selectedSection);

  const handleSectionSelect = (section) => {
    setSelectedSection(section);
    // Update URL without page reload
    const newUrl = new URL(window.location);
    newUrl.searchParams.set('section', section);
    window.history.pushState({}, '', newUrl);
    // Scroll to the feature section
    document.getElementById('feature-section')?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    const savedSection = localStorage.getItem('selectedDemo');
    if (savedSection) {
      setSelectedSection(savedSection);
      localStorage.removeItem('selectedDemo');
      // Scroll to feature section after a short delay to ensure content is loaded
      setTimeout(() => {
        document.getElementById('feature-section')?.scrollIntoView({ behavior: 'smooth' });
      }, 100);
    }
  }, []);

  return (
    <div className="flex overflow-hidden flex-col bg-slate-950">
      <main className="flex flex-col items-center">
        <Hero />
        <section className="flex flex-col px-24 mt-24 w-full max-md:px-5 max-md:mt-10 max-md:max-w-full">
          {/* Section selection buttons */}
          <div className="flex gap-9 items-center max-w-full text-lg text-center text-gray-700 w-[430px] mx-auto max-md:w-full max-md:gap-4">
            <div className="flex flex-col flex-1 shrink self-stretch my-auto basis-0">
              <button onClick={() => handleSectionSelect('Chat AI')} className="flex flex-col items-center">
                <FaComments className={`self-center ${width <= 768 ? 'w-12 text-xl' : 'w-16 text-4xl'} ${selectedSection === 'Chat AI' ? 'text-orange-500' : 'text-gray-500'}`} />
                <div className={`mt-5 text-lg ${selectedSection === 'Chat AI' ? 'text-orange-500' : 'text-gray-500'} max-md:text-base`}>Chat AI</div>
              </button>
            </div>
            <div className="flex flex-col flex-1 shrink self-stretch my-auto basis-0">
              <button onClick={() => handleSectionSelect('Data Dialogue')} className="flex flex-col items-center">
                <FaChartLine className={`self-center ${width <= 768 ? 'w-12 text-xl' : 'w-16 text-4xl'} ${selectedSection === 'Data Dialogue' ? 'text-orange-500' : 'text-gray-500'}`} />
                <div className={`mt-5 text-lg ${selectedSection === 'Data Dialogue' ? 'text-orange-500' : 'text-gray-500'} max-md:text-base`}>Data Dialogue</div>
              </button>
            </div>
            <div className="flex flex-col flex-1 shrink self-stretch my-auto whitespace-nowrap basis-0">
              <button onClick={() => handleSectionSelect('Dev41')} className="flex flex-col items-center">
                <FaCode className={`self-center ${width <= 768 ? 'w-12 text-xl' : 'w-16 text-4xl'} ${selectedSection === 'Dev41' ? 'text-orange-500' : 'text-gray-500'}`} />
                <div className={`mt-5 text-lg ${selectedSection === 'Dev41' ? 'text-orange-500' : 'text-gray-500'} max-md:text-base`}>Dev41</div>
              </button>
            </div>
          </div>
          <div id="feature-section" className="flex flex-col mt-16 w-full max-w-[1240px] max-md:mt-10 max-md:max-w-full mx-auto space-y-32">
            {isMobile 
              ? filteredFeatures.map((feature, index) => (
                  <MobileFeatureSection key={index} {...feature} />
                ))
              : filteredFeatures.map((feature, index) => (
                  <FeatureSection key={index} {...feature} />
                ))
            }
          </div>
        </section>
        <CallToAction />
      </main>
    </div>
  );
};

export default DemoPage;