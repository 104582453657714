import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

function UpdateCardResponsive({ title, imageSrc, introduction, blogPostId }) {
  const [isFlipped, setIsFlipped] = useState(false);
  const navigate = useNavigate();

  const isTablet = useMediaQuery({ minWidth: 767, maxWidth: 1024 });
  const isMobile = useMediaQuery({ maxWidth: 768 });

  const handleReadMore = () => {
    navigate(`/blog/${blogPostId}`);
  };

  return (
    <article
      className={`relative flex flex-col rounded-[4px] transition-all duration-500 ease-in-out`}
      style={{
        width: isMobile ? '167px' : isTablet ? '300px' : 'auto',
        height: '200px',
        marginLeft: isMobile ? 'auto' : '0',
        marginRight: isMobile ? 'auto' : '0',
      }}
      onClick={() => setIsFlipped(!isFlipped)}
    >
      {/* Default View */}
      <div className={`flex flex-col grow ${isFlipped ? 'hidden' : 'block'}`}>
        <div
          className="relative flex flex-col rounded-[4px] aspect-[1.5]"
          style={{
            padding: isMobile ? '0.5rem' : '1rem',
          }}
        >
          <img
            src={imageSrc}
            alt={title}
            className="object-cover absolute inset-0 size-full rounded-[4px]"
          />
          <div className="absolute bottom-0 w-full px-2 py-1">
            <h4 className="text-xs font-bold text-white">{title}</h4>
          </div>
        </div>
      </div>

      {/* Flipped View */}
      <div
        className={`absolute inset-0 bg-[#08293c] flex flex-col justify-center items-center space-y-4 transition-all duration-500 ease-in-out ${
          isFlipped ? 'flex' : 'hidden'
        }`}
      >
        <h3 className="text-[#f57c00] text-xs font-bold text-center">{title}</h3>
        <div
          className="bg-[#f16e00] text-white rounded-md px-3 py-1 cursor-pointer"
          onClick={handleReadMore}
        >
          <span>Read Blog</span>
        </div>
      </div>
    </article>
  );
}

export default UpdateCardResponsive;
