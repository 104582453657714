// pages/AboutUs.js
import React from 'react';
import AboutUsHero from '../components/AboutUs/AboutUsHero';
import AboutUsIntro from '../components/AboutUs/AboutUsIntro';
import { FoundersSection } from '../components/AboutUs/FoundersSection';


const AboutUs = () => {
    return (
        <div className="aboutus">
         
          <main>
            <AboutUsHero/>
            <div className="bg-[#01111A]">
            <AboutUsIntro/>
            <FoundersSection/>
            </div>
            
          </main>
        </div>
      );
};

export default AboutUs;