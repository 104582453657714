import React, { useEffect, useState } from 'react';
import UpdateCard from './UpdateCard';

function Showcase() {
  const [updates, setUpdates] = useState([]);

  useEffect(() => {
    async function fetchLatestUpdates() {
      try {
        const response = await fetch('/blog_posts/published/');
        if (!response.ok) throw new Error('Failed to fetch blog posts');
        
        const data = await response.json();
        setUpdates(data);
      } catch (error) {
        console.error('Error fetching latest blog posts:', error);
      }
    }

    fetchLatestUpdates();
  }, []);
  return (
    <section className="flex flex-col mt-12 w-full max-md:mt-10 max-md:max-w-full">
      <header className="flex flex-wrap gap-10 justify-between items-start w-full max-md:max-w-full">
        <h2 className="flex gap-1 items-center text-2xl font-bold leading-none text-orange-500 whitespace-nowrap">
          Showcase
          <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/bd69caf513a031a441b0ae2c28050f8e01024dfd2f15fb76144d64f2f7d9df8f?placeholderIfAbsent=true&apiKey=9d196419b2b0427f99f4a8814e99f56a" alt="" className="object-contain shrink-0 self-stretch my-auto w-6 aspect-square" />
        </h2>
        
      </header>
      <div className="mt-4 w-full max-md:max-w-full">
        <div className="flex gap-5 max-md:flex-col">
        {updates.map((update) => (
            <UpdateCard
              key={update.id}
              title={update.heading}
              imageSrc={update.image1}
              introduction={update.preview_text}
              blogPostId={update.id}
            />
          ))}
        </div>
      </div>
    </section>
  );
}

export default Showcase;