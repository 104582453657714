import React, { useState } from "react";
import DemoPopup from "../DemoPopup";

const MobileFeatureCard = ({
  title,
  subtitle,
  features,
  buttonText,
  demoYTLink,
  demo_link,
  is_try,
  is_watch,
}) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const handleOpenPopup = () => {
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  const handleButtonClick = () => {
    if (is_try) {
      console.log("Button Clicked");
      window.open(demo_link, "_blank", "noopener,noreferrer");
    } else {
      console.log("Button Clicked");
      handleOpenPopup();
    }
  };

  return (
    <div className="flex flex-col grow justify-center items-start -mt-[22rem] rounded-2xl border border-solid border-gray-500 h-[120%] w-[full] z-5 p-4 mb-20 max-sm  :-mx-6 max-lg:-mx-6">
      <div className="flex flex-col w-full overflow-visible"> 
        <div className="flex flex-col items-start w-full">
          <h3 className="text-3xl font-bold text-orange-500 text-left pl-0 pt-20">
            {title}
          </h3>
          <p className="text-lg leading-none text-zinc-300 text-left break-words max-w-full pt-2 pl-0">
            {subtitle}
          </p>
        </div>
        <ul className="flex flex-col mt-8 w-full text-sm leading-6 text-zinc-300 items-start overflow-visible">
          {features.map((feature, index) => (
            <li
              key={index}
              className="flex gap-2.5 items-center mt-1.5 w-full justify-start overflow-visible"
            >
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/728e7f2d1a96680b06158aab96322a9ebd14d0d3dc9f59ffa5d8f7a32c036b9c?placeholderIfAbsent=true&apiKey=2a0ad5d14c014777b1f4b2f82bd16488"
                alt=""
                className="object-contain shrink-0 w-4 aspect-square border-2 border-solid border-[#312D34]"
              />
              <span className="self-stretch">{feature}</span>
            </li>
          ))}
        </ul>
        <div className="flex gap-8 items-center mt-8 w-full z-100 text-base font-medium text-white justify-start">
          {is_watch ? (
            <button onClick={handleOpenPopup} className="gap-2.5 self-stretch px-4 py-2.5 bg-orange-500 rounded z-50">
              Watch Demo
            </button>
          ) : is_try ? (
            <a href={demo_link} target="_blank" rel="noopener noreferrer" className="gap-2.5 self-stretch px-4 py-2.5 bg-orange-500 rounded z-50">
              Try It Out
            </a>
          ) : (
            <a href="/contact" className="gap-2.5 self-stretch px-4 py-2.5 bg-orange-500 rounded z-50">
              Get in touch
            </a>
          )}
        </div>
      </div>
      {isPopupOpen && (
        <DemoPopup directDriveLink={demoYTLink} onClose={handleClosePopup} />
      )}
    </div>
  );
};

export default MobileFeatureCard;
