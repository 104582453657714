import React, { useState, useEffect } from 'react';
import NavItem from '../NavItem';
import { FaTimes, FaBars } from 'react-icons/fa';

const navItems = [
  { label: 'About us', href: '/about' },
  { label: 'Careers', href: '/careers' },
  { label: 'Contact Us', href: '/contact' }
];

function MobileHeader() {
  const [isOpen, setIsOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };
    
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={`fixed top-0 left-0 right-0 flex flex-col items-start py-4 px-8 z-200 transition-all duration-300 ${
      isScrolled ? 'bg-black/80 ' : 'bg-black/90'
    } backdrop-blur-lg text-white`}>
      <div className="flex justify-between w-full items-center h-full">
        <h1 onClick={() => window.location.href = '/'} className="text-2xl font-bold text-primary cursor-pointer">Think41</h1> {/* Header title */}
        <button onClick={toggleMenu} className="text-2xl">
          {isOpen ? <FaTimes /> : <FaBars />} {/* Change icon based on menu state */}
        </button>
      </div>
      {isOpen && (
        <div className="absolute top-14 left-0 right-0 bg-black/90 backdrop-blur-lg z-50">
          <nav className="flex flex-col mt-2 items-end"> {/* Align items to the right */}
            <div className="flex flex-col items-left pl-6 w-full"> {/* Ensure full width for right alignment */}
              {navItems.map((item, index) => (
                <>
                  <NavItem 
                    key={index} 
                    label={item.label} 
                    href={item.href} 
                    onClick={toggleMenu} 
                    className={index !== navItems.length - 1 ? "border-b border-white" : ""} // Only add border if not last item
                  />
                  {index !== navItems.length - 1 && <hr className="border-b border-gray-600 max-w-[90%]" />}
                </>
              ))}
            </div>
          </nav>
        </div>
      )}
    </div>
  );
}

export default MobileHeader;
