import React from 'react';

export function Testimonials() {
  const testimonials = [
    {
      quote: "\"Transitioning from an intern to a full-time role at Think41 has been an incredible journey. The steep learning curve has been highly rewarding. I've made great friends, love the vibrant office in HSR, and see immense growth potential here. It’s a place where my career is truly thriving.\"",
      name: "Kumar Adarsh Pandita",
      role: "Full Stack Engineer",
      imageUrl: "https://cdn.builder.io/api/v1/image/assets/9d196419b2b0427f99f4a8814e99f56a/5920ab5e1e7be09a8821667574e7cde11c356e11a032f39024eeb57ea02e6d5e?apiKey=9d196419b2b0427f99f4a8814e99f56a&"
    },
    {
      quote: "\"Working as a product manager at Think41 has been incredibly rewarding. The open and approachable leadership fosters a culture where every idea is valued. Our team is both friendly and highly collaborative, making problem-solving a collective effort. The work is challenging and engaging, with thought-provoking projects that keep us constantly learning.\"",
      name: "Anusha Aravapalli",
      role: "Technical Product Manager",
      imageUrl: "https://cdn.builder.io/api/v1/image/assets/9d196419b2b0427f99f4a8814e99f56a/6cbdcacd3fe2d1f40d8f71c59ac08bc20074f725f1544be17ee6a0c693332d3e?apiKey=9d196419b2b0427f99f4a8814e99f56a&"
    },
    {
      quote: "\"At Think41, the culture perfectly matches my expectations—innovative and supportive. The seamless integration of AI into our projects is impressive. I value the autonomy to lead my team while receiving strong support from leadership. It's fantastic to work in an environment where freedom and collaboration thrive together.\"",
      name: "Hussain",
      role: "Engineering Manager",
      imageUrl: "https://cdn.builder.io/api/v1/image/assets/9d196419b2b0427f99f4a8814e99f56a/e1cd096ea276b3863f966277fd2ea9d0c46237c02aba9bfed0df4e00797aea6b?apiKey=9d196419b2b0427f99f4a8814e99f56a&"
    }
  ];

  return (
    <section className="flex flex-col px-[16px] md:px-[40px] lg:px-[100px]">
      <header className="flex flex-col items-center self-center max-w-full text-center text-zinc-300 w-[100%] lg:w-[660px]">
        <h1 className="text-[24px] md:text-[28px] lg:text-[32px] text-[#d8d8d8] font-bold">Thinker's Speak</h1>

        {/* Line with gradient */}
        <div className="self-stretch mt-4 w-full h-[5px] bg-gradient-to-r from-[#01111A] via-[#F57C00] to-[#01111A] -mx-6 mb-3" />
        <p className='text-[14px] md:text-[16px] lg:text-[20px] text-[#d8d8d8]'>What our employees have to say</p>
      </header>
      
      {/* Responsive Card Layout */}
      <div className="grid grid-cols-1 lg:flex lg:flex-wrap gap-6 self-center mt-8 w-full text-base leading-6 text-black max-w-[1240px]">
        {testimonials.map((testimonial, index) => (
          <TestimonialCard
            key={index}
            quote={testimonial.quote}
            name={testimonial.name}
            role={testimonial.role}
            imageUrl={testimonial.imageUrl}
          />
        ))}
      </div>
    </section>
  );
}

function TestimonialCard({ quote, name, role, imageUrl }) {
  return (
    <article className="flex flex-col flex-1 shrink justify-between p-4 md:p-6 rounded-lg shadow-sm basis-0 bg-zinc-300 min-w-[240px] w-full max-w-full max-md:px-4">
      <blockquote className="relative leading-6 text-[14px] md:text-[14px] lg:text-[16px]">
        {quote}
      </blockquote>
      <div className="flex gap-4 items-center mt-3 md:mt-7 w-full">
        <img 
          loading="lazy" 
          src={imageUrl} 
          alt={`Portrait of ${name}`}
          className="object-cover rounded-full w-[50px] h-[50px] md:w-[60px] md:h-[60px]" 
        />
        <div className="leading-6">
          <p className="font-semibold m-0">{name}</p>
          <p className="text-[14px] md:text-[14px] lg:text-[16px] m-0">{role}</p>
        </div>
      </div>
    </article>
  );
}

export default Testimonials;