import React, { useState } from "react";
import InfoModal from "./InfoModal";

function GenAiCard() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const modalContent = `<p>Prompts are the instruction manual for LLMs and have to be managed effectively. Given the probabilistic nature of LLMs, it is crucial to implement guardrails, retries, exhaustive test-harness, and failure handling to ensure a high quality of service.</p>
  </br>
  <p>Additionally, monitoring and audit systems are essential to ensure uptime and to detect and recover from problems quickly. This, combined with the essential principles of modern software engineering, ensures a system that can react to, handle, and solve an expanded category of problems.</p>`;

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <>
      <section
        className="flex flex-col items-center justify-center rounded-lg py-2 sm:py-3 bg-cyan-950 min-h-[200px] relative overflow-hidden bg-gradient-to-b from-[#698da2] to-transparent backdrop-blur-lg w-full "
        aria-label="GenAI Application Layer Card"
      >
        <header className="text-4xl sm:text-5xl font-semibold  leading-8 sm:leading-10 text-slate-50 text-center max-md:-mt-4">
          <h2 className="text-center">
            <span className="text-3xl max-md:text-2xl ">GenAI</span>
            <br />
            <span className="text-2xl max-md:text-xl">Application Layer</span>
          </h2>
        </header>

        <section className="flex flex-col gap-4 sm:gap-5 justify-center items-center mt-4 sm:mt-6 w-full px-2 text-base sm:text-lg leading-loose text-blue-200 whitespace-nowrap text-center">
          <div className="flex flex-col items-center">
            {" "}
            {/* Centering the inner div */}
            <div className="flex flex-col sm:flex-row gap-4 sm:gap-6 items-center justify-center">
              {" "}
              {/* Responsive layout */}
              <div className="flex flex-col px-1 sm:px-2 w-full sm:w-auto">
                <div className="flex flex-row justify-center">
                  <div className="border-l border-blue-500 py-1 sm:py-2 px-3 sm:px-4 text-sm sm:text-base">
                    Prompts
                  </div>
                  <div className="border-r border-l border-blue-500 py-1 sm:py-2 px-3 sm:px-4 text-sm sm:text-base">
                    APIs
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="absolute bottom-2 sm:bottom-2 right-2 sm:right-3">
          <button
            className="border border-gray-500 rounded-full w-8 h-8 flex items-center justify-center shadow-lg bg-transparent hover:border-gray-400"
            onClick={openModal} // This opens the modal
          >
            <span className="text-gray-500 text-2xl sm:text-xl hover:text-gray-400">
              +
            </span>
          </button>
        </div>
      </section>
      <InfoModal
        isOpen={isModalOpen}
        onClose={closeModal}
        content={modalContent}
        modalHeader="GenAI Application Layer - Integrating Generative AI"
      />
    </>
  );
}

export default GenAiCard;
