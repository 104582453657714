import React from 'react';
import FeatureCard from './FeatureCard';

const FeatureSection = ({ title, subtitle, features, buttonText, imageSrc, demoYTLink, demo_link, is_try, is_watch }) => {
  return (
    <div className="mt-20 w-full max-md:mt-10 max-md:max-w-full flex justify-center mx-auto " >
      <div className="flex gap-5 max-md:flex-col justify-center items-start mx-28" >
        <div className="flex flex-col w-[800px] max-md:ml-0 max-md:w-full mt-10 relative mx-28 ml-32"> {/* Set z-index to 0 */}
          <FeatureCard
            title={title}
            subtitle={subtitle}
            features={features}
            buttonText={buttonText}
            demoYTLink={demoYTLink}
            demo_link={demo_link}
            is_try={is_try}
            is_watch={is_watch}
          />
        </div>
        <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full relative ml-5"> {/* Set z-index to 0 */}
          <div className="w-[700px] h-[430px] max-md:h-[450px] overflow-visible -ml-56"> {/* Increased width and height */}
            <img loading="lazy" src={imageSrc} alt={title} className="object-fit w-full h-full rounded-xl border-2 border-solid border-[#312D34]" /> {/* Changed video back to img for GIF display */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeatureSection;
