import React from 'react';
import { FaUser } from 'react-icons/fa';

const MobileArticleHeader = ({ title, authors, date, readTime }) => {
  const formatDate = (dateString) => {
    const dateObj = new Date(dateString);
    const options = { month: 'short', day: 'numeric', year: 'numeric' };
    return dateObj.toLocaleDateString(undefined, options);
  };

  return (
    <div className="flex flex-col px-8 py-10 mt-14 w-full bg-slate-950">
      <h1 className="text-3xl font-bold text-orange-500">{title}</h1>
      <div className="flex flex-col mt-4 text-white">
        {authors.map((author) => (
          <div key={author.id} className="flex items-center mt-2">
            {author.image ? (
              <img
                loading="lazy"
                src={author.image}
                alt={`${author.name}'s profile`}
                className="w-10 h-10 rounded-full mr-3"
              />
            ) : (
              <FaUser className="w-10 h-10 text-gray-500 mr-3" />
            )}
            <div>
              <p className="text-lg font-semibold">{author.name}</p>
              <p className="text-sm">{author.designation}</p>
            </div>
          </div>
        ))}
        <div className="mt-3 text-sm">
          {formatDate(date)} • {readTime} min read
        </div>
      </div>
    </div>
  );
};

export default MobileArticleHeader;

