import * as React from "react";
import IntelligentInterfacesCard from "./TechRadarCards/IntelligentInterfacesCard";
import AssistantsCopilotsCard from "./TechRadarCards/AssistantsCopilotsCard";
import GenAiCard from "./TechRadarCards/GenAiCard"; // Import the GenAiCard component
import AgenticWorkflows from "./TechRadarCards/AgenticWorkflows";
import ReferenceDataCard from "./TechRadarCards/ReferenceDataCard";
import VectorStoresCard from "./TechRadarCards/VectorStoresCard";
import OpenWeight from "./TechRadarCards/OpenWeight";
import CustomModels from "./TechRadarCards/CustomModels";
import ProprietoryModels from "./TechRadarCards/ProprietoryModels";
import ProprietaryData from "./TechRadarCards/ProprietaryData";

export default function TechRadar() {
  return (
    <main data-layername="techRadar" className="flex flex-col px-24 py-12  bg-slate-950">
      <header className="flex flex-col justify-center items-center w-full text-center mt-10 ">
        <h1 data-layername="techExpertise" className="text-4xl font-semibold text-orange-500">
          Tech Expertise
        </h1>
        <div className="w-[500px] h-[5px] mt-2" style={{
          background: "linear-gradient(90deg, rgba(255, 255, 255, 0.00) 0%, #F57C00 50%, rgba(255, 255, 255, 0.00) 100%)"
        }} />
        <div className="mt-2.5 max-w-full min-h-[5px] w-[455px]" />
        <p data-layername="discoverOurExpertiseAcrossTheAiLandscape" className="mt-2.5 text-lg text-zinc-300">
          Discover Our Expertise Across the AI Landscape
        </p>
      </header>

      <section className="grid grid-cols-12 gap-4 px-9 py-8 mt-5 w-full bg-slate-950">
        {/* Row 1: IntelligentInterfacesCard and AssistantsCopilotsCard */}
        <div className="col-span-6">
          <IntelligentInterfacesCard />
        </div>
        <div className="col-span-6">
          <AssistantsCopilotsCard />
        </div>

        {/* Row 2: GenAiCard and AgenticWorkflows */}
        <div className="col-span-3 mt-6">
          <GenAiCard />
        </div>
        <div className="col-span-3 mt-6">
          <AgenticWorkflows />
        </div>
        <div className="col-span-3 mt-6">
          <ReferenceDataCard /> {/* Reference Data Card */}
        </div>
        <div className="col-span-3 mt-6">
          <VectorStoresCard /> {/* Vector Stores Card */}
        </div>

        {/* Row 3 and 4: OpenWeight spans two rows */}
        <div className="col-span-3 row-span-2">
          <OpenWeight /> {/* OpenWeight Card spanning rows 3 and 4 */}
        </div>
        <div className="col-span-9">
          <CustomModels />
        </div>

        {/* Row 5: ProprietoryModels and ProprietaryData */}
        <div className="col-span-6">
          <ProprietoryModels /> {/* Additional ProprietoryModels Card */}
        </div>
        <div className="col-span-3">
          <ProprietaryData /> {/* Additional ProprietaryData Card */}
        </div>
      </section>
    </main>
  );
}
