import React, { useState } from "react";
import InfoModal from "./InfoModal"; // Adjust the import path as necessary
import { FaMicrophone, FaFileAlt } from "react-icons/fa"; // Importing icons

const AssistantsCopilotsCard = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const modalContent =
    "<p>The data density of voice is much higher than typing or clicking. You can arrange for a voice discussion with the system to collect much more information in a lot less time from a lot more people.</p>" +
    "</br>" +
    "<p>Assistants can be passive participants, providing a voice in the ear, or active collaborators in important meetings, helping with note-taking, delivering data, information, and insights on the fly.</p>" +
    "</br>" +
    "<p>Primary use cases include sales support, customer support, training, interviews, and active assistance.</p>";

  return (
    <section className="flex flex-wrap grow items-center justify-center w-full bg-cyan-950 relative rounded-lg min-h-[240px] max-w-full">
      <h2 className="w-full text-4xl font-semibold tracking-tight leading-none text-transparent bg-clip-text bg-gradient-to-r from-gray-300 to-primary text-center ml-3">
        Assistants & Copilots
      </h2>
      <div className="flex gap-6 items-center justify-center flex-wrap -mt-10">
        <div className="flex gap-4 justify-center items-center self-stretch px-5 py-2 my-auto text-base font-medium leading-4 text-cyan-200 rounded-full border border-solid bg-slate-300 bg-opacity-20 border-white border-opacity-10 min-h-[48px]">
          <FaMicrophone className="text-3xl gradient-icon max-md:text-sm" />{" "}
          {/* Icon for Natural Voice Conversation */}
          <div className="my-auto max-md:text-xs">
            Natural Voice Conversation
          </div>
        </div>
        <div className="flex gap-4 justify-center items-center self-stretch px-5 py-1 my-auto text-base font-medium leading-4 text-cyan-200 rounded-full border border-solid bg-slate-300 bg-opacity-20 border-white border-opacity-10 min-h-[48px] max-md:px-2 max-md:py-0.5 max-md:text-md max-md:min-h-[40px] max-md:-mt-6">
          <FaFileAlt className="text-3xl gradient-icon max-md:text-xs" />{" "}
          {/* Icon for Rich Text */}
          <div className="self-stretch my-auto max-md:text-xs">Rich Text</div>
        </div>
      </div>
      <div className="absolute bottom-3 right-3 sm:bottom-2 sm:right-2">
        <button
          className="border border-gray-500 rounded-full w-10 h-10 sm:w-8 sm:h-8 flex items-center justify-center shadow-lg bg-transparent hover:border-gray-400"
          onClick={openModal} // This opens the modal
        >
          <span className="text-gray-500 text-2xl sm:text-xl hover:text-gray-400">
            +
          </span>
        </button>
      </div>
      <InfoModal
        isOpen={isModalOpen}
        onClose={closeModal}
        content={modalContent}
        modalHeader="Assistants & Copilots - Enhancing User Experience"
      />
    </section>
  );
};

export default AssistantsCopilotsCard;
