import React from 'react';

function TransformationCard({ title, description, imageSrc, className }) {
  // Function to handle image path
  const getImagePath = (src) => {
    if (src.startsWith('/public/')) {
      return src.replace('/public', '');
    }
    return src;
  };

  return (
    <article className={`flex overflow-hidden flex-col flex-1 shrink justify-between self-stretch p-8 my-auto bg-[#08293C] min-w-[240px] h-[400px] rounded-[32px] shadow-[0px_4px_34px_rgba(0,0,0,0.12)] max-md:px-5 relative transition-all duration-300 hover:shadow-[0_0_20px_rgba(255,255,255,0.3)] hover:-translate-y-1 hover:scale-[1.02] ${className || ''}`}>
      <div 
        className="absolute w-[478.314px] h-[478.314px] left-[-220.723px] top-[-320.662px] rounded-[478.314px]"
        style={{
          transform: 'rotate(-151.194deg)',
          background: 'radial-gradient(50% 50% at 50% 50%, rgba(241, 110, 0, 0.35) 0%, rgba(208, 89, 3, 0.00) 100%)',
          zIndex: 0
        }}
      />
      <div className="flex flex-col w-full h-full relative z-10">
        <div className="flex items-center h-[90px] mb-8">
          {imageSrc ? (
            <img 
              loading="lazy" 
              src={getImagePath(imageSrc)} 
              alt="" 
              className="object-contain rounded-lg aspect-square shadow-[2px_2px_4px_rgba(255,255,255,0.09)] w-[90px]" 
            />
          ) : (
            <div className="w-[90px] h-[90px]" />
          )}
        </div>
        <div className="flex flex-col flex-grow">
          <h2 className="text-3xl font-bold leading-none text-white mb-4">
            {title}
          </h2>
          <p className="flex-grow text-base leading-6 text-zinc-300 overflow-y-auto">
            {description}
          </p>
        </div>
      </div>
      <div 
        className="absolute w-[600px] h-[600px] right-[-500px] bottom-[-250px] rounded-[600px]"
        style={{
          transform: 'rotate(-151.194deg)',
          background: 'radial-gradient(50% 50% at 50% 50%, rgba(67, 221, 255, 0.60) 0%, rgba(83, 23, 182, 0.00) 100%)',
          zIndex: 0
        }}
      />
    </article>
  );
}

export default TransformationCard;