import React, { useEffect, useState } from 'react';

import HeaderComponent from '../../components/HeaderComponent';
import ArticleHeader from '../../components/Blogs/ArticleHeader';
import MobileArticleHeader from '../../components/Blogs/Mobile/MobileArticleHeader';
import ArticleContent from '../../components/Blogs/ArticleContent';
import MobileArticleContent from '../../components/Blogs/Mobile/MobileArticleContent';

const ArticlePage = ({ id, article, authors, relatedArticles }) => {
  const [authorData, setAuthorData] = useState([]); // Updated to handle multiple authors
  const [articleData, setArticleData] = useState(null); // State for article data
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  const calculateReadTime = (content) => {
    const wordsPerMinute = 200; // Average reading speed
    const wordCount = content.split(' ').length; // Count words in content
    return Math.ceil(wordCount / wordsPerMinute); // Calculate read time in minutes
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1253);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    // Fetch author data
    const fetchAuthorData = async () => {
      try {
        const response = await fetch(`/api/blog_posts/${id}/authors`);
        const data = await response.json();
        setAuthorData(data); // Set author data
      } catch (error) {
        console.error("Error fetching author data:", error);
      }
    };

    // Fetch article data
    const fetchArticleData = async () => {
      try {
        const response = await fetch(`/api/blog_posts/${id}`);
        const data = await response.json();
        setArticleData(data); // Set article data
        // Calculate read time from intro and sections content
        const introReadTime = calculateReadTime(data.intro);
        const sectionsReadTime = data.sections.reduce((acc, section) => acc + calculateReadTime(section.content), 0);
        const totalReadTime = introReadTime + sectionsReadTime;
        data.read_time = totalReadTime; // Update read_time in article data
      } catch (error) {
        console.error("Error fetching article data:", error);
      }
    };

    fetchAuthorData();
    fetchArticleData();
  }, [id]); // Dependency array to run effect when id changes

  if (!authorData.length || !articleData) { // Updated condition to check for authors
    return <div>Loading...</div>;
  }

  return (
    <div className="flex overflow-hidden flex-col bg-slate-950 pb-[50px]">
      <main>
        {isMobile ? (
          <MobileArticleHeader
            title={articleData.heading}
            authors={authorData} // Pass all authors
            date={articleData.published_date}
            readTime={articleData.read_time}
          />
        ) : (
          <ArticleHeader
            title={articleData.heading}
            authors={authorData} // Pass all authors
            date={articleData.published_date}
            readTime={articleData.read_time}
          />
        )}
        {isMobile ? (
          <MobileArticleContent id={id} />
        ) : (
          <ArticleContent id={id} />
        )}
      </main>
    </div>
  );
};

export default ArticlePage;
