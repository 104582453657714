import React from 'react';
import { IMAGE_BASE_URL } from '../../config';
const AboutUsIntro = () => {
  return (
    <div className="flex flex-col items-center justify-center w-full text-center pt-[100px]">
      <header className="flex flex-col items-center self-center max-w-full text-center text-zinc-300 w-[660px]">
        <h1 className="text-[18px] md:text-[24px] lg:text-[30px] font-bold">About Us</h1>

        {/* Line with gradient */}
        <div className="self-stretch mt-4 w-full h-[5px] bg-gradient-to-r from-[#01111A] via-[#F57C00] to-[#01111A] -mx-6" />
      </header>
      <div className="w-full max-w-[800px] mt-2 px-4">
        <div className="text-[#d8d8d8] text-[14px] md:text-[16px] lg:text-[18px] leading-[28px]">
        We are a young, well-funded, full-stack GenAI organization building systems and software that enable better collaboration between humans and AI, with the goal of enhancing human experience and creating a more fulfilling work environment. Our tools, systems, and platforms support this goal, helping us operate as one team that delivers higher-quality results, faster.
        </div>
      </div>

      {/* Gap between About Us and Image Section */}
      <div className="my-10 lg:my-[100px] md:my-[50px] sm:my-[25px] flex flex-col lg:flex-row lg:gap-6 text-white text-left">
        {/* Image Section 1*/}
        <div className="w-full lg:w-1/2">
          <img 
            src={`${IMAGE_BASE_URL}aboutUs1.png`} 
            alt="Person working at computer" 
            className="w-full h-auto max-h-[400px] object-cover rounded-[0px_8px_8px_0px] lg:rounded-[0px_8px_8px_0px] max-w-full shadow-blue-glow" 
          />
        </div>

        {/* Text Section 1 */}
        <div className="w-full lg:w-1/2 px-6 lg:pl-6 lg:pr-[100px] py-16 flex flex-col justify-center">
          <h2 className="text-[#f16e00] text-[18px] md:text-[24px] lg:text-[30px] font-bold mb-4">
            We use GenAI and Agentic Workflows for Operations
          </h2>
          
          <p className="text-[#D8D8D8] text-[14px] md:text-[16px] lg:text-[18px] leading-[28px] mb-12">
            We move faster be it recruitment, training, project management, allocations, execution and so on all while delivering a high personalised experience where software adapts to your needs and not the other way around.

          </p>
        </div>
      </div>
      <div className="flex flex-col-reverse lg:flex-row text-white text-left">
        {/* Text Section */}
        <div className="w-full lg:w-1/2 lg:pl-[100px] px-6 py-12 flex flex-col justify-center">
          <h2 className="text-[#f16e00] text-[18px] md:text-[24px] lg:text-[30px] font-bold mb-4">GenAI Technology led Solutions and Services</h2>
          <p className="mb-6 text-[14px] md:text-[16px] lg:text-[18px] leading-[28px]">
            We invest in a GenAI foundation, so we can offer a much higher value proposition to our clients.This enables us to take on projects that many would find challenging, delivering results faster and with minimal hassle.
          </p>
        </div>

        {/* Image Section */}
        <div className="w-full lg:w-1/2">
          <img 
            src={`${IMAGE_BASE_URL}aboutUs2.png`} 
            alt="Person working at computer" 
            className="w-full h-auto max-h-[400px] object-cover rounded-[8px_0px_0px_8px] lg:rounded-[8px_0px_0px_8px] max-w-full shadow-blue-glow" 
          />
        </div>
      </div>
      <div className="my-10 lg:my-[100px] md:my-[50px] sm:my-[25px] flex flex-col lg:flex-row lg:gap-6 text-white text-left">
        {/* Image Section 3 */}
        <div className="w-full lg:w-1/2">
          <img 
            src={`${IMAGE_BASE_URL}services2.png`} 
            alt="Person working at computer" 
            className="w-full h-auto max-h-[400px] object-cover rounded-[0px_8px_8px_0px] lg:rounded-[0px_8px_8px_0px] shadow-blue-glow" 
          />
        </div>

        {/* Text Section 3 */}
        <div className="w-full lg:w-1/2 px-6 lg:pl-6 lg:pr-[100px] py-16 flex flex-col justify-center">
          <h2 className="text-[#f16e00] text-[18px] md:text-[24px] lg:text-[30px] font-bold mb-4">
          Dev41 Platform for Software Generation & Management
          </h2>
          
          <p className="text-[#D8D8D8] text-[14px] md:text-[16px] lg:text-[18px] mb-12 leading-[28px] ">
          With Dev41 our teams create and launch products much faster. This unique platform lets us deliver more with less and works across the SDLC. It can be customised to specific client needs accelerating software development across teams.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutUsIntro;