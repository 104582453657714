import React, { useState, useEffect, useRef } from "react";
import AuthorCard from "../AuthorCard"; // Import AuthorCard
import { FaTwitter, FaLinkedin, FaFacebook, FaList } from "react-icons/fa"; // Import icons from react-icons/fa

const MobileArticleContent = ({ id }) => {
  const [blogPost, setBlogPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [authors, setAuthors] = useState([]);
  const [showTOC, setShowTOC] = useState(false);
  const [activeId, setActiveId] = useState('');
  const observerRef = useRef({});
  const [showTOCButton, setShowTOCButton] = useState(false);
  const articleRef = useRef(null);

  useEffect(() => {
    const fetchBlogPost = async () => {
      try {
        const response = await fetch(`/api/blog_posts/${id}`);
        const data = await response.json();
        setBlogPost(data);
      } catch (error) {
        console.error("Error fetching blog post:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchBlogPost();
  }, [id]);

  useEffect(() => {
    const fetchAuthors = async () => {
      try {
        const response = await fetch(`/api/blog_posts/${id}/authors`);
        const data = await response.json();

        const formattedAuthors = Array.isArray(data)
          ? data.map((author) => ({
              id: author.id,
              name: author.name,
              image: author.image,
              designation: author.designation,
              socialLinks: [
                {
                  name: "Instagram",
                  url: author.social_links.instagram,
                  icon: "instagram-icon.png",
                },
                {
                  name: "LinkedIn",
                  url: author.social_links.linkedin,
                  icon: "linkedin-icon.png",
                },
                {
                  name: "Twitter",
                  url: author.social_links.twitter,
                  icon: "twitter-icon.png",
                },
              ],
              bio: author.about,
            }))
          : [
              {
                id: data.id,
                name: data.name,
                image: data.image,
                designation: data.designation,
                socialLinks: [
                  {
                    name: "Instagram",
                    url: data.social_links.instagram,
                    icon: "instagram-icon.png",
                  },
                  {
                    name: "LinkedIn",
                    url: data.social_links.linkedin,
                    icon: "linkedin-icon.png",
                  },
                  {
                    name: "Twitter",
                    url: data.social_links.twitter,
                    icon: "twitter-icon.png",
                  },
                ],
                bio: data.about,
              },
            ];

        setAuthors(formattedAuthors);
      } catch (error) {
        console.error("Error fetching authors:", error);
      }
    };

    fetchAuthors();
  }, [id]);

  useEffect(() => {
    if (!blogPost) return;
    
    const headings = document.querySelectorAll('h2[id^="heading-"]');
    
    const callback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setActiveId(entry.target.id);
        }
      });
    };

    const options = {
      rootMargin: '-20% 0px -35% 0px',
      threshold: 0
    };

    observerRef.current = new IntersectionObserver(callback, options);

    headings.forEach((element) => {
      observerRef.current.observe(element);
    });

    return () => observerRef.current?.disconnect();
  }, [blogPost]);

  useEffect(() => {
    if (!blogPost) return;

    const handleScroll = () => {
      if (!articleRef.current) return;

      const articleStart = articleRef.current.getBoundingClientRect().top;
      const articleEnd = articleRef.current.getBoundingClientRect().bottom;
      
      // Show TOC when article content is in view
      setShowTOCButton(
        articleStart < window.innerHeight * 0.5 && // Past the start
        articleEnd > window.innerHeight * 0.4 // Before the end
      );
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Initial check

    return () => window.removeEventListener('scroll', handleScroll);
  }, [blogPost]);

  const handleSectionClick = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      const headerOffset = 80;
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
    setShowTOC(false); // Close TOC after clicking
  };

  if (loading) return <div className="p-4">Loading...</div>;

  if (!blogPost) return <div className="p-4">No blog post found.</div>;

  return (
    <div className="flex flex-col px-4 sm:px-10 pb-8 mt-20 bg-orange-50 rounded-lg max-w-[90%] mx-auto">
      <div className="w-full rounded-lg -mt-20 overflow-hidden shadow-md relative h-0 pb-[56.25%]">
        <img
          loading="lazy"
          src={blogPost.image1}
          alt="Article header"
          className="absolute top-0 left-0 w-full h-full object-cover "
        />
      </div>
      <div
        dangerouslySetInnerHTML={{ __html: blogPost.intro }}
        className="text-black mt-4"
      />
      <article ref={articleRef} className="mt-4">
        {blogPost.sections.map((section, index) => (
          <div key={index} id={`section-${index}`} className="mt-4">
            <h2 
              id={`heading-${index}`}
              className="text-lg font-semibold text-black"
            >
              {section.heading}
            </h2>
            <div
              dangerouslySetInnerHTML={{ __html: section.content }}
              className="text-black mt-2" // Ensured text is black
            />
            {section.image && (
              <div className="mt-2 rounded-lg overflow-hidden shadow-md">
                <img
                  src={section.image}
                  alt={`Section ${index + 1}`}
                  className="w-full h-auto object-cover"
                />
              </div>
            )}
          </div>
        ))}
      </article>
      <div className="mt-4 flex justify-between items-center">
        <p className="text-md text-black">Share this article:</p> {/* Changed text color to black */}
        <div className="flex space-x-3">
          <a
            href={`https://twitter.com/share?url=${encodeURIComponent(
              window.location.href
            )}&text=Check out this article I read on Think41 blogs`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaTwitter className="w-6 h-6 text-primary" />
          </a>
          <a
            href={`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
              window.location.href
            )}&title=Check out this article I read on Think41 blogs`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaLinkedin className="w-6 h-6 text-primary" />
          </a>
          <a
            href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
              window.location.href
            )}&quote=Check out this article I read on Think41 blogs`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaFacebook className="w-6 h-6 text-primary" />
          </a>
        </div>
      </div>
      <div className="mt-4">
        <h2 className="text-lg font-semibold text-black">Authors</h2>
        <div className="mt-2">
          {authors.map((author, index) => (
            <AuthorCard
              key={index}
              name={author.name}
              title={author.designation}
              bio={author.bio}
              image={author.image}
              socialLinks={author.socialLinks}
            />
          ))}
        </div>
      </div>

      {showTOCButton && (
        <button
          onClick={() => setShowTOC(!showTOC)}
          className="fixed bottom-8 left-5 p-3 bg-orange-50 backdrop-blur-md text-primary rounded-full shadow-lg z-50 border border-primary/40 hover:bg-orange-50 transition-colors"
          aria-label="Table of Contents"
        >
          <FaList className="w-5 h-5" />
        </button>
      )}

      <div 
        className={`fixed inset-0 bg-black/20 backdrop-blur-sm transition-opacity duration-300 z-40 ${
          showTOC ? 'opacity-100' : 'opacity-0 pointer-events-none'
        }`}
        onClick={() => setShowTOC(false)}
      >
        <div
          className={`fixed left-4 right-4 bottom-20 max-h-[70vh] w-auto max-w-md mx-auto bg-white   rounded-xl shadow-2xl transform transition-all duration-300 ease-out z-50 ${
            showTOC ? 'translate-y-0 opacity-100' : 'translate-y-full opacity-0'
          }`}
          onClick={e => e.stopPropagation()}
        >
          <div className="h-full flex flex-col">
            <div className="p-4 border-b border-gray-100 flex justify-between items-center">
              <h2 className="text-lg font-semibold text-black">In this article</h2>
              <button
                onClick={() => setShowTOC(false)}
                className="p-2 hover:bg-gray-100 rounded-full transition-colors"
              >
                <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
            
            <nav className="flex-1 overflow-y-auto p-4">
              {blogPost.sections.map((section, index) => (
                <a
                  key={index}
                  href={`#heading-${index}`}
                  className={`block mb-2 p-3 rounded-lg transition-all hover:bg-orange-50 ${
                    `heading-${index}` === activeId
                      ? 'bg-orange-100 text-primary font-medium'
                      : 'text-gray-600'
                  }`}
                  onClick={(e) => {
                    e.preventDefault();
                    handleSectionClick(`heading-${index}`);
                  }}
                >
                  <div className="flex items-center">
                    <span className="text-sm text-primary/60 w-8">
                      {`0${index + 1}`.slice(-2)}
                    </span>
                    <span className="flex-1">{section.heading}</span>
                  </div>
                </a>
              ))}
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileArticleContent;
