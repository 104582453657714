import React, { useRef, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';

const LatestInsights = () => {
  const carouselRef = useRef(null);
  const [insightsData, setInsightsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentIndex, setCurrentIndex] = useState(0); // State to track the current index
  const [isHovered, setIsHovered] = useState(false); // Add this new state
  const navigate = useNavigate();

  // Fetch data from the API
  const fetchInsightsData = async () => {
    try {
      const response = await fetch('/api/blog_posts/published/');
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setInsightsData(data);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  // Fetch insights data on component mount
  useEffect(() => {
    fetchInsightsData();
  }, []);

  // Scroll Forward
  const scrollRight = () => {
    if (carouselRef.current) {
      const newIndex = (currentIndex + 1) % insightsData.length;
      setCurrentIndex(newIndex);
      
      // Check if we're at the last item
      if (newIndex === 0) {
        carouselRef.current.scrollTo({
          left: 0,
          behavior: 'smooth'
        });
      } else {
        carouselRef.current.scrollBy({
          left: carouselRef.current.offsetWidth,
          behavior: 'smooth'
        });
      }
    }
  };

  // Scroll Backward
  const scrollLeft = () => {
    if (carouselRef.current) {
      const newIndex = (currentIndex - 1 + insightsData.length) % insightsData.length; // Update currentIndex
      setCurrentIndex(newIndex); // Update current index
      carouselRef.current.scrollBy({
        left: -carouselRef.current.offsetWidth,
        behavior: 'smooth'
      });
    }
  };

  // Automatic Forward Scrolling with Loop
  useEffect(() => {
    let interval;
    if (!isHovered) { // Only run if not hovered
      interval = setInterval(scrollRight, 3000);
    }
    return () => clearInterval(interval);
  }, [currentIndex, isHovered]);

  // Render loading state or fetched data
  if (loading) {
    return <div className="text-center">Loading...</div>;
  }

  return (
    <div className="w-full flex flex-col items-center px-8 md:px-10 lg:px-28">
      {/* Section Header */}
      <header className="flex flex-col justify-center items-center w-full text-center my-10 ">
        <h1 data-layername="techExpertise" className="text-4xl font-semibold text-orange-500">
          Latest Insights
        </h1>
        <div className="w-[500px] h-[5px] max-md:w-[300px] mt-2" style={{
          background: "linear-gradient(90deg, rgba(255, 255, 255, 0.00) 0%, #F57C00 50%, rgba(255, 255, 255, 0.00) 100%)"
        }} />
        <div className="mt-2.5 max-w-full min-h-[5px] w-[455px]" />
        <p data-layername="discoverOurExpertiseAcrossTheAiLandscape" className="mt-2.5 text-lg text-zinc-300">
          Stay updated with the latest trends and developments in the world of AI and technology.
        </p>
      </header>

      {/* Carousel */}
      <div className="relative w-full overflow-hidden">
        <div
          ref={carouselRef}
          className="flex gap-4 overflow-x-scroll scrollbar-hide"
          style={{ 
            scrollSnapType: 'x mandatory',
            msOverflowStyle: 'none',  /* IE and Edge */
            scrollbarWidth: 'none',   /* Firefox */
          }}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          {insightsData.map((item) => (
            <div
              key={item.id}
              className="flex-shrink-0 w-full md:w-[60%] lg:w-[40%] bg-[#021B2A] rounded-lg text-white flex flex-col h-full"
              style={{ scrollSnapAlign: 'start' }}
            >
              {/* Image Section */}
              <div className="w-full h-[200px] md:h-[250px]">
                <img
                  src={item.image1}
                  alt={item.heading}
                  className="w-full h-full object-cover rounded-t-lg"
                />
              </div>

              {/* Text Content */}
              <div className="p-4 flex flex-col justify-between h-full">
                <h3 className="text-lg font-semibold">{item.heading}</h3>
                {item.preview_text && (
                  <p className="text-gray-300 mt-2 text-sm">{item.preview_text}</p>
                )}
                <div className="mt-auto pt-4">
                  <button
                    onClick={() => navigate(`/blog/${item.id}`)}
                    className="bg-orange-500 text-white py-2 rounded-lg hover:bg-orange-600 transition w-[80px] md:mt-4 max-lg:text-sm text-sm"
                  >
                    Read More
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Modern Indicators */}
        <div className="flex justify-center gap-2 mt-6">
          {insightsData.map((_, index) => (
            <motion.button
              key={index}
              whileHover={{ scale: 1.2 }}
              whileTap={{ scale: 0.9 }}
              onClick={() => {
                const targetScroll = index * carouselRef.current.offsetWidth;
                carouselRef.current.scrollTo({ left: targetScroll, behavior: 'smooth' });
                setCurrentIndex(index);
              }}
              className={`transition-all duration-300 ease-in-out rounded-full h-2.5
                ${index === currentIndex 
                  ? 'w-10 bg-[#F16E00]' 
                  : 'w-2.5 bg-white/70 hover:bg-white'}`}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default LatestInsights;
