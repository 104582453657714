import React from 'react';

function SubscriptionForm() {
  return (
    <form className="flex flex-col mt-8 text-sm max-w-[600px] w-full">
      <div className="flex flex-wrap gap-5 justify-between pl-3.5 bg-white rounded border border-solid border-zinc-400">
        <label htmlFor="emailInput" className="sr-only">Email Address</label>
        <input
          id="emailInput"
          type="email"
          placeholder="Email Address"
          className="flex-1 my-auto tracking-wide text-zinc-400 bg-transparent border-none px-2"
          aria-label="Email Address"
        />
        <button
          type="submit"
          className="px-5 py-3 font-medium text-center text-white whitespace-nowrap bg-orange-500 rounded-none"
        >
          Subscribe
        </button>
      </div>
    </form>
  );
}

export default SubscriptionForm;
