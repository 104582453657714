import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ContactUsMobile from './ContactUsMobile';
import { FaFacebookF, FaInstagram, FaLinkedinIn } from "react-icons/fa"; 
import { IMAGE_BASE_URL } from '../../config';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

const ContactUs = () => {
  const navigate = useNavigate(); // Initialize navigate function
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [isSubmitted, setIsSubmitted] = useState(false); // State for submission status

  // Form state with updated keys
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
    message: '',
  });

  // Handle input changes and reset submission status
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setIsSubmitted(false); 
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('/api/contact/', formData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      setIsSubmitted(true); 
      setFormData({ first_name: '', last_name: '', email: '', phone_number: '', message: '' });
    } catch (error) {
      console.error("Error sending message:", error);
      alert("Failed to send message. Please try again later.");
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Function to reset the form and navigate to homepage
  const handleBackToHome = () => {
    setIsSubmitted(false); // Reset the submission status
    navigate('/'); // Navigate to the homepage
  };

  return (
    <div className="mb-10">
      {/* Hero Section */}
      <div className="relative w-full h-[500px] md:h-[400px] lg:h-[600px] mb-4">
        <img 
          className="w-full h-[550px] object-cover" 
          alt="Careers Hero" 
          src={`${IMAGE_BASE_URL}careersheropage.png`} 
        />
        <div className="absolute inset-0 flex flex-col items-center justify-center text-center text-white">
          <h1 className="text-[50px] font-semibold text-primary">Contact Us</h1>
          <p className="text-[22px] text-[#fff] mt-2">Reach out, and let's create a universe of possibilities together!</p>
        </div>
      </div>

      {/* Conditional Rendering for Mobile View */}
      {isMobile ? (
        <div className="p-4">
          <ContactUsMobile />
        </div>
      ) : (
        <div className="relative w-full max-w-[1196px] mx-auto shadow-lg rounded-[10px] -mt-40 p-4">
          <div className="flex flex-col md:flex-row rounded-[10px]">
            {/* Left Side Content - Contact Information Overlay */}
            <div className="relative w-full md:w-[450px] p-4 bg-[#d8d8d8] rounded-l-[10px]">
              <div className="absolute top-4 left-4">
                <div className="text-white text-xl px-4 font-bold mt-6 mb-8">Contact Information</div>
                <div className="flex items-center mb-2 mt-8 px-4">
                  <img className="w-4 h-4 mr-2" alt="Phone Icon" src="/assets/call.png" />
                  <span className="text-white text-[16px] ">+91 9827368363</span>
                </div>
                <div className="flex items-center mb-1  px-4">
                  <img className="w-4 h-4 mr-2" alt="Email Icon" src="/assets/mail.png" />
                  <span className="text-white text-[16px]">contact@think41.com</span>
                </div>
                <div className="mb-4 p-1 text-left px-4 flex ">
                  <img className="w-4 h-4 mr-2" alt="Address Icon" src="/assets/address.png" />
                  <div className="text-white text-[16px]">
                      <p>2nd Floor, Obeya Gusto, 367,</p>
                      <p>5th Main Rd, Sector 6, HSR Layout,</p>
                      <p>Bengaluru, Karnataka 560102</p>
                  </div>
                </div>

                <div className="flex mt-36 px-4 mb-6 p-2">
  <a href="https://www.facebook.com/Think41corp/" aria-label="Facebook" target="_blank" rel="noopener noreferrer">
    <div className="relative flex items-center justify-center w-10 h-10 rounded-full bg-white mr-3"> {/* Added margin right */}
      <FaFacebookF className="text-orange-500 w-7 h-7" />
    </div>
  </a>
  <a href="https://www.instagram.com/think41corp/" aria-label="Instagram" target="_blank" rel="noopener noreferrer">
    <div className="relative flex items-center justify-center w-10 h-10 rounded-full bg-white mr-3"> {/* Added margin right */}
      <FaInstagram className="text-orange-500 w-7 h-7" />
    </div>
  </a>
  <a href="https://www.linkedin.com/company/think41/" aria-label="LinkedIn" target="_blank" rel="noopener noreferrer">
    <div className="relative flex items-center justify-center w-10 h-10 rounded-full bg-white">
      <FaLinkedinIn className="text-orange-500 w-7 h-7" />
    </div>
  </a>
</div>

              </div>
              <img 
                className="w-full h-[450px] object-cover " 
                alt="Contact Us Image" 
                src={`${IMAGE_BASE_URL}careersheropage.png`} 
              />
            </div>

            {/* Right Side Content - Conditionally Render Form or Thank-You Message */}
            <div className="flex-grow bg-[#d8d8d8] p-8 md:rounded-r-[10px] flex flex-col justify-center items-center">
              {isSubmitted ? (
                // Thank-you message displayed after form submission
                <div className="text-center">
                  <img 
                    src={`/assets/thankyou.png`}  // Replace with the actual path to your thank-you image
                    alt="Thank you" 
                    className="w-30 h-30 mb-4 mx-auto"  // Center image with mx-auto
                  />
                  <h2 className="text-primary text-3xl font-semibold mb-4">Thank you for contacting us!</h2>
                  <p className="text-[#444444] text-lg mb-10">
                    We have received your message.<br />
                    We’ll reach out to you immediately!
                  </p>
                  <button 
                    onClick={handleBackToHome} 
                    className="bg-primary text-white font-bold px-6 py-3 mt-20 rounded-lg hover:bg-opacity-90"
                  >
                    Back to Homepage
                  </button>
                </div>
              ) : (
                // Contact Form
                <form onSubmit={handleSubmit} className="w-full">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
                  <div>
                    <label className="block text-[#303030]">First Name</label>
                    <input 
                      type="text" 
                      name="first_name"
                      value={formData.first_name}
                      onChange={handleInputChange}
                      className="w-full border-b-2 bg-[#d8d8d8] border-black focus:outline-none focus:border-orange-500 p-2 text-[#303030]" 
                      placeholder="" 
                    />
                  </div>
                  <div>
                    <label className="block text-[#303030]">Last Name</label>
                    <input 
                      type="text" 
                      name="last_name"
                      value={formData.last_name}
                      onChange={handleInputChange}
                      className="w-full border-b-2 bg-[#d8d8d8] border-black focus:outline-none focus:border-orange-500 p-2 text-[#303030]" 
                      placeholder="" 
                    />
                  </div>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
                  <div>
                    <label className="block text-[#303030]">Email</label>
                    <input 
                      type="email" 
                      name="email"
                      value={formData.email}
                      onChange={handleInputChange}
                      className="w-full border-b-2 bg-[#d8d8d8] border-black focus:outline-none focus:border-orange-500 p-2 text-[#303030]" 
                      placeholder="" 
                    />
                  </div>
                  <div>
                    <label className="block text-[#303030]">Phone Number</label>
                    <input 
                      type="text" 
                      name="phone_number"
                      value={formData.phone_number}
                      onChange={handleInputChange}
                      className="w-full border-b-2 bg-[#d8d8d8] border-black focus:outline-none focus:border-orange-500 p-2 text-[#303030]" 
                      placeholder="" 
                    />
                  </div>
                </div>
                <div className="mb-6">
                  <label className="block text-[#303030]">Message</label>
                  <input 
                    type="text"
                    name="message"
                    value={formData.message}
                    onChange={handleInputChange}
                    className="w-full border-b-2 bg-[#d8d8d8] border-black focus:outline-none focus:border-orange-500 p-2 text-[#303030]" 
                    placeholder="Write your message..." 
                  />
                </div>
                <div className="text-right">
                  <button 
                    type="submit"
                    className="bg-primary text-white font-bold px-6 py-3 rounded-lg hover:bg-opacity-90"
                  >
                    Send Message
                  </button>
                </div>
              </form>
              
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ContactUs;
