import React, { useState, useEffect } from "react";
import { IMAGE_BASE_URL } from '../../config';

const images = [
  `${IMAGE_BASE_URL}Carousel1.jpeg`,
  `${IMAGE_BASE_URL}Carousel2.png`,
  `${IMAGE_BASE_URL}Carousel3.png`,
  `${IMAGE_BASE_URL}Carousel4.png`,
  `${IMAGE_BASE_URL}Carousel5.png`
];

const Carousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, 4000); // Change image every 4 seconds
    return () => clearInterval(interval);
  }, [currentIndex]);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  return (
    <div className="flex flex-col items-center">
      {/* Carousel Container */}
      <div className="relative w-full max-w-[90%] md:max-w-[1220px] h-[300px] md:h-[350px] lg:h-[440px] flex items-center justify-center mx-auto">
        {/* Image Wrapper */}
        <div className="relative w-full h-full flex items-center justify-center overflow-hidden">
          {images.map((image, index) => {
            const isCurrent = index === currentIndex;
            const isPrev = index === (currentIndex - 1 + images.length) % images.length;
            const isNext = index === (currentIndex + 1) % images.length;

            return (
              <div
                key={index}
                className={`absolute transition-transform duration-500 ease-in-out transform ${
                  isCurrent
                    ? 'translate-x-0 scale-100 z-10'
                    : isPrev
                    ? '-translate-x-[110%] scale-90 blur-sm opacity-70'
                    : 'translate-x-[110%] scale-90 blur-sm opacity-70'
                }`}
                style={{
                  width: isCurrent ? '80%' : '40%', // Increase size for mobile view
                  transitionDelay: '0ms'
                }}
              >
                <img
                  src={image}
                  alt={`Slide ${index + 1}`}
                  className="w-full h-full rounded-[8px] object-cover"
                />
              </div>
            );
          })}
        </div>
      </div>

      {/* Navigation Arrows and Slide Indicators */}
      <div className="flex items-center gap-4 mt-6 md:mt-8">
        {/* Left Navigation Arrow */}
        <div className="cursor-pointer" onClick={prevSlide}>
          <img src="/assets/ArrowLeft.png" alt="Previous" className="w-4 h-4 md:w-5 md:h-5 lg:w-6 lg:h-6" />
        </div>

        {/* Slide Indicators */}
        <div className="flex gap-2">
          {images.map((_, index) => (
            <div
              key={index}
              onClick={() => setCurrentIndex(index)}
              className={`cursor-pointer transition-transform duration-300 ease-in-out rounded-full ${
                index === currentIndex ? 'bg-[#F16E00] scale-125' : 'bg-gray-300 scale-100'
              } ${index === currentIndex ? 'w-2.5 h-2.5 md:w-3.5 md:h-3.5 lg:w-4 lg:h-4' : 'w-2 h-2 md:w-3 md:h-3 lg:w-3.5 lg:h-3.5'}`}
            />
          ))}
        </div>

        {/* Right Navigation Arrow */}
        <div className="cursor-pointer" onClick={nextSlide}>
          <img src="/assets/ArrowRight.png" alt="Next" className="w-4 h-4 md:w-5 md:h-5 lg:w-6 lg:h-6" />
        </div>
      </div>
    </div>
  );
};

export default Carousel;
