import * as React from "react";
import IIMobileCard from "./TechRadarCards/Mobile/IIMobileCard";
import ACMobileCard from "./TechRadarCards/Mobile/ACMobileCard";
import GenAIMobile from "./TechRadarCards/Mobile/GenAIMobile";
import AgentWMobile from "./TechRadarCards/Mobile/AgentWMobile";
import RefDataMobile from "./TechRadarCards/Mobile/RefDataMobile";
import OWMobile from "./TechRadarCards/Mobile/OWMobile";
import PMMobile from "./TechRadarCards/Mobile/PMMobile";
import CMMobile from "./TechRadarCards/Mobile/CMMobile";
import VSCardMobile from "./TechRadarCards/Mobile/VSCardMobile";
import PDMobileCard from "./TechRadarCards/Mobile/PDMobileCard";
import VectorStoresCard from "./TechRadarCards/VectorStoresCard";
import OpenWeight from "./TechRadarCards/OpenWeight";
import CustomModels from "./TechRadarCards/CustomModels";
import ProprietoryModels from "./TechRadarCards/ProprietoryModels";
import ProprietaryData from "./TechRadarCards/ProprietaryData";

export default function TechRadarResponsive() {
  return (
    <main data-layername="techRadar" className="flex flex-col y-6 bg-slate-950">
      <header className="flex flex-col justify-center items-center w-full text-center mt-12">
        <h1 data-layername="techExpertise" className="text-3xl font-semibold text-orange-500">
          Tech Expertise
        </h1>
        <div className="w-full h-[5px] mt-2" style={{
          background: "linear-gradient(90deg, rgba(255, 255, 255, 0.00) 0%, #F57C00 50%, rgba(255, 255, 255, 0.00) 100%)"
        }} />
        <p data-layername="discoverOurExpertiseAcrossTheAiLandscape" className="mt-2.5 text-base text-zinc-300">
          Discover Our Expertise Across the AI Landscape
        </p>
      </header>

      <section className="mt-5 bg-slate-950">
        <div className="grid grid-cols-5 gap-[5px] mx-6"> {/* Added gap-4 for spacing between cards */}
          {/* Row 1 */}
          <div className="col-span-2">
            <IIMobileCard />
          </div>
          <div className="col-span-3">
            <ACMobileCard />
          </div>

          {/* Row 2 */}
          <div className="col-span-3 mt-4 sm:mt-10">
          <GenAIMobile />
          </div>
          <div className="col-span-2 mt-4 sm:mt-10">
          <AgentWMobile />
          </div>

          {/* Row 3 */}
          <div className="col-span-3">
          <RefDataMobile />
          </div>
          <div className="col-span-2">
          <VSCardMobile />
          </div>

          {/* Row 4 */}
          <div className="col-span-2 mt-4">
          <OWMobile />
          </div>
          <div className="col-span-3 mt-4">
          <PMMobile />
          </div>

          {/* Row 5 */}
          <div className="col-span-3 mb-4">
          <CMMobile />
          </div>
          <div className="col-span-2 mb-4">
          <PDMobileCard />
          </div>
        </div>
      </section>
    </main>
  );
}
