import React, { useEffect, useState } from 'react';
import UpdateCardResponsive from './UpdateCardResponsive';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

const LatestUpdatesMobile = () => {
  const [updates, setUpdates] = useState([]);

  useEffect(() => {
    async function fetchLatestUpdates() {
      try {
        const response = await fetch('/api/latest_blog_posts/');
        if (!response.ok) throw new Error('Failed to fetch blog posts');
        
        const data = await response.json();
        setUpdates(data);
      } catch (error) {
        console.error('Error fetching latest blog posts:', error);
      }
    }

    fetchLatestUpdates();
  }, []);

  return (
    <section className="w-full overflow-hidden" style={{ paddingLeft: '20px', paddingRight: '20px' }}>
      <h2 className="text-2xl font-bold text-orange-500 mb-4">Latest Updates</h2>

      <Swiper slidesPerView={1.5} spaceBetween={10} >
        {updates.map((update) => (
          <SwiperSlide key={update.id}>
            <UpdateCardResponsive
              title={update.heading}
              imageSrc={update.image1}
              introduction={update.preview_text}
              blogPostId={update.id}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  );
};

export default LatestUpdatesMobile;
