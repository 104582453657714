import React from 'react';
import QuickLinks from './QuickLinks';
import ContactInfo from './ContactInfo';
import SocialLinks from './SocialLinks';
import MapComponent from './MapComponent';

const Footer = () => {
  return (
    <footer className="flex overflow-hidden flex-col px-24 pt-12 pb-8 border-t bg-slate-950 border-cyan-950 max-md:px-5">
      <div className="flex flex-col w-full max-md:max-w-full">
        <div className="flex flex-col max-w-full text-center w-[199px]">
          <div className="flex flex-col justify-center items-start w-full">
            <a href="/" className="text-lg md:text-xl lg:text-2xl font-bold text-orange-500 whitespace-nowrap cursor-pointer" onClick={() => window.scrollTo(0, 0)} >
              Think41
            </a>
            <p className="mt-2.5 text-base leading-snug text-zinc-300">
              Digital Journey. Accelerated
            </p>
          </div>
        </div>
        <div className="flex flex-wrap gap-10 justify-between items-start p-5 mt-12 w-full rounded-lg border border-solid border-zinc-300 border-opacity-20 max-md:mt-10 max-md:max-w-full">
          <QuickLinks />
          <div className="flex flex-col w-[88px]">
            <h3 className="flex flex-col text-lg font-medium text-orange-500 whitespace-nowrap w-[65px]">
              Demos
              <div className="mt-1.5 w-full min-h-0" />
              <div className="self-stretch mt-1 w-full h-[2px] bg-gradient-to-r from-[#F57C00] to-[#01111A]" />
            </h3>
            <nav className="flex flex-col mt-5 text-sm text-zinc-300 w-[95px]">
              <a href="/demos?section=Chat AI">Chat AI</a>
              <a href="/demos?section=Data Dialogue" className="mt-4">Data Dialogue</a>
              <a href="/demos?section=Dev41" className="mt-4">Dev41</a>
            </nav>
          </div>
          <ContactInfo />
          <MapComponent />
        </div>
        <div className="flex gap-10 justify-between items-center mt-12 w-full max-md:mt-10 max-md:max-w-full max-md:flex-col max-md:items-center">
          <p className="self-stretch my-auto text-xs text-zinc-300 max-md:text-center max-md:order-2">
            © 2024 Apply. All rights reserved
          </p>
          <SocialLinks />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
