import React, { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

const ProtectedRoute = ({ element }) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const secretKey = queryParams.get('secret'); // Get the secret key from query params
  const [validSecretKey, setValidSecretKey] = useState(null);
  const [loading, setLoading] = useState(true); // Add loading state

  useEffect(() => {
    const fetchSecretKey = async () => {
      const response = await fetch('/api/config/');
      const data = await response.json();
      setValidSecretKey(data.secret_key);
      setLoading(false); // Set loading to false after fetching
    };

    fetchSecretKey();
  }, []);

  // Debugging: Log the secret key and valid secret key
  console.log('Secret Key from URL:', secretKey);
  console.log('Valid Secret Key from API:', validSecretKey);

  // Check if the provided secret key matches the valid secret key only if not loading
  const isAuthenticated = !loading && secretKey === validSecretKey;

  return loading ? null : (isAuthenticated ? element : <Navigate to="/" />); // Show nothing while loading
};

export default ProtectedRoute;
