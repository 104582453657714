import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useParams } from 'react-router-dom';
import Home from './pages/Home';
import BlogViewPage from './pages/Blogs/BlogViewPage';
import BlogAddPage from './pages/Blogs/BlogAddPage';
import BlogList from './pages/BlogListing';
import BlogEditPage from './pages/Blogs/BlogEditPage';
import BlogDashboard from './pages/Blogs/BlogDashboard';
import Careers from './pages/CareersPage';
import BlogPreviewPage from './pages/Blogs/BlogPreviewPage';
import DemoPage from './pages/Demos/DemoPage';
import HeaderComponent from './components/HeaderComponent';
import Footer from './components/Footer/Footer';
import ProtectedRoute from './ProtectedRoute';

import ContactUsPage from './pages/ContactUsPage';
import AboutUs from './pages/AboutUs';
function App() {
  return (
    <Router>
      <div className="App">
        <HeaderComponent />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/blog/:id" element={<BlogViewPage />} />
          
          {/* Protected Routes with URL parameter for secret key */}
          <Route path="/add-blog" element={<ProtectedRoute element={<BlogAddPage />}  />} />
          <Route path="/edit-blog/:id" element={<ProtectedRoute element={<BlogEditPage />} />} />
          <Route path="/blog-preview/:id" element={<ProtectedRoute element={<BlogPreviewPage />}  />} />
          <Route path="/blogs-dashboard" element={<ProtectedRoute element={<BlogDashboard />}  />} />
          
          <Route path="/bloglist" element={<BlogList />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/demos" element={<DemoPage />} />
          <Route path="/contact" element={<ContactUsPage />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;