import React from 'react';
import { IMAGE_BASE_URL } from '../../config';
const CareersHero = () => {
  // Button component defined within CareersHero
  const ButtonChange = () => {
    return (
      <div className="relative z-10 flex flex-col items-center justify-center gap-4 md:gap-6 lg:gap-8 mt-6 md:mt-8 lg:mt-10 text-center px-4">
      <a 
          href="https://think.recruit41.com/" 
          target="_blank" 
          rel="noopener noreferrer" 
          className="px-4 py-3 md:px-6 md:py-3 lg:px-6 lg:py-3 rounded-lg bg-primary hover:bg-opacity-90 text-white font-bold text-sm md:text-base lg:text-lg transition duration-300 ease-in-out"
        >
          Open Positions
        </a>
    </div>
    );
  };

  return (
    <div className="relative w-full h-[500px] md:h-[400px] lg:h-[600px]"> {/* Height adjustments for different screen sizes */}
      <img 
        className="w-full h-full object-fit" 
        alt="Careers Hero" 
        src={`${IMAGE_BASE_URL}careersheropage.png`} 
      />
      <section className="absolute inset-0 flex flex-col items-center justify-center text-center p-4">
        <h1 className="self-stretch text-3xl font-bold text-[#f16e00] md:text-4xl sm:text-3xl xs:text-2xl"> {/* Added responsive size for extra small devices */}
          Be the force behind impactful AI advancements
        </h1>
        <p className="mt-[40px] text-xl text-center leading-6 text-zinc-300 md:text-xl sm:text-lg xs:text-base"> {/* Responsive text size */}
          Join us in transforming innovative ideas into real AI advancements.
        </p>
        <div>
          <ButtonChange />
        </div>
      </section>
    </div>
  );
};

export default CareersHero;