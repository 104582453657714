import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function UpdateCard({ title, imageSrc, introduction, blogPostId }) {
  const [isHovered, setIsHovered] = useState(false);
  const navigate = useNavigate();

  // Function to extract the first two sentences from a string
  function getFirstTwoSentences(htmlContent) {
    if (!htmlContent) {
      return "";
    }

    const sentences = htmlContent
      .replace(/(<([^>]+)>)/gi, "") // Remove HTML tags
      .match(/[^.!?]+[.!?]+/g); // Match sentences

    if (sentences && sentences.length >= 2) {
      return sentences.slice(0, 2).join(' '); // Return the first two sentences
    }
    return htmlContent; // If fewer than 2 sentences, return as is
  }

  const firstTwoSentences = getFirstTwoSentences(introduction);

  // Function to handle the redirect
  const handleReadMore = () => {
    navigate(`/blog/${blogPostId}`); // Redirect to the blog post page
  };

  return (
    <article
      className={`relative flex flex-col w-3/12 max-md:ml-0 max-md:w-full group rounded-[4px] transition-all duration-500 ease-in-out`} 
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{ zIndex: isHovered ? 100 : 0 }}
    >
      {/* Default Card (Before Hover) */}
      <div className="flex flex-col grow text-lg font-bold text-white max-md:mt-3.5 relative">
        <div className="flex relative flex-col px-1.5 pt-36 pb-5 w-full rounded-[4px] aspect-[1.5] max-md:pt-24">
          <img
            loading="lazy"
            src={imageSrc}
            alt=""
            className="object-cover absolute inset-0 size-full rounded-[4px]"
          />
          <div className="absolute bottom-0 w-full px-4 py-2">
            <h3 className="w-full">{title}</h3>
          </div>
        </div>
      </div>

      {/* Hover Card (Expanded View) */}
      <div
        className={`absolute inset-0 bg-[#08293c] transition-all duration-500 ease-in-out rounded-[4px] z-50 ${
          isHovered ? 'opacity-100' : 'opacity-0'
        }`}
        style={{
          pointerEvents: isHovered ? 'auto' : 'none',
          height: isHovered ? '500px' : '0',
          width: isHovered ? '450px' : '0',
        }}
      >
        {/* Image with reduced padding */}
        <div className="flex justify-center p-2">
          <img
            src={imageSrc}
            alt=""
            className="w-full h-[300px] object-fill"
          />
        </div>

        {/* Title */}
        <h3 className="text-[#f57c00] text-xl font-bold px-2">{title}</h3>

        {/* Introduction Text - Visible Only on Hover */}
        <p
          className="text-white text-sm px-2"
          dangerouslySetInnerHTML={{ __html: firstTwoSentences }}
        />

        {/* Read Blog Button */}
        <div className="flex w-full mt-4  justify-start px-2">
          <div 
            className="bg-[#f16e00] text-white rounded-md px-4 py-2 w-max cursor-pointer" 
            onClick={handleReadMore}
          >
            <span>Read Blog</span>
          </div>
        </div>
      </div>
    </article>
  );
}

export default UpdateCard;
