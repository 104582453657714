import React from 'react';
import HomeCarousel from './HomeCarousel'; // Adjust the import according to your project structure
import { IMAGE_BASE_URL } from '../../config';
function DemoSection() {
  return (
    <div className="flex flex-col bg-cover bg-center px-4 md:px-6 lg:px-28 py-8 md:py-12 lg:py-16" 
         style={{ 
           backgroundImage: `url(${IMAGE_BASE_URL}backgrounds/bg_genai_section.png)`,
           backgroundSize: 'cover',
           backgroundPosition: 'center',
           backgroundRepeat: 'no-repeat',
           position: 'relative'
         }}>
      <div className="absolute inset-0 bg-gradient-to-b from-[#49738b] via-transparent to-[#49738b] opacity-[37%]" />
      {/* Section Heading */}
      <div className="text-center flex flex-col items-center gap-2 w-full max-w-4xl mx-auto z-30">
      <header className="flex flex-col justify-center items-center w-full text-center my-10 ">
        <h1 data-layername="techExpertise" className="text-4xl font-semibold text-primary">
          Experience Our Demos
        </h1>
        <div className="w-[500px] h-[5px] max-md:w-[300px] mt-2" style={{
          background: "linear-gradient(90deg, rgba(255, 255, 255, 0.00) 0%, #F57C00 50%, rgba(255, 255, 255, 0.00) 100%)"
        }} />
        <div className="mt-2.5 max-w-full min-h-[5px] w-[455px]" />
        <p data-layername="discoverOurExpertiseAcrossTheAiLandscape" className="mt-2.5 text-lg text-zinc-300">
          Discover the features and capabilities of our latest demos and see how they can benefit you.
        </p>
      </header>
      </div>

      {/* Demo Content */}
      <div className="flex flex-col lg:flex-row justify-center items-center gap-6">
        {/* Left Image */}
        <div className="relative w-full ">
          <img
            src={`${IMAGE_BASE_URL}recruit41.png`}
            alt="Robot and Human Interaction"
            className="w-full h-auto md:h-[400px] lg:h-[477px] object-cover rounded-lg transition-all duration-500 lg:blur-0"
          />
        </div>

        {/* Right Carousel */}
        <div className="w-full lg:w-1/2 mt-6">
          <HomeCarousel />
        </div>
      </div>
    </div>
  );
}

export default DemoSection;
