import React, { useState } from "react";
import InfoModal from "../InfoModal"; // Ensure you import the InfoModal

function ProprietoryData() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const modalContent = `<p>Use learned embeddings to convert and store text as vectors, which can later be queried for a similarity search. This technique allows for enhanced data retrieval and analysis, making it easier to find relevant information based on contextual similarities.</p>
  </br>
  <p>By leveraging these embeddings, systems can improve user engagement and facilitate a more dynamic interaction with the data.</p>`;

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <div className="flex items-center justify-center w-full sm:px-4 sm:pt-6 -mb-16 relative">
      <img 
        src="/assets/Cylinder.png"
        alt="Vector Stores"
        className="w-full h-full object-cover md:w-[90%] lg:w-[70%]" // Reduced width and height for screens higher than 768px
      />

      <button 
        className="absolute text-center w-11/12 sm:w-3/4 lg:w-1/2 pt-4" 
        style={{ 
          top: '50%', 
          left: '50%', 
          transform: 'translate(-50%, -50%)',
          maxWidth: 'calc(100% - 2rem)' 
        }}
        onClick={openModal} // This opens the modal
      >
        <h2 className="text-[16px] sm:text-[36px] tracking-tight text-[#8BC5E1] bg-clip-text xs:-mb-2 sm:-mb-4 xs:pt-4 sm:pt-8">
          Vector
        </h2>
        <h2 className="text-[16px] sm:text-[30px] tracking-tight text-[#8BC5E1] bg-clip-text mt-[-4px] md:mt-[10px]">
        Stores
        </h2>
      </button>

      <InfoModal isOpen={isModalOpen} content={modalContent} onClose={closeModal} modalHeader="Vector Stores" />
    </div>
  );
}

export default ProprietoryData;
