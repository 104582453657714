import React, { useState } from "react";
import InfoModal from "./InfoModal"; // Ensure you import the InfoModal

function AgenticWorkflows() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const modalContent = `<p>Fine-tuned models can be tailored to specific use cases for enhanced accuracy, performance, and domain relevance. This applies for running private models, where proprietary data can’t be sent to public LLMs due to compliance or IP restrictions.</p>
  </br>
  <p>It is crucial to collect data as these systems are utilised and developed. Initiating this will facilitate a smoother transition to specialised, fine-tuned models in the future.</p>`;

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <section 
      className="flex flex-col items-center justify-center rounded-lg py-2 px-4 bg-cyan-950 min-h-[180px] w-full relative
                 sm:py-3 sm:px-6 sm:min-h-[240px]
                 md:py-4 md:px-8 md:min-h-[180px]
                 xl:py-3 xl:px-4 xl:min-h-[180px]"
      aria-label="Agentic Workflows Card"
    >
      <img 
        src="/assets/customModel.png" 
        alt="Custom Model" 
        className="absolute top-2 -right-2 w-24
                   sm:w-28
                   md:w-30
                   lg:w-30
                   xl:w-40 max-md:w-14" 
      />
      <div style={{
            background: 'linear-gradient(274deg, #D2E1F7 -9.37%, #63AEDE 105.89%)',
            backgroundClip: 'text',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
            textAlign: 'center'
          }}
      >
        <h2 className="text-3xl font-semibold tracking-tight leading-none text-slate-50
                       sm:text-3xl
                       md:text-4xl
                       xl:text-4xl max-md:text-xl">
          Custom Model(s)
        </h2>
        <p className="text-lg text-slate-200 mt-1
                      sm:text-xl sm:mt-2
                      md:text-2xl
                      xl:text-2xl max-md:text-sm">
          Fine-tuned for specific use-cases.
        </p>
      </div>
      <div className="absolute bottom-2 right-2
                      sm:bottom-3 sm:right-3
                      md:bottom-3 md:right-3
                      xl:bottom-3 xl:right-3">
        <button 
          className="border border-gray-500 rounded-full w-10 h-10 sm:w-8 sm:h-8 flex items-center justify-center shadow-lg bg-transparent hover:border-gray-400"
          onClick={openModal} // This opens the modal
        >
          <span className="text-gray-500 text-2xl  hover:text-gray-400">+</span>
        </button>
      </div>

      <InfoModal 
        isOpen={isModalOpen} 
        onClose={closeModal} 
        content={modalContent}
        modalHeader="Custom Models"
      />
    </section>
  );
}

export default AgenticWorkflows;
